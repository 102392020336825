import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Form, FormCheck, FormLabel, InputGroup, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import DataContext from '../contexts/DataContext';
import PaymentContext from '../contexts/PaymentContext';
import validations from '../utils/validations';
import DocumentField from './DocumentField';
import DocumentHolderData from './DocumentHolderData';

const CredCardForm = ({formData, setFormData}) => {
    CredCardForm.propTypes = {
        formData: PropTypes.object.isRequired,
        setFormData: PropTypes.func.isRequired
    };
    const doctype = formData.IDdoctype;
    const { setCardData } = useContext(PaymentContext);
    const { source } = useContext(DataContext);
    const isMembership = source === "membership";

    const [name, setName] = useState('');
    const [nameInvalid, setNameInvalid] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [cardNumberInvalid, setCardNumberInvalid] = useState(false);
    const [date, setDate] = useState('');
    const [dateInvalid, setDateInvalid] = useState(false);
    const [cvv, setCvv] = useState('');
    const [cvvInvalid, setCvvInvalid] = useState(false);
    const [installment, setInstallment] = useState('');
    const [installmentInvalid, setInstallmentInvalid] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [cpfInvalid, setCpfInvalid] = useState(false);
    const [isTitular, setIsTitular] = useState(false);

    
     
    const setNameChange = (value) => {
        setName(value);
        setNameInvalid(!validations.validateName(value));
        setFormData({ ...formData, CCnameInvalid: !validations.validateName(value) });
    }
    const setCardNumberChange = (value) => {
        setCardNumber(value);
        setCardNumberInvalid(!validations.validateCardNumber(value));
        setFormData({ ...formData, CCnumberInvalid: !validations.validateCardNumber(value) });
    }
    const setDateChange = (value) => {
        setDate(value);
        setDateInvalid(!validations.validateDate('01/' + value));
        setFormData({ ...formData, CCdateInvalid: !validations.validateDate('01/' + value) });
    }
    const setCvvChange = (value) => {
        setCvv(value);
        setCvvInvalid(!validations.validateIsCVVNumber(cardNumber, value));
        setFormData({ ...formData, CVV: value ,CVVInvalid: (!validations.validateIsCVVNumber(cardNumber, value)) });
    }
    const setInstallmentChange = (value) => {
        setInstallment(value);
        setInstallmentInvalid(!value);
        setFormData({ ...formData, CCInstallment: value, InstallmentInvalid: (!value) });
    }

    const saveCardData = () => {
        const cardData = {};
        cardData.publicKey = isMembership ? process.env.REACT_APP_PAGS_PUBLIC_KEY : process.env.REACT_APP_PAGS_PUBLIC_KEY_CLUBBVC;
        cardData.holder = name;
        cardData.number = cardNumber?.replace(/\D/g, '');
        cardData.expMonth = date.substring(0, 2);
        cardData.expYear = date.substring(3, 7);
        cardData.securityCode = cvv;
        setCardData(cardData);
    }

    const saveHolderCpf = (value) => {
        setCpfInvalid(!validations.validateCPF(value));
        setFormData({ ...formData, HolderCpf: value, HolderCpfInvalid: !validations.validateCPF(value) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const revalidateForm = (() => {
        if(formData.forceValidation) {
            setNameChange(name);
            setCardNumberChange(cardNumber);
            setDateChange(date);
            setCvvChange(cvv);
            setInstallmentChange(installment);
            saveHolderCpf(formData.HolderCpf || '');
        }
    });

      
    const saveConfirmTitular = (value) => {
        let cpfValue;
        setIsTitular(value);
        if(value === false) {
            cpfValue = '';
        } else {
            cpfValue = formData.IDcpf;
        }
        setFormData({ ...formData, 
            CCisTitular: value, 
            HolderCpf: cpfValue,
            HolderCpfInvalid: !validations.validateCPF(cpfValue)
        });
    }

    useEffect(() => {
        initializeForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.forceValidation]); 

    const initializeForm = () => {
        setNameInvalid(formData.CCnameInvalid || false);
        setCardNumberInvalid(formData.CCnumberInvalid || false);
        setDateInvalid(formData.CCdateInvalid || false);
        setCvvInvalid(formData.CVVInvalid || false);
        setCpfInvalid(formData.HolderCpfInvalid || false);
        setInstallmentInvalid(formData.InstallmentInvalid || false);
        revalidateForm();
    }

    return (
        <Container className='col-md-12 mb-4 pb-4'>
        <Row className='pt-4'>
            <h2>Dados do cartão de crédito</h2>
        </Row>
        <div className='d-flex justify-content-center flex-direction: row'> 
                <Row>
                    <Form.Group as={Col} className="form-group-payment card-group">
                        <Form.Label>Nome impresso no cartão</Form.Label>
                        <InputGroup hasValidation>
                        <Form.Control
                            className={`form-control form-control-payment
                                 ${nameInvalid ? 'is-invalid' : (name ? 'is-valid' : '')}`}
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setNameChange(e.target.value)}
                            onBlur={(e) => saveCardData()}
                            required
                            />
                            <Form.Control.Feedback type="invalid">
                                Preencha o nome impresso no cartão de crédito
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="form-group-payment card-group">
                        <Form.Label>Número do cartão</Form.Label>
                        <InputGroup hasValidation>
                        <InputMask
                            className={`form-control form-control-payment
                                 ${cardNumberInvalid ? 'is-invalid' : (cardNumber ? 'is-valid' : '')}`}
                            mask="9999 9999 9999 9999"
                            value={cardNumber}
                            id="cardNumber"
                            type="text"
                            required
                            onChange={(e) => setCardNumberChange(e.target.value)}
                            onBlur={(e) => saveCardData()}
                            />
                            {!cardNumberInvalid ? '' : <div className="invalid-feedback">
                                Número do cartão não é válido
                            </div>}
                        </InputGroup>
                    </Form.Group> 
                </Row>
                <Row>
                    <Form.Group as={Col} md={6} className="form-group-payment card-group">
                        <Form.Label>Vencimento</Form.Label>
                        <InputGroup hasValidation>
                        <InputMask
                            className={`form-control form-control-payment 
                                ${dateInvalid ? 'is-invalid' : (date ? 'is-valid' : '')}`}
                            mask="99/9999"
                            value={date}
                            id="date"
                            type="text"
                            required
                            onChange={(e) => setDateChange(e.target.value)}
                            onBlur={(e) => saveCardData()}
                        />
                            {!dateInvalid ? '' : <div className="invalid-feedback">
                                Preencha a data de validade
                            </div>}
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md={5} className="form-group-payment card-group">
                        <Form.Label>CVV</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                            className={`form-control form-control-payment 
                                ${cvvInvalid ? 'is-invalid' : (cvv ? 'is-valid' : '')}`}
                            id="cvv"
                            type="text"
                            maxLength={4}
                            value={cvv}
                            required
                            onChange={(e) => setCvvChange(e.target.value)}
                            onBlur={(e) => saveCardData()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Preencha o código verificador
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Row>
                {!isMembership && (<>
                <Row>
                        <Form.Group as={Col} className="form-group-payment card-group">
                            <Form.Label>Parcelas</Form.Label>
                            <InputGroup hasValidation>
                                <select
                                    onBlur={(e) => setInstallmentChange(e.target.value)}
                                    className={`form-select form-control-payment
                                        ${installmentInvalid ? 'is-invalid' : (installment ? 'is-valid' : '')}`}
                                    required
                                >
                                    <option value=""> </option>
                                    <option value="1">À vista</option>
                                    <option value="2">2x</option>
                                    <option value="3">3x</option>
                                    <option value="4">4x</option>
                                    <option value="5">5x</option>
                                    <option value="6">6x</option>
                                </select>
                                <Form.Control.Feedback type="invalid">
                                    Preencha a quantidade de parcelas
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                </Row>
                <h2 className='pt-4'>Dados do titular do cartão</h2>
                <Row>
                    <FormLabel className='mt-4'> 
                        <FormCheck
                            type="checkbox"
                            label=" "
                            checked={isTitular}
                            onClick={(e) => saveConfirmTitular(e.target.checked)}
                        />
                        <span className='h7 txt-instructions'>Sou titular do meio de pagamento</span>
                    </FormLabel>
                    <Form.Group as={Col} className="form-group-payment card-group">
                    <DocumentField 
                            additionalClass='form-control-payment' doctype='CPF' 
                            additional='Hold'
                            value={formData.HolderCpf}
                            disabled={(formData.CCisTitular && formData.HolderCpf)}
                            onChange={(e) => saveHolderCpf(e.target.value)}
                            invalid={cpfInvalid}
                            func={saveHolderCpf} 
                            forceValidation={formData.forceValidation}
                        />
                    </Form.Group>
                </Row>
                </>)}
            </div>
            {isMembership && (
            <div className='d-flex justify-content-center flex-direction: row mt-4'> 
                <DocumentHolderData doctype={doctype} formData={formData} setFormData={setFormData} />
            </div>
            )}
      </Container>  
    )
}

export default CredCardForm;