const validateCardNumber = (cardNumber) => {
    const digitsOnly = cardNumber.replace(/\D/g, '');
    const digitsReversed = digitsOnly.split('').reverse().join('');

    if(digitsOnly.length === 0)
      return false;
  
    let sum = 0;
    for (let i = 0; i < digitsReversed.length; i++) {
      let digit = parseInt(digitsReversed[i]);
      if (i % 2 === 1) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      sum += digit;
    }
  
    return sum % 10 === 0;
}

function isAmericanExpress(cardNumber) {
  const cleanedNumber = cardNumber.replace(/\D/g, '');

  if (cleanedNumber.length < 13) {
    return false;
  }

  const firstTwoDigits = cleanedNumber.substring(0, 2);
  return firstTwoDigits === '34' || firstTwoDigits === '37';
}

const validateIsCVVNumber = (cardNumber, cvv) => {
  const isNumber = validateIsNumber(cvv);
  const isAmericanExpressCard = isAmericanExpress(cardNumber);
  if(isNumber) {
    if((isAmericanExpressCard && cvv.length === 4) || 
       (!isAmericanExpressCard && cvv.length === 3)) {
          return true;
       }
  }
  return false;
}

const validateCellPhone = (phoneNumber) => {
    if (!phoneNumber || typeof phoneNumber !== 'string') return false;
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, '');
    return sanitizedPhoneNumber.length === 11 && /^[1-9]{2}$/.test(sanitizedPhoneNumber.slice(0, 2));
}

const validateState = (state) => {
  const siglasEstados = [
    "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA",
    "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN",
    "RS", "RO", "RR", "SC", "SE", "SP", "TO"
  ];
  const stringMaiuscula = state.toUpperCase();
  return siglasEstados.some(sigla => stringMaiuscula.includes(sigla));
}

const validateCEP = (cep) => {
    const regex = /^\d{5}-\d{3}$/;
    return regex.test(cep);
};

const validateDate = (dateStr) => {
    if (!dateStr || typeof dateStr !== 'string') {
      return false;
    }
    const [day, month, year] = dateStr.split('/');

    return (
      !isNaN(parseInt(day)) &&
      !isNaN(parseInt(month)) &&
      !isNaN(parseInt(year)) &&
      parseInt(day) >= 1 &&
      parseInt(day) <= 31 &&
      parseInt(month) >= 1 &&
      parseInt(month) <= 12 &&
      parseInt(year) >= 1900 &&
      parseInt(year) <= 2100
    );
  }

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

const validateIsNumber = (str) => {
    const regex = /^\d+$/;
    return regex.test(str);
}
  
const validateString = (string) => {
  return (string.trim().length >= 2);
}

const validateName = (string) => {
  return (validateString(string) && string.includes(' '));
}

const invalidCPFs = [
    "00000000000", "11111111111", "22222222222", "33333333333", "44444444444",
    "55555555555", "66666666666", "77777777777", "88888888888", "99999999999"
  ];
  
  const calculateCpfDigit = (sumFunction) =>{
    let sum = sumFunction();
    let rev = 11 - (sum % 11);
    return (rev === 10 || rev === 11) ? 0 : rev;
  }
  
  const calculateSum = (cpf, multiplier) => {
    let sum = 0;
    for (let i = 0; i < multiplier; i++) {
      sum += parseInt(cpf.charAt(i)) * (multiplier + 1 - i);
    }
    return sum;
  }
  
  const validateCPF = (value) => {
    const cpf = value.replace(/[^\d]/g, '');
  
    if (cpf.length !== 11) {
      return false;
    }
    if (invalidCPFs.includes(cpf)) {
      return false;
    }
    
    const j = calculateCpfDigit(() => calculateSum(cpf, 9));
    const k = calculateCpfDigit(() => calculateSum(cpf, 10));
    return !(j !== parseInt(cpf.charAt(9)) || k !== parseInt(cpf.charAt(10)));
  }

function validateCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
  
    if (cnpj.length !== 14) {
      return false;
    }
  
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
  
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
  
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(0)) {
      return false;
    }
  
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
  
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
  
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(1)) {
      return false;
    }

    return true;
}
  

const validations = 
        { validateCardNumber, validateCellPhone, validateCEP, validateDate, validateEmail, validateState,
          validateIsNumber, validateString, validateName, validateCPF, validateCNPJ, validateIsCVVNumber };
export default validations;