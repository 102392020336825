
async function createCardPayment(
  formData,
  setResponseError,
  setResponseType,
  setShowResponse,
  setIsLoading,
  encryptCard,
  toEncrypt
) {

  const phone = formData.IDphone.replace(/\D/g, "");
  const encriptografado = await encryptCard(toEncrypt);
  const cep = formData.addCep.replace(/\D/g, "");
  const installment = parseInt(formData.CCInstallment);
  const payerDocument = formData.HolderCpf.replace(/\D/g, "");
  const registeredDocument = formData.IDcpf.replace(/\D/g, "");


  const payload = {
      payer_document: payerDocument,
      customer: {
        name: formData.IDname,
        email: formData.Email,
        document: registeredDocument,
        phones: [
            {
              area: phone.substr(0, 2),
              country: "55",
              number: phone.substr(2, phone.length),
            },
        ]
    },
    billing_address: {
        street: formData.addAddress,
        number: formData.addNumber,
        locality: formData.addDistrict,
        city: formData.addCity,
        state: formData.addState,
        country: "BRL",
        postal_code: cep
    },
    offer_name: ["educacaoprimopobre"],
    payment_method: {
        encrypted_card: encriptografado.encryptedCard, 
        security_code: formData.CVV,
        installments: installment
    }
  }

  try {
    await fetch(process.env.REACT_APP_URL_CARD_PAYMENT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(payload),
    }).then(async (response) => {
      const resp = await response.json();
      setResponseError('');
      setShowResponse(true);

      if (resp.statusCode === 201) {
        setResponseError('');
        setResponseType('success');

      } else {
        setResponseError("Erro ao gerar pagamento com cartão");
        setResponseType('fail');
      }
      setIsLoading(false);
    });
  } catch (error) {
    setResponseError(error);
    setIsLoading(false);
    return { error: error };
  } 
}
export default createCardPayment;
