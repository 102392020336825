import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormCheck,
  FormLabel,
  Row,
  Spinner,
} from "react-bootstrap";
import ClubbCart from "../components/ClubbCart";
import MemberCart from "../components/MemberCart";
import DataContext from "../contexts/DataContext";
import PaymentContext from "../contexts/PaymentContext";
import createCardPayment from "../utils/createCardPayment";
import createPixPayment from "../utils/createPixPayment";
import createSubscription from "../utils/createSubscription";
import formValidation from "../utils/formValidation";
import getPlanData from "../utils/getPlanData";
import registerTermsAccept from "../utils/registerTermsAccept";
import updateCardSubscription from "../utils/updateCardSubscription";
import sendPrimoPobreTerms from "../utils/registerPrimoPobreTermsAccept";

const Cart = ({
  setIsTermsOpen,
  formData,
  setFormData,
  setShowResponse,
  setResponseType,
  setResponseError,
  cardchange = false
}) => {
  Cart.propTypes = {
    setIsTermsOpen: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
  };
  const { source } = useContext(DataContext);
  const isMembership = source === "membership";
  const isClubb = source !== "membership";
  const { cardData } = useContext(PaymentContext);

  const [errorMsg, setErrorMsg] = useState("");
  const [agreedTerms, setAgreedTerms] = useState(formData.TermsAccepted);
  const [pagSeguro, setPagSeguro] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [termsMsg, setTermsMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [planData, setPlanData] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if(!isLoaded) {
      const script = document.createElement("script");
      script.src = process.env.REACT_APP_PAGSEGURO_FILE;
      script.async = true;  
      script.onload = () => {
        setPagSeguro(window.PagSeguro);
      };
      document.body.appendChild(script);
      setIsLoaded(true);
    }

    const fetchData = async () => {
      const data = await getPlanData(formData.IDdocument, formData.planId);
      setPlanData(data.plan);
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openServiceTerms = () => {
    setFormData({ ...formData, isServiceOpen: true });
  }

  useEffect(() => {
    setAgreedTerms(formData.TermsAccepted);
  }, [formData.TermsAccepted]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMsg(false);
    }, 5000);
    return () => clearTimeout(timer);   
  }, [errorMsg]);

  function doMembershipValidation() {
    if(!cardchange) {
      if (!formData.IDisRepresentative && formData.IDisNotRepresentative) {
        setErrorMsg(": Apenas o representante legal da empresa pode avançar");
        return false;
      }
    }
    const errors = formValidation.formMembershipValidation(formData, cardchange);
    if (errors.length > 0) {
      setErrorMsg(true);
      setFormData({ ...formData, forceValidation: true });
      return false;
    }
    if (!agreedTerms) {
      setErrorMsg(true);
      setTermsMsg(true);
      return false;
    }
    return true;
  }
  
  function doClubbValidation() {
    const errors = formValidation.formClubbValidation(formData);
    if (errors.length > 0) {
      setErrorMsg(true);
      setFormData({ ...formData, forceValidation: true });
      return false;
    }
    if (!agreedTerms || !formData.ServiceAccepted) {
      setErrorMsg(true);
      setTermsMsg(true);
      return false;
    }
    return true;
  }

  async function handleMembershipPayment() {
    const validated = doMembershipValidation();
    if (!validated) return;

    setIsLoading(true);
    let resp = "";

    //Compliance dos termos
    registerTermsAccept(formData);

    try {
        if(!cardchange) {
          resp = await createSubscription(
            formData,
            setResponseError,
            setResponseType,
            setShowResponse,
            setIsLoading,
            pagSeguro.encryptCard,
            cardData
          );
        } else {
          resp = await updateCardSubscription(
            formData,
            setResponseError,
            setResponseType,
            setShowResponse,
            setIsLoading,
            pagSeguro.encryptCard,
            cardData
          );
        }

    } catch (error) {
      setResponseType("fail");
      setShowResponse(true);
      setErrorMsg(resp);
      setIsLoading(false);
    }
  }

  async function handleClubbPayment() {
    const validated = doClubbValidation();
    if (!validated) return;

    setIsLoading(true);
    let resp = "";

    //Compliance dos termos
    sendPrimoPobreTerms(formData, "primo-curso");
    sendPrimoPobreTerms(formData, "primo-investimento");

    try {
        if(formData.payMethod === 'pix') {
          try {
            resp = await createPixPayment(
              formData,
              setResponseError,
              setResponseType,
              setShowResponse,
              setIsLoading,
            );
        } catch (error) {
          setResponseType("fail");
          setShowResponse(true);
          setErrorMsg(resp);
          setIsLoading(false);
        }
        } else {
          try {
              resp = await createCardPayment(
                formData,
                setResponseError,
                setResponseType,
                setShowResponse,
                setIsLoading,
                pagSeguro.encryptCard,
                cardData
              );
          } catch (error) {
            setResponseType("fail");
            setShowResponse(true);
            setErrorMsg(resp);
            setIsLoading(false);
          }
        }

    } catch (error) {
      setResponseType("fail");
      setShowResponse(true);
      setErrorMsg(resp);
      setIsLoading(false);
    }
  }

  async function handlePagamento(e) {
    e.preventDefault();
    setFormData({ ...formData, submitted: true });

    if(isMembership) { 
      await handleMembershipPayment();
    } else { 
      await handleClubbPayment();
    }
  }

  return (
    <Container id="cart">
      {isClubb && <ClubbCart />}
      {isMembership && <MemberCart planData={planData} />}

      {isMembership && (
          <Row className="py-3">
            <Form.Group as={Col} className="pb-3">
              <FormLabel className="terms-advisor text-start flex-wrap">
                  <>
                    <FormCheck
                      type="checkbox"
                      label=""
                      readOnly={true}
                      checked={agreedTerms}
                      onClick={() => {
                        setIsTermsOpen(true);
                        setTermsMsg(true);
                      }}
                    />
                      Ao prosseguir você concordará com
                      <button
                      className="link"
                      onClick={() => {
                        setIsTermsOpen(true);
                        setTermsMsg(true);
                      }}
                    > termo de adesão
                    </button>.
                  </>
              </FormLabel>
            </Form.Group>
          </Row>
      )}

      {isClubb && (<>
          <Row>
          <Form.Group as={Col} >
            <FormLabel className="terms-advisor text-start flex-wrap">
                <FormCheck
                    type="checkbox"
                    label=""
                    readOnly={true}
                    checked={agreedTerms}
                    onClick={() => {
                      setIsTermsOpen(true);
                      setTermsMsg(true);
                    }}
                  />
                    Eu li e aceito o &nbsp;
                    <button
                    className="link"
                    onClick={() => {
                      setIsTermsOpen(true);
                      setTermsMsg(true);
                    }}
                  >termo de adesão
                  </button>.
            </FormLabel>
          </Form.Group>
        </Row>
        <Row className="pb-3">
          <Form.Group as={Col} className="pb-3">
            <FormLabel className="terms-advisor text-start flex-wrap">
                <FormCheck
                    type="checkbox"
                    label=""
                    readOnly={true}
                    checked={formData.ServiceAccepted}
                    onClick={() => {
                      openServiceTerms();
                      setTermsMsg(true);
                    }}
                  />
                    Eu li e aceito a &nbsp;
                    <button
                    className="link"
                    onClick={() => {
                      openServiceTerms();
                      setTermsMsg(true);
                    }}
                  >prestação de serviços
                  </button>.
            </FormLabel>
          </Form.Group>
        </Row>        
        </>
      )}
      
      <Row>
        {!((!agreedTerms && formData.forceValidation)) ? (
          ""
        ) : (
          <div className="invalid-feedback" style={{ display: "block" }}>
            Por favor, aceite o termo para continuar.
          </div>
        )}
      </Row>
      <Row className={errorMsg ? "show" : "hide"}>
        {errorMsg && (
          <Alert key="danger" variant="danger">
            {formData.IDisNotRepresentative && !formData.IDisRepresentative
              ? "Apenas o representante legal da empresa pode avançar"
              : (typeof(errorMsg) === 'string' ? errorMsg 
              : "Por favor, verifique o preenchimento dos dados")
            }
          </Alert>
        )}
      </Row>
      <Row>
        <Button
          className="btn-primary btn"
          onClick={(e) => {
            handlePagamento(e);
          }}
        >
          {isLoading && <Spinner animation="border" />}
          {!isLoading && isMembership && (cardchange ? "Atualizar Pagamento" : "Iniciar Assinatura")}
          {!isLoading && isClubb && "Realizar Pagamento"}
        </Button>
      </Row>
      <Row></Row>
      <Row className="cart-disclaimer">
        <FontAwesomeIcon icon={faLock} style={{ width: "10px" }} /> O pagamento
        é seguro e encriptografado
      </Row>
    </Container>
  );
};

export default Cart;
