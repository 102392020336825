import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContainerTitle = ({title}) => {

    return (
        <div className='header-title'>
            <div className='icon'><FontAwesomeIcon icon={faCircleChevronRight} /></div>
            <div><h1>{title}</h1></div>
        </div>
    )
}

export default ContainerTitle;