
async function updateCardSubscription(
  formData,
  setResponseError,
  setResponseType,
  setShowResponse,
  setIsLoading,
  encryptCard,
  toEncrypt
) {
  const document = formData.IDcpf;
  const encriptografado = await encryptCard(toEncrypt);

  const payload = {
    email: formData.Email,
    document: document.toString(),
    encrypted_card: encriptografado.encryptedCard,
    security_code: formData.CVV.toString()
  };

  try {
    await fetch(process.env.REACT_APP_UPDATE_CARD_URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then(async (response) => {
      const resp = await response.json();
      setResponseError('');
      setShowResponse(true);

      if (resp.statusCode === 200) {
        setResponseError('');
        setResponseType('success');

      } else {
        if(resp.message.includes("already")) {
          resp.message = 'Já existe uma assinatura ativa para este documento';
          setResponseError(resp.message);
        }
        setResponseType('fail');
      }
      setIsLoading(false);
    });
  } catch (error) {
    setResponseError(error);
    setIsLoading(false);
    return { error: error };
  }
}
export default updateCardSubscription;
