import { faCircleDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Container, Row } from 'react-bootstrap';

//* Dados variáveis *//
import { TermsOfServiceContent } from '../components/TermsOfServiceContent';
const termsTitle = 'Termo de Prestação de Serviços';
const modificationDate = 'Última modificação 3 de agosto de 2024';


const TermsOfService = ({formData, setFormData}) => {
  TermsOfService.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired
  };  

  const [hasScrolled, setHasScrolled] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [hasReachedBottom, setHasReachedBottom] = useState(false); 
  const textareaRef = useRef(null);

  const scrollToBottom = () => {
    setIsScrolling(true);
    setHasScrolled(true);
  };

  const handleClickAccept = () => {
    if (!hasScrolled && !hasReachedBottom) {
      setShowAlert(true);
    } else {
      setFormData({ ...formData, ServiceAccepted: true, isServiceOpen: false });
    }
  }

  const closeTerms = () => {
    setFormData({ ...formData, isServiceOpen: false });
  }

  /* Adiciona evento de scroll suave ao textarea */
  useEffect(() => {
    if (isScrolling) {
      const element = textareaRef.current;
      const targetScroll = element.scrollHeight;
      const startScroll = element.scrollTop;
      const duration = 200; 
      const startTime = performance.now();

      const animateScroll = () => {
        const currentTime = performance.now();
        const progress = currentTime - startTime;
        element.scrollTop = startScroll + (targetScroll - startScroll) * Math.min(progress / duration, 1);
        if (progress < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          setIsScrolling(false);
        }
      };
      requestAnimationFrame(animateScroll);
    }
  }, [isScrolling, textareaRef]);

  /* Ajusta a altura do textarea para 65% da tela */
  useEffect(() => {
    const textarea = textareaRef.current;
    const telaAltura = window.innerHeight;
    textarea.style.height = telaAltura * 0.65 + 'px';
  }, []);

  /* Detecta se o usuário rolou até o final */
  useEffect(() => {
    const textarea = textareaRef.current;

    const handleScroll = () => {
      if (textarea.scrollHeight - textarea.scrollTop === textarea.clientHeight) {
        setHasReachedBottom(true);
      }
    };

    if (textarea) {
      textarea.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('scroll', handleScroll);
      }
    };
  }, [textareaRef]);

  /* Controla o componente Alert */
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    return () => clearTimeout(timer);   
  }, [showAlert]);

  return (
    <Container id="terms" className="terms-screen terms-title">
      <Row className='terms-title-border'>
        <div className='terms-title-block'>
          <div>
            <h1>{termsTitle}</h1>
            <h6>{modificationDate}</h6>
          </div>
          <div>
              <button 
                className='terms-close-btn'
                onClick={() => closeTerms()}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
          </div>
        </div>
      </Row>
      <Row className='terms-col'>
        <div className="textarea-simulated" ref={textareaRef} 
            dangerouslySetInnerHTML={{ __html: TermsOfServiceContent }}
        />  
        <div className={`toast-alert ${showAlert ? 'show' : 'hide'}`} >
            { showAlert && (<Alert key='warning' variant='warning'>
              Você deve ler os termos antes de aceitar
            </Alert>
            )}
        </div>    
        <div className='terms-layer'>
          <div className='terms-layer-footer'>
            <Button
              className='btn-primary btn terms-btn'
              onClick={() => handleClickAccept()}
            >
              Aceitar
              </Button>
            <button 
              className='icon icon-big'
              onClick={scrollToBottom}
            >
              <FontAwesomeIcon icon={faCircleDown} />
            </button>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default TermsOfService;