import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import DataContext from '../contexts/DataContext';
import { PaymentProvider } from '../contexts/PaymentContext';
import Address from './Address';
import Cart from './Cart';
import IdentifyForm from './IdentifyForm';
import Payment from './Payment';
import TermsOfService from './TermsOfService';
import TermsOfUse from './TermsOfUse';

function IndexCheckout({ isTermsOpen, setIsTermsOpen, formData, setFormData, setShowResponse, setResponseType, setResponseError }) {
    const { source } = useContext(DataContext);
    const isMembership = source === 'membership';
    const isClubb = source === 'clubb';

    return (
        <PaymentProvider>
            {isTermsOpen  && (
                <TermsOfUse isOpen={isTermsOpen} 
                    setIsTermsOpen={setIsTermsOpen}
                    formData={formData} 
                    setFormData={setFormData}
                    isClubb={isClubb} />
            ) }
            {formData.isServiceOpen  && (
                <TermsOfService 
                    formData={formData} 
                    setFormData={setFormData} />
            ) }
            <Col className={`mainCol ${(isTermsOpen || formData.isServiceOpen) ? 'hide-div ' : ''}`}>
                { isMembership && (
                   <IdentifyForm 
                   formData={formData}
                   setFormData={setFormData} />
                )}
                { isClubb && (
                    <Address 
                    formData={formData}
                    setFormData={setFormData} />
                )}
                <Payment 
                    formData={formData}
                    setFormData={setFormData} />
            </Col>
            <Col className={`userDataCol minCol ${(isTermsOpen || formData.isServiceOpen) ? 'hide-div ' : ''}`}>
                <Cart setIsTermsOpen={setIsTermsOpen}
                    formData={formData}
                    setFormData={setFormData}
                    setShowResponse={setShowResponse}
                    setResponseType={setResponseType}
                    setResponseError={setResponseError}
                 />
            </Col>
        </PaymentProvider>
    );
}

IndexCheckout.propTypes = {
    setIsTermsOpen: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired
};
export default IndexCheckout;
                