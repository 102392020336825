import React from 'react';
import { Container, Row } from 'react-bootstrap';
import pixInstruction1 from '../assets/pix_instruction1.png';
import pixInstruction2 from '../assets/pix_instruction2.png';
import pixInstruction3 from '../assets/pix_instruction3.png';

const PixForm = ({formData, setFormData}) => {
    
    return (
        <Container className='col-md-11 mb-4'>
        <div className='d-flex flex-column'> 
            <Row>
            <div className='pix-column pt-5'>
                <div className='pix-instructions' ><img src={pixInstruction1} alt='' className='img-instructions' /></div>
                <div className='txt-instructions'>Clique em “Realizar pagamento” para obter o código QR de pagamento.</div>
            </div>
            <div className='pix-column pt-2 '>
                <div className='pix-instructions' ><img src={pixInstruction2} alt='' className='img-instructions' /></div>
                <div className='txt-instructions'>Depois faça um pix scaneando ou copiando e colando o código no app do seu banco.</div>
            </div>
            <div className='pix-column pt-2 mb-4'>
                <div className='pix-instructions' ><img src={pixInstruction3} alt='' className='img-instructions' /></div>
                <div className='txt-instructions'>Pronto! Agora é só entrar no site e acessar o seu curso.</div>
            </div>
            </Row>
        </div>
        </Container>
    )
}
export default PixForm;