import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Col, Collapse, Container, Form, Row } from 'react-bootstrap';
import ContainerTitle from '../components/ContainerTitle';
import CredCardForm from '../components/CredCardForm';
import PixForm from '../components/PixForm';
import DataContext from '../contexts/DataContext';

const Payment = ({formData, setFormData}) => {
  Payment.propTypes = {
      formData: PropTypes.object.isRequired,
      setFormData: PropTypes.func.isRequired
  };

  const { source } = useContext(DataContext);
  const [cardSelection, setCardSelection] = useState(true);
  const [pixSelection, setPixSelection] = useState(false);
  const isMembership = source === 'membership';
  const isClubb = source === 'clubb';

  const handleOpen = (index) => {
    switch (index) {
      case 'card':
        setCardSelection(!cardSelection);
        setPixSelection(false);
        setFormData({ ...formData, payMethod: 'card' });
        break;
      case 'pix':
        setCardSelection(false);
        setPixSelection(!pixSelection);
        setFormData({ ...formData, payMethod: 'pix' });
        break;
      default:
        setCardSelection(false);
        setPixSelection(false);
        break;
    }
  }

  return (
    <Container className="userDataCol">
      <Row className='userDataColContent align-items-start mt-4'>
          <Col md={4}>
            <Row>
              <ContainerTitle title='Pagamento' />
            </Row>
            {isClubb && (<>
              <Row>
                <h6>Detalhes do pagamento</h6>
              </Row>
            </>)}
              <Button 
                className={`payment-cardboard payment-title ${cardSelection ? 'payment-cardboard-selected' : ''}`}
                onClick={() => !isMembership && handleOpen('card')}>
                <input type='radio'
                  checked={cardSelection}
                  onClick={() => !isMembership && handleOpen('card')}
                />
                <Form.Label className='payment-name'>Cartão de Crédito</Form.Label>
              </Button>
              {!isMembership && ( 
              <Button 
                className={`payment-cardboard payment-title ${pixSelection ? 'payment-cardboard-selected' : ''}`}
                onClick={() => handleOpen('pix')}>
                <input type='radio'
                  checked={pixSelection}
                  onClick={() => handleOpen('pix')}
                />
                <Form.Label className='payment-name'>PIX</Form.Label>
              </Button>
              )}
          </Col>
          <Col md={8}>
              <Collapse in={cardSelection}>
                <div id="credit-card" className="payment-cardboard">
                  <CredCardForm formData={formData} setFormData={setFormData} />
                </div>
              </Collapse>
              <Collapse in={pixSelection}>
                <div id="pix" className="payment-cardboard">
                  <PixForm formData={formData} setFormData={setFormData} />
                </div>
              </Collapse>
          </Col>
        </Row>
    </Container>
  );
};

export default Payment;
