import { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import validations from '../utils/validations';

import PropTypes from 'prop-types';

const DocumentField = ({doctype, 
                        value, 
                        additionalClass = '', 
                        disabled = false, 
                        info = false, 
                        additional = false, 
                        func = false, 
                        invalid = false, 
                        forceValidation = false
                       }) => {
    DocumentField.propTypes = {
        doctype: PropTypes.string.isRequired,
        value: PropTypes.string,
        additionalClass: PropTypes.string,
        disabled: PropTypes.bool,
        additional: PropTypes.bool,
        func: PropTypes.func,
        forceValidation: PropTypes.bool
    };
    const [document, setDocument] = useState('');
    const [invalidField, setInvalidField] = useState(false);
    const fieldId = !additional ? doctype?.toLowerCase() : (doctype?.toLowerCase() + additional);
    let validateDoc, mask;


    if(doctype === 'CPF' || !doctype) {
        validateDoc = validations.validateCPF;
        mask = '999.999.999-99';
    } else if(doctype === 'CNPJ') {
        validateDoc = validations.validateCNPJ;
        mask = '99.999.999/9999-99';
    }

    const setChange = (value) => {
        setDocument(value);
        setInvalidField(!validateDoc(value));
        if(func) {
            func(value);
        }
    }

    useEffect(() => {
        setDocument(value);
        setInvalidField(invalid);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceValidation, value]);

    return (
        <>
            <Form.Label>{additional !=='Rep' && doctype} </Form.Label>
            <InputGroup hasValidation>
                <InputMask
                    className={`${info ? 'form-control-disabled' : 'form-control'}
                        ${additionalClass} 
                        ${invalidField || invalid ? 'is-invalid' : (document ? 'is-valid' : '')}`}
                    mask={mask}
                    value={document}
                    id={fieldId}
                    type="text"
                    required
                    onClick={info ? () => func(true) : null}
                    onChange={(e) => setChange(e.target.value)}
                    {...disabled && {disabled: true}}
                    />
                    {((document && !invalidField) || !invalid) ? '' : <div className="invalid-feedback">
                        Informe o documento corretamente
                    </div>}
            </InputGroup>
        </>
    )
}

export default DocumentField