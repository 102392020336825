
async function createSubscription(
  formData,
  setResponseError,
  setResponseType,
  setShowResponse,
  setIsLoading,
  encryptCard,
  toEncrypt
) {
  const phone = formData.IDphone.replace(/\D/g, "");
  let document = '';
  if(formData.doctype === "CPF") {
    document = formData.IDdocument;
  } else {
    if(formData.CCpfisica) {
      document = formData.IDcpf;
    } else {  
      document = formData.IDdocument;
    }
  }

  const encriptografado = await encryptCard(toEncrypt);

  const payload = {
    wallet_id: formData.walletId,
    plan_id: formData.planId,
    subscriber_name: formData.IDname,
    subscriber_email: formData.Email,
    subscriber_document: document.toString(),
    subscriber_phones: [
      {
        area: phone.substr(0, 2),
        country: "55",
        number: phone.substr(2, phone.length),
      },
    ],
    subscriber_encrypted_card: encriptografado.encryptedCard,
    card_security_code: formData.CVV,
  };

  try {
    await fetch(process.env.REACT_APP_URL_CREATE_SUBSCRIPTION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(payload),
    }).then(async (response) => {
      const resp = await response.json();
      setResponseError('');
      setShowResponse(true);

      if (resp.message.includes("success")) {
        setResponseError('');
        setResponseType('success');

      } else {
        if(resp.message.includes("already")) {
          resp.message = 'Já existe uma assinatura ativa para este documento';
          setResponseError(resp.message);
        }
        setResponseType('fail');
      }
      setIsLoading(false);
    });
  } catch (error) {
    setResponseError(error);
    setIsLoading(false);
    return { error: error };
  }
}
export default createSubscription;
