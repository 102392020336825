import { faCircleDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Container, Row } from 'react-bootstrap';

//* Dados variáveis *//
import { TermsOfUseClubbContent } from '../components/TermsOfUseClubbContent';
import { TermsOfUseContent } from '../components/TermsOfUseContent';
const termsTitle = 'Termo de Adesão';
const modificationDate = 'Última modificação 16 de setembro de 2024';


const TermsOfUse = ({setIsTermsOpen, formData, setFormData, isClubb = false}) => {
  TermsOfUse.propTypes = {
    setIsTermsOpen: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired
  };  

  const [hasScrolled, setHasScrolled] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  const textareaRef = useRef(null);
  const TermsContent = isClubb ? TermsOfUseClubbContent : TermsOfUseContent;

  const scrollToBottom = () => {
    setIsScrolling(true);
    setHasScrolled(true);
  };

  const handleClickAccept = () => {
    if (!hasScrolled && !hasReachedBottom) {
      setShowAlert(true);
    } else {
      setFormData({ ...formData, TermsAccepted: true });
      setIsTermsOpen(false);
    }
  }

  /* Adiciona evento de scroll suave ao textarea */
  useEffect(() => {
    if (isScrolling) {
      const element = textareaRef.current;
      const targetScroll = element.scrollHeight;
      const startScroll = element.scrollTop;
      const duration = 200; 
      const startTime = performance.now();

      const animateScroll = () => {
        const currentTime = performance.now();
        const progress = currentTime - startTime;
        element.scrollTop = startScroll + (targetScroll - startScroll) * Math.min(progress / duration, 1);
        if (progress < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          setIsScrolling(false);
        }
      };
      requestAnimationFrame(animateScroll);
    }
  }, [isScrolling, textareaRef]);

  /* Ajusta a altura do textarea para 65% da tela */
  useEffect(() => {
    const textarea = textareaRef.current;
    const telaAltura = window.innerHeight;
    textarea.style.height = telaAltura * 0.65 + 'px';
  }, []);

  /* Detecta se o usuário rolou até o final */
  useEffect(() => {
    const textarea = textareaRef.current;

    const handleScroll = () => {
      if (textarea.scrollHeight - textarea.scrollTop === textarea.clientHeight) {
        setHasReachedBottom(true);
      }
    };

    if (textarea) {
      textarea.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('scroll', handleScroll);
      }
    };
  }, [textareaRef]);

  /* Controla o componente Alert */
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    return () => clearTimeout(timer);   
  }, [showAlert]);

  return (
    <Container id="terms" className="terms-screen terms-title">
      <Row className='terms-title-border'>
        <div className='terms-title-block'>
          <div>
            <h1>{termsTitle}</h1>
            <h6>{modificationDate}</h6>
          </div>
          <div>
              <button 
                className='terms-close-btn'
                onClick={() => setIsTermsOpen(false)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
          </div>
        </div>
      </Row>
      <Row className='terms-col'>
        <div className="textarea-simulated" ref={textareaRef} 
            dangerouslySetInnerHTML={{ __html: TermsContent }}
        />  
        <div className={`toast-alert ${showAlert ? 'show' : 'hide'}`} >
            { showAlert && (<Alert key='warning' variant='warning'>
              Você deve ler os termos antes de aceitar
            </Alert>
            )}
        </div>    
        <div className='terms-layer'>
          <div className='terms-layer-footer'>
            <Button
              className='btn-primary btn terms-btn'
              onClick={() => handleClickAccept()}
            >
              Aceitar
              </Button>
            <button 
              className='icon icon-big'
              onClick={scrollToBottom}
            >
              <FontAwesomeIcon icon={faCircleDown} />
            </button>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default TermsOfUse;