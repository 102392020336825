/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import DataContext from '../contexts/DataContext';
import { decrypt } from '../utils/decrypt';
import decryptV1 from '../utils/decryptV1';
import IndexCheckout from './IndexCheckout';
import RegisterForm from './RegisterForm';
import Response from './Response';
import TermsOfUseReadOnly from './TermsOfUseReadOnly';

const urlParams = new URLSearchParams(window.location.search);
let userDocument = urlParams.get('user');
let planId = urlParams.get('id'); //usado somente em membership
let walletId = urlParams.get('walletId'); //usado somente em membership
let readonly = urlParams.get('readonly'); //parametro para exibir somente os termos de uso sem o checkout
let ivParams = urlParams.get("iv"); //dados de auth vindos do clubb 
let dataParams = urlParams.get("data"); //dados de auth vindos do clubb 

const getDoctype = (document) => {
    if(document) {
        if(document.length === 11) {
            return 'CPF';
        } else if(document.length === 14) {
            return 'CNPJ';
        } else {
            return '';
        }
    }
    return '';
}

const getDocument = (document) => {
    if(document) {
        return decryptV1(urlParams.get('user'), process.env.REACT_APP_DECRIPTY_KEY);
    }
    return '';
}

const decryptedParams = async () => {
    if(ivParams && dataParams) {
        const params = await decrypt(ivParams, dataParams);
        return params;
    } 
    return null;
}

function Index({isTermsOpen, setIsTermsOpen}) { 
    const { source } = useContext(DataContext);
    const isMembership = source === 'membership';
    const isClubb = source === 'clubb';

    const [formData, setFormData] = useState({});
    const [showResponse, setShowResponse] = useState(false);
    const [responseType, setResponseType] = useState(false);
    const [responseError, setResponseError] = useState('');
    const [localError, setLocalError] = useState(false);


    if(planId) planId = planId?.toString();
    if(walletId) walletId = walletId?.toString();

    //Carrega dados iniciais de sessão
    useEffect(() => {
        if(isClubb) {
            const fetchData = async () => {
                await decryptedParams().then((params) => {
                    if(params) {
                        const param = JSON.parse(params);
                        setFormData({...formData, 
                            IDcpf: param.cpf,
                            Email: param.email,
                            IDphone: param.celular,
                            IDname: param.nomeCompleto
                        }); 
                    } else {
                        setLocalError(true);
                    }
                });
            };
            fetchData();
        }

        if(isMembership) {
            setFormData({...formData, 
                IDdoctype: getDoctype(userDocument), 
                IDdocument: getDocument(userDocument), 
                IDcpf: (getDoctype(userDocument) === 'CPF' ? getDocument(userDocument) : ''),
                planId: planId,
                walletId: walletId,
            });
        }
    }, []); 

    return (
            <Container>
                { !localError && (
                <div>
                    { isClubb && !showResponse && (
                    <RegisterForm 
                    formData={formData}
                    setFormData={setFormData} />
                    )}
                    {((isMembership && formData.IDdocument && formData.IDdoctype && formData.planId && formData.walletId) || isClubb) && ( 
                    <>
                        <div className={`checkoutPage ${showResponse ? 'hide-div' : ''}`}>
                            <IndexCheckout 
                                    isTermsOpen={isTermsOpen} 
                                    setIsTermsOpen={setIsTermsOpen} 
                                    formData={formData} 
                                    setFormData={setFormData}
                                    setShowResponse={setShowResponse}
                                    setResponseType={setResponseType}
                                    setResponseError={setResponseError}
                            />
                        </div>
                        <div className={`${!showResponse ? 'hide-div' : ''}`}>
                            {showResponse && (
                            <Response 
                                type={responseType} 
                                setShowResponse={setShowResponse} 
                                responseError={responseError}
                                show={showResponse} />
                            )}
                        </div>
                    </>
                    )}
                    {!readonly && (isMembership && !userDocument) && (
                        <div className='pt-5' style={{color: 'white'}}><h1>Erro: Cliente não informado</h1></div>
                    )}
                    {!readonly && (!planId) && (isMembership && formData.IDdocument && formData.IDdoctype) && (
                        <div className='pt-5' style={{color: 'white'}}><h1>Erro: Plano de assinatura não informado</h1></div>
                    )}
                    {!readonly && (!walletId) && (planId) && (isMembership && formData.IDdocument && formData.IDdoctype) && (
                        <div className='pt-5' style={{color: 'white'}}><h1>Erro: Dados incorretos</h1></div>
                    )}
                    {readonly && isMembership && (
                        <TermsOfUseReadOnly />
                    )}
                </div>
            )}
            { localError && (
                <div className='pt-5' style={{color: 'white'}}><h1>Erro: Dados de Cliente não encontrados</h1></div>
            )}
            </Container>
    );
} 

Index.propTypes = {
    isTermsOpen: PropTypes.bool.isRequired,
    setIsTermsOpen: PropTypes.func.isRequired
};

export default Index;
