/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Container, Spinner } from 'react-bootstrap';
import { PaymentProvider } from '../contexts/PaymentContext';
import Cart from './Cart';
import ClientInfo from './ClientInfo';
import Payment from './Payment';
import Response from './Response';
import TermsOfUse from './TermsOfUse';

const urlParams = new URLSearchParams(window.location.search);
let planId = urlParams.get('id');
let walletId = urlParams.get('walletId');

const getDoctype = (document) => {
    if(document) {
        if(document.length === 11) {
            return 'CPF';
        } else if(document.length === 14) {
            return 'CNPJ';
        } else {
            return '';
        }
    }
    return '';
}

function CardChange({isTermsOpen, setIsTermsOpen}) { 
    const [formData, setFormData] = useState({});
    const [showResponse, setShowResponse] = useState(false);
    const [responseType, setResponseType] = useState(false);
    const [responseError, setResponseError] = useState('');
    const [isReady, setIsReady] = useState(false);

    if(planId) planId = planId.toString();
    if(walletId) walletId = walletId.toString();

    const getWalletData = () => {
        setIsReady(false);
        try {
            fetch(`${process.env.REACT_APP_WALLETDATA_URL}&walletId=${walletId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Connection: "keep-alive",
            },
            }).then(async (response) => {
                const data = await response.json();
                const walletData = data.data;
                if(!walletData?.wallet) {
                    return;
                }
                const userDocument = walletData.wallet.document;
                setFormData({...formData, 
                    IDdoctype: getDoctype(userDocument), 
                    IDdocument: userDocument, 
                    IDcpf: walletData?.subscriber.document,
                    IDname: walletData?.subscriber.name,
                    IDphone: walletData?.subscriber.phone,
                    Email: walletData?.subscriber.email,
                    planId: planId,
                    walletId: walletId
                });
            })
        } catch (error) {
            console.error('Erro ao obter os termos:', error);
        } finally {
            setIsReady(true); 
        }
    }

    useEffect(() => {
        getWalletData();
    }, []); 

    return (
            <Container>
                <div>
                    {formData.IDdocument && formData.IDdoctype && formData.planId && formData.walletId && ( 
                    <>
                        <div className={`checkoutPage ${showResponse ? 'hide-div' : ''}`}>
                        <PaymentProvider>
                            {isTermsOpen  && (
                                <TermsOfUse isOpen={isTermsOpen} 
                                    setIsTermsOpen={setIsTermsOpen}
                                    formData={formData} 
                                    setFormData={setFormData} />
                            ) }
                            {isReady  && (<>
                                <Col className={`mainCol ${isTermsOpen ? 'hide-div ' : ''}`}>
                                    <ClientInfo 
                                        formData={formData}
                                        editable={false} />
                                    <Payment 
                                        formData={formData}
                                        setFormData={setFormData} />
                                </Col>
                                <Col className={`userDataCol minCol ${isTermsOpen ? 'hide-div ' : ''}`}>
                                    <Cart setIsTermsOpen={setIsTermsOpen}
                                        formData={formData}
                                        setFormData={setFormData}
                                        setShowResponse={setShowResponse}
                                        setResponseType={setResponseType}
                                        setResponseError={setResponseError}
                                        cardchange={true}
                                    />
                                </Col>
                        </>) }
                        
                        </PaymentProvider>
                        </div>
                        <div className={`${!showResponse ? 'hide-div' : ''}`}>
                            <Response 
                                type={responseType} 
                                setShowResponse={setShowResponse} 
                                responseError={responseError} />
                        </div>
                    </>
                    )}
                    {!formData.walletId && (
                        <div className='d-flex justify-content-center'>
                            <h1>Dados não encontrados</h1>
                        </div>
                    )}
                </div>
                {!isReady  && (
                    <Spinner animation="border" 
                                variant="secondary" 
                                className='pt-6'
                                style={{width: '6rem', height: '6rem'} }
                        />
                )}
            </Container>
    );
} 

CardChange.propTypes = {
    isTermsOpen: PropTypes.bool.isRequired,
    setIsTermsOpen: PropTypes.func.isRequired
};

export default CardChange;
