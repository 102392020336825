const decryptV1 = (encrypted, key) => {
    const keyStr = key.toString();
    let decrypted = '';
    for (let i = 0; i < encrypted.length; i++) {
        const encryptedDigit = parseInt(encrypted[i]);
        const keyDigit = parseInt(keyStr[i % keyStr.length]);
        const decryptedDigit = (encryptedDigit - keyDigit + 10) % 10;
        decrypted += decryptedDigit.toString();
    }
    return decrypted;
}

export default decryptV1;