import PropTypes from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';

const urlParams = new URLSearchParams(window.location.search);
let campaign = (urlParams.get('camp') === 'clubb') ? 'clubb' : 'membership';
const DataContext = createContext(campaign);

export const SourceProvider = ({ children }) => {
  SourceProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  const [source, setSource] = useState(campaign);
  const [isAccepted, setIsAccepted] = useState(false);

  return (
    <DataContext.Provider value={useMemo(() => ({ source, setSource, isAccepted, setIsAccepted }), [source, setSource, isAccepted, setIsAccepted])}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;