import PropTypes from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';

const PaymentContext = createContext({});

export const PaymentProvider = ({ children }) => {
  PaymentProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  const [cardData, setCardData] = useState('');
  const contextValue = useMemo(() => ({ cardData, setCardData }), [cardData, setCardData]);

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContext;