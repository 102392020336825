function formatarData(data = new Date()) {
    const mesesDoAno = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
  
    const diaMes = String(data.getDate()).padStart(2, '0');
    const mes = mesesDoAno[data.getMonth()];
    const ano = data.getFullYear();
  
    return `${diaMes} de ${mes} de ${ano}`;
}

export default formatarData;