/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Form, FormCheck, FormLabel, Row } from 'react-bootstrap';
import DocumentField from './DocumentField';

const DocumentHolderData = ({doctype, formData, setFormData}) => {
    DocumentHolderData.propTypes = {
        doctype: PropTypes.string.isRequired,
    };
    const [isTitular, setIsTitular] = useState(false);
    const [pFisica, setPFisica] = useState(doctype === 'CPF' ? true : false);
    const [pJuridica, setPJuridica] = useState(false);

    const saveCheckboxPF = (value) => {
        setPFisica(value);
        setPJuridica(false);
        setFormData({ ...formData, CCpjuridica: false, CCpfisica: value });
    }
    const saveCheckboxPJ = (value) => {
        setPJuridica(value);
        setPFisica(false);
        setFormData({ ...formData, CCpjuridica: value, CCpfisica: false });
    }
    const saveConfirmTitular = (value) => {
        setIsTitular(value);
        setFormData({ ...formData, CCisTitular: value });
    }
    useEffect(() => {
        setIsTitular(formData?.CCisTitular || isTitular);
        setPFisica(formData?.CCpfisica || pFisica);
        setPJuridica(formData?.CCpjuridica || pJuridica);
    }, [formData.IDcpf]); 


    return (
        <>
            <Row className="pt-2">
                <h2>{doctype === 'CNPJ' ? 'Selecione o ' : 'Dados do '} titular do cartão</h2>
            </Row>
            {doctype === 'CNPJ' && (
            <Row>
                <Form.Group as={Row} md={4}>
                <div className='check-group col-md-12'>
                    <div className='col-md-6'>
                    <FormLabel>
                    <FormCheck
                        type="checkbox"
                        label=" "
                        checked={pFisica}
                        onClick={(e) => saveCheckboxPF(e.target.checked)}
                    />Pessoa Física
                    </FormLabel>
                    </div>
                    <div className='col-md-6'>
                    <FormLabel>
                    <FormCheck
                        type="checkbox"
                        label=" "
                        checked={pJuridica}
                        onClick={(e) => saveCheckboxPJ(e.target.checked)}
                    />Pessoa Jurídica
                    </FormLabel>
                    </div>
                </div>
                {!(formData.forceValidation && (!pFisica && !pJuridica)) ? '' : 
                <div className="invalid-feedback" style={{display: 'block'}}>
                  Informe se o titular do cartão é pessoa física ou jurídica
                </div>
              }
                </Form.Group>
            </Row>
            )}
            {(pFisica || pJuridica) && (<>
                <Row>
                    {pFisica && (
                        <DocumentField 
                            additionalClass='form-control-payment' doctype='CPF' 
                            additional='Hold'
                            value={formData.IDcpf ? formData.IDcpf : 
                                    (formData.IDdocument.strlen === 11 ? formData.IDdocument : '')}
                            disabled={true}
                        />
                    )}
                    {pJuridica && (
                        <DocumentField 
                            additionalClass='form-control-payment' doctype='CNPJ' 
                            additional='Hold'
                            value={formData.IDdocument}
                            disabled={true}
                        />
                    )}
                </Row>
                <Row className="pt-2">
                    <Form.Group as={Col} className='pb-3'>
                        <FormLabel> 
                        <FormCheck
                            type="checkbox"
                            label=" "
                            checked={isTitular}
                            onClick={(e) => saveConfirmTitular(e.target.checked)}
                        />
                        {(pJuridica ? 'Confirmo que a empresa é a titular do cartão'
                                    : (doctype === 'CNPJ') ? 'Confirmo que sou o representante legal da empresa'
                                    : 'Confirmo que o cartão é de minha titularidade')}
                        
                        
                        </FormLabel>
                        {!(!isTitular && formData.forceValidation === true) ? '' : <div className="invalid-feedback"  style={{display: 'block'}}>
                            Necessário confirmar ser o representante legal da empresa
                        </div>}
                    </Form.Group>
                </Row></>
            )}
        </>
    );
}
            
export default DocumentHolderData;