import formatarData from '../utils/formatarData';

export const TermsOfUseContent = `
<!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.6.7.2 (Linux)"/>
	<meta name="author" content="Bruno von Medem"/>
	<meta name="created" content="2024-08-06T13:22:00"/>
	<meta name="AppVersion" content="16.0000"/>
	<meta name="DocSecurity" content="4"/>
	<meta name="DocSite.WarnToSaveToDS" content="X"/>
	<style type="text/css">
		@page { size: 8.27in 11.69in; margin-left: 0.79in; margin-right: 0.59in; margin-top: 0.47in; margin-bottom: 0.47in }
		p { text-align: left; orphans: 2; widows: 2; margin-bottom: 0.08in; direction: ltr; background: transparent }
		p.western { so-language: en-US }
		p.cjk { so-language: x-none }
		p.ctl { font-size: 10pt }
		body { font-family: 'Inter', serif; color:#989898}
		.centered { text-align: center; }
	</style>
</head>
<body lang="pt-BR" link="#000080" vlink="#800000" dir="ltr";
>
<div title="header"><p style="margin-bottom: 0.47in"><br/>

	</p>
</div>
<p className="western centered" style="margin-bottom: 0in; border: none; padding: 0in; f">
<b>TERMO DE ADESÃO E OUTRAS AVENÇAS</b></p>
<p className="western centered" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 11pt">Por
este instrumento particular, </font>
</p>
<p className="western" style="margin-bottom: 0in; border: none; padding: 0in; f">
<br/>

</body>
</html>



</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in; border: none; padding: 0in">
	<font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">A
	pessoa, física ou jurídica, que preencheu seus dados pessoais/sua
	qualificação no formulário localizado no link </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u><a target='_blank' href=https://bossa-compliance-files.s3.us-east-2.amazonaws.com/terms/membership/version/1/termo_de_adesao_scp_membership.pdf style='cursor: pointer'>Termo de adesão</a></u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	e aderiu aos termos deste instrumento, </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">doravante
	denominado simplesmente como </font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">“Novo
	Sócio Participante”; e</font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in"><a name="_Hlk171439781"></a>
	<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>POOL
	BOSSA NOVA INVESTIMENTOS LTDA</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.,
	sociedade empresária limitada inscrita no CNPJ sob o n°
	25.453.024/0001-27, com sede e foro na Rua Joaquim Floriano, nº
	466, Edifício Brascan Century Corporate, 9º andar, bairro Itaim
	Bibi, em São Paulo, SP, CEP: 04.534-002, com endereço de e-mail:
	</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>representante.legal@bossainvest.com</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
	neste ato representada nos termos de seu Contrato Social, doravante
	denominado simplesmente como “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
	Ostensivo</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”.</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in">“<font face="Supreme, serif"><font size="2" style="font-size: 11pt">Novo
Sócio Participante” e “Sócio Ostensivo” doravante
denominados, em conjunto, “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócios</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”
e, isoladamente, “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”.</font></font></p>
<p className="western" align="justify" style="font-variant: small-caps; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Considerando
que: </font></font>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">o
	Sócio Ostensivo e a Bossa Nova Investimentos e Administração S.A.
	(“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
	Participante Principal</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”)
	constituíram uma sociedade em conta de participação denominada
	POOL BOSSA NOVA INVESTIMENTOS LTDA. SCP MEMBERSHIP (“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sociedade</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”);</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">a
	Sociedade é regida por Contrato Social (“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Anexo
	A</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”)
	e por Acordo de Sócios (“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Anexo
	B</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”)
	celebrados entre o Sócio Ostensivo e o Sócio Participante
	Principal e suas posteriores alterações (“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Contrato
	Social</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”
	e “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Acordo
	de Sócios</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”,
	respectivamente); e</font></font></p></li>
</ol>
<p className="western" style="margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
 <ol type="i" start="3">
	<li><p className="western" align="justify" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt">o
	Novo Sócio Participante deseja ingressar na Sociedade.</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Resolvem
os Sócios, de mútuo e comum acordo, celebrar este Termo de Adesão,
o qual será regido pelas seguintes disposições:</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
i</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Definições
</b></font></font></span>
</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<ol>
	<ol>
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Expressões
		em letra maiúscula têm os mesmos significados previstos no
		Contrato Social e no Acordo de Sócios.</font></font></p></li>
	</ol>
</ol>
<p className="western" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
ii</b></font></font></span></p>
<p className="western" align="center" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Ingresso
	na sociedade como sócio participante </b></font></font></span>
	</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol>
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Por
		presente Termo de Adesão, o Novo Sócio Participante ingressa na
		Sociedade mediante aporte financeiro, conforme especificado nos
		itens a seguir, realizando o pagamento com cartão de crédito de
		sua titularidade ou outro meio de pagamento escolhido e aceito
		pelas partes, em favor do Sócio Ostensivo, enquanto representante
		legal da Sociedade.</font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol start="2">
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
		ingresso do Novo Sócio Participante, nos termos do item 2.1 acima,
		dar-se-á pela seleção de algum dos planos de adesão
		(individualmente, denominado como “Plano” e, conjuntamente,
		denominados como “Planos”) indicados abaixo, cujos
		‘Benefícios’, vigentes pelo período de 12 (doze) meses, estão
		sinalizadas na tabela a seguir, sendo certo que, para fins de
		classNameificação, pela Sociedade, do Plano elegido pelo Novo Sócio
		Participante, será considerado (i) o valor por ele pago
		mensalmente e/ou (ii) o Plano indicado quando da adesão à
		Sociedade, por meio do </font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">link
		</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u><a target='_blank' href=https://bossa-compliance-files.s3.us-east-2.amazonaws.com/terms/membership/version/1/termo_de_adesao_scp_membership.pdf style='cursor: pointer'>Termo de adesão</a><!-- Vini, necessário indicar o link onde os co-investidores aderirão ao Membership. --></u></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
		.</font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p><center>
<table width="661" cellpadding="7" cellspacing="0">
	<col width="79"/>
	<col width="288"/>
	<col width="128"/>
	<col width="108"/>
	<tr>
		<td colspan="4" width="645" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>TABELA
			DESCRITIVA</b></font></font></font></font></p>
		</td>
	</tr>
	<tr>
		<td width="79" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>PLANO</b></font></font></font></font></p>
		</td>
		<td width="288" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>VALOR
			A INTEGRALIZAR</b></font></font></font></font></p>
		</td>
		<td width="128" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>BENEFÍCIOS</b></font></font></font></font></p>
		</td>
		<td width="108" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>INVESTBACK*</b></font></font></font></font></p>
		</td>
	</tr>
	<tr>
		<td width="79" height="32" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Bronze</font></font></font></font></p>
		</td>
		<td width="288" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">12
			(doze) parcelas mensais e sucessivas de R$ 999,00 (novecentos e
			noventa e nove reais)</font></font></font></font></p>
		</td>
		<td width="128" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Conforme
			item 2.2.1</font></font></font></font></p>
		</td>
		<td width="108" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">1%
			(um por cento)</font></font></font></font></p>
		</td>
	</tr>
	<tr>
		<td width="79" height="33" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Prata</font></font></font></font></p>
		</td>
		<td width="288" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">12
			(doze) parcelas mensais e sucessivas de R$ 3.999,00 (três mil,
			novecentos e noventa e nove reais)</font></font></font></font></p>
		</td>
		<td width="128" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Conforme
			item 2.2.2</font></font></font></font></p>
		</td>
		<td width="108" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">2%
			(dois por cento)</font></font></font></font></p>
		</td>
	</tr>
	<tr>
		<td width="79" height="33" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Ouro</font></font></font></font></p>
		</td>
		<td width="288" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">12
			(doze) parcelas mensais e sucessivas de R$ 6.999,00 (seis mil,
			novecentos e noventa e nove reais)</font></font></font></font></p>
		</td>
		<td width="128" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Conforme
			item 2.2.3</font></font></font></font></p>
		</td>
		<td width="108" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">3%
			(três por cento)</font></font></font></font></p>
		</td>
	</tr>
	<tr>
		<td width="79" height="31" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Diamante</font></font></font></font></p>
		</td>
		<td width="288" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">12
			(doze) parcelas mensais e sucessivas de R$ 9.999,00 (nove mil,
			novecentos e noventa e nove reais)</font></font></font></font></p>
		</td>
		<td width="128" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Conforme
			item 2.2.4</font></font></font></font></p>
		</td>
		<td width="108" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">4%
			(quatro por cento)</font></font></font></font></p>
		</td>
	</tr>
	<tr>
		<td width="79" height="33" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Hero</font></font></font></font></p>
		</td>
		<td width="288" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">12
			(doze) parcelas mensais e sucessivas de R$ 30.000,00 (trinta mil
			reais)</font></font></font></font></p>
		</td>
		<td width="128" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Conforme
			item 2.2.5</font></font></font></font></p>
		</td>
		<td width="108" style="border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center" style="orphans: 2; widows: 2">
			<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">5%
			(cinco por cento)</font></font></font></font></p>
		</td>
	</tr>
</table></center><br /><br />
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>*
</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Para
todos os fins deste instrumento, o termo ‘Investback’ significa a
cessão gratuita, pelo Sócio Ostensivo (ou por outra empresa de seu
grupo econômico), de participação nos fundos sociais desta
Sociedade (ou de outra sociedade em conta de participação em que o
Sócio Ostensivo seja sócio ostensivo ou, ainda, que empresa de seu
grupo econômico seja sócio ostensivo) ao Novo Sócio Participante,
cujo montante será calculado por meio da adoção do percentual
mencionado no quadrante relacionado ao Plano selecionado pelo Novo
Sócio Participante sobre o ‘Valor a Integralizar’, conforme
tabela acima, bem como de quaisquer outros valores integralizados
pelo Novo Sócio Participante, a partir desta data e apenas durante
os meses nos quais estiver integralizando as parcelas do ‘Valor a
Integralizar’ deste Termo de Adesão, em quaisquer outras
Sociedades em Conta de Participação que tenha como sócio ostensivo
o Sócio Ostensivo ou outra empresa de seu grupo econômico.</font></font></p>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<br/>

</p>
<ol start="2">
	<ol>
		<ol>
			<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
			Plano Bronze assegura aos Sócios que a ele aderirem os seguintes
			‘Benefícios’:</font></font></p></li>
		</ol>
	</ol>
</ol>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<br/>

</p>
<ol type="a">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">01
	(um) acesso comum ao evento organizado pelo Sócio Ostensivo (ou por
	outra empresa de seu grupo econômico) denominado como ‘Bossa
	Summit’; e</font></font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="2">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">no
	item 5.1. (ii) (a) do Acordo de Sócios da Sociedade, ao invés dos
	Retornos do Investimento lhes serem pagos, na proporção de seus
	Direitos Econômicos, deduzidos em 20% (vinte por cento), serão
	deduzidos em 19% (dezenove por cento), cabendo, portanto, ao Sócio
	Participante Principal, nos termos do item 5.1. (ii) (c) do Acordo
	de Sócios da Sociedade, o acréscimo aos Retornos do Investimento
	que lhe sejam devidos, na proporção de seus Direitos Econômicos,
	em 19% (dezenove por cento).</font></font></font></p></li>
</ol>
<ol start="2">
	<ol>
		<ol start="2">
			<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
			Plano Prata assegura aos Sócios que a ele aderirem os seguintes
			‘Benefícios’:</font></font></p></li>
		</ol>
	</ol>
</ol>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<br/>

</p>
<ol type="a">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">01
	(um) acesso VIP ao evento organizado pelo Sócio Ostensivo (ou por
	outra empresa de seu grupo econômico) denominado como ‘Bossa
	Summit’; e</font></font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="2">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">no
	item 5.1. (ii) (a) do Acordo de Sócios da Sociedade, ao invés dos
	Retornos do Investimento lhes serem pagos, na proporção de seus
	Direitos Econômicos, deduzidos em 20% (vinte por cento), serão
	deduzidos em 18% (dezoito por cento), cabendo, portanto, ao Sócio
	Participante Principal, nos termos do item 5.1. (ii) (c) do Acordo
	de Sócios da Sociedade, o acréscimo aos Retornos do Investimento
	que lhe sejam devidos, na proporção de seus Direitos Econômicos,
	em 18% (dezoito por cento).</font></font></font></p></li>
</ol>
<ol start="2">
	<ol>
		<ol start="3">
			<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
			Plano Ouro assegura aos Sócios que a ele aderirem os seguintes
			‘Benefícios’:</font></font></p></li>
		</ol>
	</ol>
</ol>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<br/>

</p>
<ol type="a">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">01
	(um) acesso VIP ao evento organizado pelo Sócio Ostensivo (ou por
	outra empresa de seu grupo econômico) denominado como ‘Bossa
	Summit’; e</font></font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="2">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">no
	item 5.1. (ii) (a) do Acordo de Sócios da Sociedade, ao invés dos
	Retornos do Investimento lhes serem pagos, na proporção de seus
	Direitos Econômicos, deduzidos em 20% (vinte por cento), serão
	deduzidos em 17,6% (dezessete vírgula seis por cento), cabendo,
	portanto, ao Sócio Participante Principal, nos termos do item 5.1.
	(ii) (c) do Acordo de Sócios da Sociedade, o acréscimo aos
	Retornos do Investimento que lhe sejam devidos, na proporção de
	seus Direitos Econômicos, em 17,6% (dezessete vírgula seis por
	cento).</font></font></font></p></li>
</ol>
<ol start="2">
	<ol>
		<ol start="4">
			<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
			Plano Diamante assegura aos Sócios que a ele aderirem os
			seguintes ‘Benefícios’:</font></font></p></li>
		</ol>
	</ol>
</ol>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<br/>

</p>
<ol type="a">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">02
	(dois) acessos VIPs ao evento organizado pelo Sócio Ostensivo (ou
	por outra empresa de seu grupo econômico) denominado como ‘Bossa
	Summit’;</font></font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="2">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">no
	item 5.1. (ii) (a) do Acordo de Sócios da Sociedade, ao invés dos
	Retornos do Investimento lhes serem pagos, na proporção de seus
	Direitos Econômicos, deduzidos em 20% (vinte por cento), serão
	deduzidos em 17% (dezessete por cento), cabendo, portanto, ao Sócio
	Participante Principal, nos termos do item 5.1. (ii) (c) do Acordo
	de Sócios da Sociedade, o acréscimo aos Retornos do Investimento
	que lhe sejam devidos, na proporção de seus Direitos Econômicos,
	em 17% (dezessete por cento);</font></font></font></p></li>
</ol>
<p lang="x-none" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="3">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">acesso
	às oportunidades de investimento do Sócio Ostensivo (ou de outra
	empresa de seu grupo econômico), mediante disponibilização, a
	exclusivo critério do Sócio Ostensivo (ou de outra empresa de seu
	grupo econômico) de material nesse sentido; e</font></font></font></p></li>
</ol>
<ol start="2">
	<ol>
		<ol start="5">
			<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
			Plano Hero assegura aos Sócios que a ele aderirem os seguintes
			‘Benefícios’:</font></font></p></li>
		</ol>
	</ol>
</ol>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<br/>

</p>
<ol type="a">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">05
	(cinco) acessos VIPs ao evento organizado pelo Sócio Ostensivo (ou
	por outra empresa de seu grupo econômico) denominado como ‘Bossa
	Summit’;</font></font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="2">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">no
	item 5.1. (ii) (a) do Acordo de Sócios da Sociedade, ao invés dos
	Retornos do Investimento lhes serem pagos, na proporção de seus
	Direitos Econômicos, deduzidos em 20% (vinte por cento), serão
	deduzidos em 14% (quatorze por cento), cabendo, portanto, ao Sócio
	Participante Principal, nos termos do item 5.1. (ii) (c) do Acordo
	de Sócios da Sociedade, o acréscimo aos Retornos do Investimento
	que lhe sejam devidos, na proporção de seus Direitos Econômicos,
	em 14% (quatorze por cento);</font></font></font></p></li>
</ol>
<p lang="x-none" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="3">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">acesso
	às oportunidades de investimento do Sócio Ostensivo (ou de outra
	empresa de seu grupo econômico), mediante disponibilização, a
	exclusivo critério do Sócio Ostensivo (ou de outra empresa de seu
	grupo econômico) de material nesse sentido;</font></font></font></p></li>
</ol>
<p lang="x-none" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="4">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">direito
	de solicitar 01 (uma) análise trimestral, ao Sócio Ostensivo (ou a
	outra empresa de seu grupo econômico), para fins de avaliação de
	possível investimento, de empresas por eles indicadas,
	respectivamente;</font></font></font></p></li>
</ol>
<p lang="x-none" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="5">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">direito
	de ter uma  coluna no portal de notícias integrante do grupo
	econômico do Sócio Ostensivo denominado como ‘Startupi’ e
	publicar, uma vez por mês, artigo de sua autoria contendo sua
	opinião pessoal sobre temas correlatos à linha editorial do
	Startupi, qual seja, venture capital, startups, tecnologia e
	inovação; e</font></font></font></p></li>
</ol>
<p lang="x-none" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="6">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">01
	(um) acesso de uso único anual ao evento organizado por parceiro do
	Sócio Ostensivo denominado como ‘Jornada Equity’.</font></font></font></p></li>
</ol>
<ol start="2">
	<ol>
		<ol start="6">
			<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Em
			nenhuma hipótese, os ‘Benefícios’ dos demais Planos serão
			cumulados aos ‘Benefícios’ do Plano elegido pelo Novo Sócio
			Participante, cabendo-lhe, portanto, apenas os ‘Benefícios’
			relacionados ao Plano que tiver elegido, conforme tabela acima.</font></font></p></li>
		</ol>
	</ol>
</ol>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<br/>

</p>
<ol start="2">
	<ol>
		<ol start="7">
			<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Eventualmente,
			caso o Novo Sócio Participante realize pagamento à Sociedade em
			valor distinto àqueles relacionados aos Planos, conforme tabela
			acima, a Sociedade considerará, como Plano elegido pelo Novo
			Sócio Participante, o Plano de maior valor financeiro que esteja
			coberto pela quantia paga pelo Novo Sócio Participante à
			Sociedade.</font></font></p></li>
		</ol>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol>
		<ol>
			<ol>
				<li><p className="western" align="justify" style="margin-bottom: 0in">
				<font face="Supreme, serif"><font size="2" style="font-size: 11pt">Para
				todos os efeitos, o primeiro pagamento por parte do ‘Novo Sócio
				Participante’ será utilizado como critério de classNameificação
				do Plano elegido, de forma que, caso pague quantia distinta no(s)
				próximo(s) mês(es), poderá o Sócio Ostensivo adotar as
				medidas descritas no item 2.3.1 abaixo.</font></font></p></li>
			</ol>
		</ol>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.25in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol start="2">
	<ol start="3">
		<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">O
		Novo Sócio Participante se compromete, neste ato, a pagar,
		tempestivamente, o valor descrito no quadrante imediatamente ao
		lado do Plano selecionado, conforme indicado na coluna ‘Valor a
		Integralizar’.</font></font></font></p>
		<ol>
			<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Em
			consonância com o art. 474 do Código Civil, na hipótese de o
			Novo Sócio Participante não realizar a integralização de sua
			participação societária, nos termos descritos nos itens
			anteriores, fica facultado ao Sócio Ostensivo, a seu exclusivo
			critério, (i) o cancelamento deste Termo de Adesão, restando
			resolvidas as obrigações acordadas nesse instrumento e, por
			conseguinte, ensejando em seu distrato automático, caso não
			tenha ocorrido qualquer pagamento ou (ii) o cancelamento imediato
			dos ‘Benefícios’ atribuídos ao Plano cujo Novo Sócio
			Participante tenha aderido e a restituição da(s)
			participação(ões) societária(s) recebida(s) a título de
			‘</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Investback’</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
			nos termos da cláusula 2.5. infra, caso não tenha realizado 01
			(um) ou mais pagamentos, contudo, </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>desde
			que tenha realizado o pagamento da primeira parcela</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></p></li>
		</ol>
	</ol>
</ol>
<p className="western" align="justify" style="margin-left: 0.25in; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>2.3.2.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Caso
o cenário mencionado no item 2.3.1 (ii) acima seja regularizado, a
participação assegurada ao Novo Sócio Participante a título de
‘</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Investback’</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
bem como os demais ‘Benefícios’ aos quais esteja vinculado,
conforme Plano elegido, serão restabelecidos.</font></font></p>
<p className="western" align="justify" style="margin-left: 0.25in; margin-bottom: 0in">
<br/>

</p>
<ol start="2">
	<ol start="4">
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Os
		‘Benefícios’ assegurados aos Sócios, nos termos do item 2.2 e
		subitens, vigorarão enquanto os Sócios estiverem realizando o
		pagamento do ‘Valor a Integralizar’, conforme item 2.3 acima,
		sempre em tempo e modo. Nesse sentido, concluído o pagamento da
		totalidade do ‘Valor a Integralizar’, os ‘Benefícios’
		atribuídos aos Sócios, conforme Plano elegido, serão revogados
		de pleno direito, independentemente de os Sócios terem (ou não)
		utilizado, cabendo aos Sócios, portanto, </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>(a)</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
		os Direitos Econômicos na Sociedade, conforme disposto no item 2.7
		(ii) abaixo, bem como </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>(b)</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
		a(s) participação(ões) societária(s) recebida(s) a título de
		‘</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Investback’.</font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol>
		<ol>
			<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
			<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">Caso
			os Sócios decidam por manter os ‘Benefícios’ vigentes,
			conforme Plano elegido, deverão assinar, juntamente com o Sócio
			Ostensivo, termo aditivo ao presente instrumento, de forma a
			repactuar os valores e condições que vincularão as referidas
			partes.</font></font></font></p></li>
		</ol>
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Neste
		ato, o Novo Sócio Participante outorga ao Sócio Ostensivo uma
		opção de compra da(s) participação(ões) societária(s) por ele
		recebida(s) a título de ‘</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Investback’</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
		exercível nesta </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Sociedade
		(ou em outra sociedade em conta de participação em que o Sócio
		Ostensivo seja sócio ostensivo ou, ainda, que empresa de seu grupo
		econômico seja sócio ostensivo), </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>vigente
		pelo período de 02 (dois) anos a contar da data de assinatura
		deste instrumento</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
		(“Opção de Compra”).</font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>2.5.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	A
</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Opção
de Compra poderá ser exercida pelo Sócio Ostensivo ou por qualquer
empresa de seu grupo econômico.</font></font></p>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>2.5.2.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
preço da Opção de Compra será de R$ 1,00 (um real), tendo o Sócio
Ostensivo (ou a empresa de seu grupo econômico que eventualmente
exercer a Opção de Compra) poderes para adotar todas as medidas de
natureza jurídica e contábil para concretizar a transação.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-left: 0.39in; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>2.5.3.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">A
</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Opção
de Compra somente será exercível se o Novo Sócio Participante
incidir no cenário descrito no item </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">2.3.1
(ii) acima.</font></font></p>
<p className="western" align="justify" style="margin-left: 0.25in; margin-bottom: 0in">
<br/>

</p>
<ol start="2">
	<ol start="6">
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Fica
		igualmente facultado ao Sócio Ostensivo o cancelamento deste Termo
		de Adesão, na plataforma clicksign, caso o Novo Sócio
		Participante não seja aprovado em análise de risco por ele
		realizada, hipótese que ensejará na devolução integral do valor
		integralizado, bem como no distrato automático deste instrumento.</font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol start="7">
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Em
		razão do ingresso do Novo Sócio Participante na Sociedade:</font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">o
	Sócio Ostensivo continua sendo titular de 100% (cem por cento) dos
	Direitos Políticos na Sociedade; e</font></font></p></li>
</ol>
<p className="western" style="margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">o
	Novo Sócio Participante passa a ser titular de percentual dos
	Direitos Econômicos na Sociedade, conforme condições constantes
	no Acordo de Sócios.</font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in; border: none; padding: 0in">
<br/>
<br/>

</p>
<ol start="2">
	<ol>
		<ol>
			<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in; border: none; padding: 0in">
			<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">O
			Novo Sócio Participante compromete-se a informar ao Sócio
			Ostensivo sobre eventual mudança de seus dados bancários, com
			antecedência de 15 (quinze) dias sob pena de ser considerada
			válida a conta ora informada para todos os fins.</font></font></font></font></p></li>
		</ol>
	</ol>
</ol>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
iii</b></font></font></span></p>
<p className="western" align="center" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Adesão
ao contrato social e ao acordo de sócios</b></font></font></span></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>3.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	O
Novo Sócio Participante adere, neste ato, de forma integral,
irrevogável e irretratável, ao Contrato Social e ao Acordo de
Sócios, sem ressalvas, objeções e/ou restrições.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>3.2.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	O
Novo Sócio Participante compromete-se a cumprir o Contrato Social e
o Acordo de Sócios.</font></font></p>
<p className="western" align="center" style="font-variant: small-caps; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula</b></font></font></span><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>
</b></font></font> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>iv</b></font></font></span></p>
<p className="western" align="center" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>declarações
do novo sócio participante</b></font></font></span></p>
<p className="western" align="center" style="font-variant: small-caps; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>4.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	O
Novo Sócio Participante declara neste ato que:</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
	possui processo ativo de recuperação judicial, extrajudicial ou
	falência, se pessoa jurídica;</font></font></p></li>
</ol>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
	se qualifica como pessoa politicamente exposta, conforme definido no
	Anexo I, se pessoa física;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="3">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
	possui processo (suspenso, baixado ou ativo) de insolvência, se
	pessoa física;</font></font></p></li>
</ol>
<p className="western" style="margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
 <ol type="i" start="4">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
	é réu em processo de execução fiscal (municipal, estadual ou
	federal);</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="5">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
	é réu em processo trabalhista;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="6">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
	é réu em processos criminais relacionados a desvio e lavagem de
	dinheiro; falsificação; pirataria; contrafação; propina; fraude
	ou crimes fiscais;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="7">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
	é réu em processos criminais relacionados a tráfico ilícito de
	substâncias entorpecentes ou drogas afins; financiamento do
	terrorismo; financiamento, contrabando ou tráfico de armas e
	munições; crimes contra a vida; roubo ou furto; se pessoa física;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="8">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
	é réu e/ou não está, de alguma forma, relacionado a crimes
	contra a Administração Pública, tais como, corrupção; emprego
	irregular de verbas públicas; dentre outros, bem como prática ou
	omissão de atos que caracterizem crimes praticados contra a
	Administração Pública estrangeira;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"> 
</p>
 <ol type="i" start="9">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
	é réu e/ou não está, de alguma forma, relacionado a crimes
	contra o sistema financeiro nacional, tais como a falsificação de
	títulos e valores mobiliários; a obtenção, mediante fraude, de
	empréstimo em banco; aplicação, em finalidade diversa da prevista
	em lei, de recursos provenientes de financiamento concedido por
	instituição financeira oficial ou por instituição credenciada a
	repassá-lo;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="10">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">realizou,
	por conta própria e/ou com ajuda de seus advogados/contadores,
	análise de risco para tomar a decisão de aportar recursos na
	Sociedade, não tendo levado em consideração, portanto, apenas as
	razões divulgadas pelo Sócio Ostensivo;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="11">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que fomentar empresas de tecnologia emergentes (“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>startups</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”)
	é uma atividade de risco, podendo ensejar na perda integral dos
	recursos ora aportados;</font></font></p></li>
</ol>
<p className="western" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="12">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que as </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>startups
	</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">estão
	na fase inicial de suas operações e, em geral, para se
	estabelecerem no mercado, competirão com grandes e renomados
	concorrentes;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="13">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que, ao assinar este instrumento, tornar-se-á sócio participante
	de uma sociedade em conta de participação, cujas atividades e
	resultados envolvem e dependem do desempenho de </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>startups;</i></font></font></p></li>
</ol>
<p className="western" style="line-height: 115%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
 <ol type="i" start="14">
	<li><p className="western" align="justify" style="line-height: 115%; margin-bottom: 0in; border: none; padding: 0in">
	<font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que, em virtude do risco da atividade objeto da Sociedade, eventos
	de retorno de investimentos que envolvam Eventos de Liquidez em
	Dinheiro e/ou Eventos de Liquidez in Natura, frutos de relações
	contratuais entre o Sócio Ostensivo/Sociedade e terceiro(s) que
	determinem, independentemente do motivo, a restituição de parcela
	ou, ainda, da totalidade dos valores recebidos pelo Sócio
	Ostensivo/Sociedade em decorrência dos referidos eventos,
	ensejarão, ao Novo Sócio Participante, no dever de restituir ao
	Sócio Ostensivo/Sociedade os valores repassados ao Novo Sócio
	Participante (e não mais que a totalidade dos valores pagos pelo
	Sócio Ostensivo/Sociedade nesse sentido), devidamente corrigidos
	por indexador negociado em cada relação contratual, desde a data
	de seu pagamento pelo Sócio Ostensivo/Sociedade;</font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="15">
	<li><p className="western" align="justify" style="line-height: 115%; margin-bottom: 0in; border: none; padding: 0in">
	<font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que na hipótese mencionada no item anterior, o Sócio Ostensivo
	notificará o Novo Sócio Participante para, no prazo de até 05
	(cinco) dias úteis, realizar a devolução dos valores repassados
	pelo Sócio Ostensivo/Sociedade (e não mais que a totalidade dos
	valores pagos pelo Sócio Ostensivo/Sociedade nesse sentido),
	devidamente corrigidos pelos indexadores aplicáveis aos cenários
	em comento, desde a data de seu pagamento pelo Sócio
	Ostensivo/Sociedade;</font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="16">
	<li><p className="western" align="justify" style="line-height: 115%; margin-bottom: 0in; border: none; padding: 0in">
	<font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que, caso não realize a devolução dos valores cabíveis, conforme
	estipulado nos itens ‘xiv’ e ‘xv’ acima, seus respectivos
	Direitos Econômicos na Sociedade restarão suspensos, de modo que,
	havendo futuros valores a serem repassados pelo Sócio
	Ostensivo/Sociedade, serão devidos à Sociedade as referidas
	quantias até que o Novo Sócio Participante inadimplente cumpra com
	suas obrigações ou então que os valores excedentes, decorrentes
	da suspensão, sejam suficientes para compensar os valores não
	restituídos;</font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="17">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que o Sócio Ostensivo ficará a cargo da operação da Sociedade,
	não podendo, por exemplo, contatar diretamente às </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>startups</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	investidas;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="18">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que parcela do valor aportado, conforme disposto no Acordo de
	Sócios, será destinada ao custeio da operação da Sociedade;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="19">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que qualquer deliberação acerca das </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>startups
	</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">investidas
	será de responsabilidade exclusiva do Sócio Ostensivo, não lhe
	cabendo qualquer consulta e/ou aos demais sócios participantes,
	salvo disposto em contrário nos documentos constitutivos;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="20">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que o Sócio Ostensivo tem autonomia para aceitar ou rejeitar
	qualquer oportunidade de negócios relacionada ao objeto da
	Sociedade, podendo, inclusive, vetar o ingresso de outros sócios
	participantes na Sociedade, salvo disposto em contrário nos
	documentos constitutivos;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="21">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que o Sócio Ostensivo poderá tomar decisões acerca das </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>startups
	</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">com
	base em informações incompletas e/ou não verificadas, podendo,
	ainda, alocar recursos da Sociedade junto à investidores notáveis,
	hipóteses em que não necessariamente realizará análise de risco;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="22">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que o Sócio Ostensivo terá acesso a informações relevantes e
	confidenciais sobre as </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>startups,
	</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">que
	não necessariamente serão compartilhadas;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="23">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que eventuais valores não alocados poderão lhe ser devolvidos;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="24">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">entende
	que todas as comunicações poderão ser realizadas por e-mail; </font></font>
	</p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="25">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">leu
	todos os documentos relacionados à Sociedade e fez todas as
	perguntas pertinentes à presente operação ao Sócio Ostensivo,
	razão pela qual optou por ingressar na Sociedade;</font></font></p></li>
</ol>
<p className="western" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="26">
	<li><p className="western" align="justify" style="line-height: 115%; margin-bottom: 0in; border: none; padding: 0in">
	<font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">está
	ciente que, havendo quaisquer pagamentos pela Sociedade e/ou pelo
	Sócio Ostensivo que sejam superiores àqueles que lhe seriam
	cabíveis, em decorrência de seus Direitos Econômicos na
	Sociedade, estará o Sócio Ostensivo autorizado a deduzir o
	montante excedente de futuros pagamentos ao Sócio Participante,
	inclusive daqueles decorrentes de Retornos do Investimento; e</font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="27">
	<li><p className="western" align="justify" style="line-height: 115%; margin-bottom: 0.14in; border: none; padding: 0in">
	<font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">aceita
	qualquer meio idôneo de comprovação de pagamento para fins de
	prova de recebimentos direta ou indiretamente relacionados à
	Sociedade, incluindo, mas não se limitando, àqueles provenientes
	de Retornos do Investimento.</font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>4.2.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	A
inobservância ou inveracidade das declarações aqui prestadas
caracterizam violação às obrigações contratuais, podendo a parte
lesada lançar mão de medidas legais cabíveis, como, por exemplo,
mas não se limitando a imediata resilição unilateral motivada
deste instrumento e indenização por prejuízos e danos incorridos.</font></font></p>
<p className="western" align="center" style="font-variant: small-caps; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula</b></font></font></span><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>
</b></font></font> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>v</b></font></font></span></p>
<p className="western" align="center" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Adesão
Eletrônica</b></font></font></span></p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>5.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Os
Sócios envolvidas neste Termo de Adesão afirmam e declaram que o
presente documento poderá ser aderido por meio eletrônico, sendo
consideradas válidas as adesões se feitas pelo </font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">link
</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u><a target='_blank' href=https://bossa-compliance-files.s3.us-east-2.amazonaws.com/terms/membership/version/1/termo_de_adesao_scp_membership.pdf style='cursor: pointer'>Termo de adesão</a><!-- Vini, necessário indicar o link onde os co-investidores aderirão ao Membership. --></u></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="right" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">São
Paulo, SP, </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">${formatarData()}</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>



</p>
<p className="western" align="center" style="margin-bottom: 0in; page-break-before: always">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u><b>ANEXO
I</b></u></font></font></p>

<p className="western" align="center" style="line-height: 115%; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>DEFINIÇÃO
DE PESSOA POLITICAMENTE EXPOSTA (“PPE”)</b></font></font></p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>


</p>
<p className="western" align="justify" style="margin-right: -0in; border: none; padding: 0in">
<font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Em
atenção às disposições legais vigentes, consideram-se pessoas
politicamente expostas (“PPE”) os agentes públicos que
desempenham ou tenham desempenhado, nos últimos 05 (cinco) anos, no
Brasil ou em países, territórios e dependências estrangeiros,
cargos, empregos ou funções públicas relevantes, assim como seus
representantes, familiares (parentes, na linha direta, até o
primeiro grau, cônjuge, companheiro(a) ou enteado(a)) e outras
pessoas de seu relacionamento próximo.</font></font></font></p>
<p className="western" align="justify" style="margin-right: -0in; border: none; padding: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-right: -0in; border: none; padding: 0in">
<font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Portanto,
são consideradas pessoas politicamente expostas:</font></font></font></p>
 <ol type="i">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-right: -0in; margin-top: 0.02in; border: none; padding: 0in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">os
	detentores de mandatos eletivos dos Poderes Executivo e Legislativo
	da União;</font></font></font></font></p></li>
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-right: -0in; margin-top: 0.02in; border: none; padding: 0in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">os
	ocupantes de cargo, no Poder Executivo da União</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">;</span></font></font></font></font></p></li>
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-right: -0in; margin-top: 0.02in; border: none; padding: 0in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">os
	membros do Conselho Nacional de Justiça, do Supremo Tribunal
	Federal e dos tribunais superiores (Superior Tribunal de Justiça,
	Tribunal Superior do Trabalho, Tribunal Superior Militar, Tribunal
	Superior Eleitoral);</font></font></font></font></p></li>
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-right: -0in; margin-top: 0.02in; border: none; padding: 0in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">os
	membros do Conselho Nacional do Ministério Público, o
	Procurador-Geral da República, o Vice- Procurador-Geral da
	República, o Procurador-Geral do Trabalho, o Procurador-Geral da
	Justiça Militar, os Subprocuradores-Gerais da República e os
	Procuradores-Gerais de Justiça dos estados e do Distrito Federal;</font></font></font></font></p></li>
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-right: -0in; margin-top: 0.02in; border: none; padding: 0in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">os
	membros do Tribunal de Contas da União e o Procurador-Geral do
	Ministério Público junto ao Tribunal de Contas da União;</font></font></font></font></p></li>
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-right: -0in; margin-top: 0.02in; border: none; padding: 0in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">os
	governadores de estado e do Distrito Federal, os presidentes de
	tribunal de justiça, de assembleia legislativa e de câmara
	distrital e os presidentes de tribunal e de conselho de contas de
	estado, de municípios e do Distrito Federal;</font></font></font></font></p></li>
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-right: -0in; margin-top: 0.02in; border: none; padding: 0in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">os
	prefeitos e presidentes de câmara municipal de capitais de estados.</font></font></font></font></p></li>
</ol>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in; page-break-before: always">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u><b>ANEXO
A</b></u></font></font></p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="line-height: 115%; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>CONTRATO
DE CONSTITUIÇÃO DE SOCIEDADE EM CONTA DE PARTICIPAÇÃO</b></font></font></p>
<p className="western" align="center" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>POOL
BOSSA NOVA INVESTIMENTOS LTDA. SCP MEMBERSHIP</b></font></font></p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Pelo
presente instrumento particular,</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>(i)	POOL
BOSSA NOVA INVESTIMENTOS LTDA</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.,
sociedade empresária limitada inscrita no CNPJ sob o n°
25.453.024/0001-27, com sede e foro na Rua Joaquim Floriano, nº 466,
Edifício Brascan Century Corporate, 9º andar, bairro Itaim Bibi, em
São Paulo, SP, CEP: 04.534-002, com endereço de e-mail:
</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>representante.legal@bossainvest.com</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
neste ato representada nos termos de seu Contrato Social, doravante
denominado</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
como “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
Ostensivo</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”;
e </font></font>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>(ii)	</b></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>BOSSA
NOVA INVESTIMENTOS E ADMINISTRAÇÃO S.A.</b></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">sociedade
anônima fechada inscrita no CNPJ sob o nº 13.568.149/0001-13, com
sede e foro na Rua Joaquim Floriano, nº 466, Brascan Century
Corporate, 9º andar, bairro Itaim Bibi, em São Paulo, SP, CEP:
04.534-002, endereço de e-mail: </font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>representante.legal@bossainvest.com</u></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
neste ato representada nos termos de seu Estatuto Social, doravante
denominado como </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
Participante Principal</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in">“<font face="Supreme, serif"><font size="2" style="font-size: 11pt">Sócio
Ostensivo” e “Sócio Participante Principal” doravante
denominados, em conjunto, “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócios</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”
e, isoladamente, “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Resolvem
os Sócios constituir uma Sociedade em Conta de Participação
(“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sociedade
ou SCP</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”),
nos termos dos artigos 991 e seguintes do Código Civil Brasileiro e
da legislação em vigor, e de acordo com as seguintes cláusulas e
condições:</font></font></p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
I</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>definições</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<ol>
	<ol>
		<li><p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
		<font face="Supreme, serif"><font size="2" style="font-size: 11pt">As
		palavras, expressões e abreviações com as letras iniciais
		maiúsculas, não definidas em outras partes deste Contrato Social,
		no singular ou no plural, terão o significado atribuído a elas no
		</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Anexo
		A.I</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></p></li>
	</ol>
</ol>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
II</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>denominação,
objeto, sede e prazo </b></font></font></span>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol>
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">A
		Sociedade </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">é</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
		despersonificada e será regida por este Contrato Social, pelos
		artigos 991 a 996 do Código Civil</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
		Brasileiro</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">,
		pelos artigos 160 e 161 </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">do
		</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">Regulamento
		do Imposto de Renda e demais normas aplicáveis.</font></font></font></font></p></li>
	</ol>
</ol>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol start="2">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">A
		Sociedade será denominada de</font><font face="Supreme, serif"><span lang="pt-BR">
		POOL BOSSA NOVA INVESTIMENTOS LTDA. SCP MEMBERSHIP.</span></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<ol start="2">
	<ol start="3">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">A
		Sociedade terá sua sede social na </font><font color="#FFFFFF"><font face="Supreme, serif">Rua
		Tomé de Souza, nº 669, 9º andar, bairro Savassi, em Belo
		Horizonte, MG, CEP: 30.140-131</font></font><font face="Supreme, serif">.</font><font face="Supreme, serif">
		</font></font></font>
		</p></li>
	</ol>
</ol>
<p lang="x-none" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol start="4">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in"><a name="_Hlk150790712"></a>
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">O
		objeto social da Sociedade será a prospecção de oportunidades de
		negócios em </font><font face="Supreme, serif"><i>Startups</i></font><font face="Supreme, serif"><span lang="pt-BR">
		</span></font><font face="Supreme, serif">para fins de investimento
		direto e/ou indireto, por meio da celebração de títulos privados
		conversíveis em participação societária e</font><font face="Supreme, serif"><span lang="pt-BR">/ou</span></font><font face="Supreme, serif">
		outros instrumentos, conforme oportunidade e necessidade</font><font face="Supreme, serif"><span lang="pt-BR">
		(“</span></font><font face="Supreme, serif"><span lang="pt-BR"><i>Special
		Deals</i></span></font><font face="Supreme, serif"><span lang="pt-BR">”).</span></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol start="5">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">A
		Sociedade vigorará por prazo determinado</font><font face="Supreme, serif"><span lang="pt-BR">,
		</span></font><font face="Supreme, serif">iniciando suas atividades
		</font><font face="Supreme, serif"><span lang="pt-BR">nesta data e
		terminando em</span></font><font face="Supreme, serif"> </font><font face="Supreme, serif"><span lang="pt-BR">04
		de julho de 2034.</span></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" style="line-height: 100%; margin-bottom: 0in"><font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">	</font></font></font></p>
<ol start="2">
	<ol start="6">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">Findo
		o prazo previsto no item 2.5, </font><font face="Supreme, serif"><span lang="pt-BR">o</span></font><font face="Supreme, serif">s
		Sócios acordam que o prazo de duração da Sociedade será
		automaticamente prorrogado pelo tempo necessário para</font><font face="Supreme, serif"><span lang="pt-BR">
		a</span></font><font face="Supreme, serif"> solução de </font><font face="Supreme, serif"><span lang="pt-BR">eventuais
		</span></font><font face="Supreme, serif">questões sociais,
		arbitrais, judiciais ou extrajudiciais envolvendo a Sociedade.</font></font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
III</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Fundos
sociais</b></font></font></span></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>3.1.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Os
fundos sociais da Sociedade serão de até R$ 3.000.200,00 (três
milhões e duzentos reais) (“Fundos Sociais”), cuja subscrição
e integralização serão divididas entre os Sócios da seguinte
forma:</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><a name="_Hlk33117047"></a>
	<font face="Supreme, serif"><font size="2" style="font-size: 11pt">o
	Sócio Ostensivo subscreve R$ 3.000.100,00 (três milhões e cem
	reais), divididos da seguinte forma: (a) R$ 100,00 (cem reais),
	quantia ora integralizada em  moeda corrente nacional; e (b) R$
	3.000.000,00 (três milhões de reais), quantia a ser integralizada
	em moeda corrente nacional, ao longo do prazo de duração da
	Sociedade; e</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">o
	Sócio Participante Principal subscreve R$ 100,00 (cem reais),
	quantia ora integralizada em moeda corrente nacional.</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-left: 1.5in; margin-bottom: 0in">
<br/>

</p>
<ol start="3">
	<ol start="2">
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Os
		Direitos Econômicos e os Direitos Políticos dos Sócios na
		Sociedade são aqueles definidos no Acordo de Sócios.</font></font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<ol start="3">
	<ol start="2">
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Por
		deliberação unilateral do Sócio Ostensivo, a parcela não
		integralizada dos Fundos Sociais, consubstanciada na alínea (i) do
		item 3.1, enquanto estiver apenas subscrita, poderá ser extinta,
		resultando na redução dos Fundos Sociais.</font></font></p></li>
	</ol>
</ol>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
IV</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>administração</b></font></font></span></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><b>4.1.</b></font><font face="Supreme, serif">	A
administração da Sociedade competirá exclusivamente </font><font face="Supreme, serif"><span lang="pt-BR">ao</span></font><font face="Supreme, serif">
</font><font face="Supreme, serif"><span lang="pt-BR">representante
legal do </span></font><font face="Supreme, serif">Sócio Ostensivo,
tendo, n</font><font face="Supreme, serif"><span lang="pt-BR">esta</span></font><font face="Supreme, serif">
condição</font><font face="Supreme, serif"><span lang="pt-BR">,</span></font><font face="Supreme, serif">
amplos e gerais poderes de administração, podendo assinar
contratos, </font><font face="Supreme, serif"><span lang="pt-BR">títulos</span></font><font face="Supreme, serif">
e demais documentos; </font><font face="Supreme, serif"><span lang="pt-BR">aplicar
e investir os recursos financeiros da Sociedade em </span></font><font face="Supreme, serif"><i>Startups</i></font><font face="Supreme, serif"><span lang="pt-BR">;
emprestar os recursos financeiros da Sociedade ao Sócio Ostensivo;</span></font><font face="Supreme, serif">
</font><font face="Supreme, serif"><span lang="pt-BR">contrair</span></font><font face="Supreme, serif">
obrigações; enfim, praticar todos e quaisquer atos necessários à
consecução do objeto social.</font></font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><b>4.2.</b></font><font face="Supreme, serif">	</font><font face="Supreme, serif"><span lang="pt-BR">O</span></font><font face="Supreme, serif">
Sócio Ostensivo é o único Sócio investido de poderes e autorizado
a agir e se obrigar perante terceiros nos negócios, operações e
atividades da Sociedade.</font><font face="Supreme, serif"><span lang="pt-BR">
Nenhum Sócio Participante poderá agir ou se obrigar em nome da
Sociedade.</span></font></font></font></p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
V</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>aporte
de recursos e ingresso de novos sócios</b></font></font></span></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>5.1.</b></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Em
virtude da necessidade de recursos financeiros para a consecução do
objeto social, caso o Sócio Ostensivo prospecte novas oportunidades
de negócios em </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
o Sócio Ostensivo poderá, a seu exclusivo critério, admitir novos
sócios na Sociedade, mediante (i) o aporte de recursos financeiros
na Sociedade</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
e assinatura de Termo de Adesão, </font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">ou
(ii) crédito utilizado pelo Novo Sócio Participante </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">e
assinatura do Termo de Cessão.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
VI</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>obrigações
do sócio ostensivo</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>6.1.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Sem
prejuízo de outras obrigações previstas neste Contrato Social e na
legislação aplicável, são obrigações do Sócio Ostensivo:</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">manter
	escrituração contábil e fiscal em livros próprios, segundo as
	normas societárias e contábeis aplicáveis;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">administrar
	e gerir os negócios, atividades e operações da Sociedade,
	isoladamente;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="3">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">responsabilizar-se
	pelo recolhimento dos tributos incidentes sobre os negócios,
	atividades e operações da Sociedade;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="4">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">apresentar
	aos órgãos públicos competentes as declarações, formulários e
	informações exigidas pela legislação em vigor; </font></font>
	</p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="5">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">responsabilizar-se
	pelo levantamento das demonstrações financeiras da Sociedade; e</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="6">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">observar,
	cumprir e respeitar todas as cláusulas, termos, artigos, normas e
	condições deste Contrato Social e da legislação em vigor.</font></font></p></li>
</ol>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>6.2.
   </b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">As
receitas, despesas, resultados, ativos e passivos da Sociedade serão
escriturados nos livros e contabilidade do Sócio Ostensivo de forma
individualizada e separada.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
VII</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>obrigações
dos sócios participantes</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<ol start="7">
	<ol>
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><span lang="pt-BR">Sem
		prejuízo de outras obrigações previstas neste Contrato Social e
		na legislação aplicável, são obrigações dos Sócios
		Participantes:</span></font></font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">abster-se
	de agir ou se obrigar nos negócios, atividades e/ou operações da
	Sociedade perante terceiros;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">responder
	pessoalmente perante terceiros, a Sociedade e os demais Sócios por
	danos ou prejuízos causados em decorrência de suas ações, atos
	e/ou omissões que infringirem este Contrato Social e/ou a
	legislação em vigor; e</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="3">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">observar,
	cumprir e respeitar todas as cláusulas, termos, artigos, normas e
	condições deste Contrato Social e da legislação em vigor.</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
VIII</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>transferência
de participações </b></font></font></span>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>8.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Qualquer
cessão, transferência e/ou outra forma de alienação, gratuita ou
onerosa, direta ou indireta, de parte ou da totalidade de
participação societária detida por Sócio Participante na
Sociedade dependerá da prévia e expressa autorização do Sócio
Ostensivo. </font></font>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>8.2.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	O
Sócio Ostensivo poderá ceder, transferir e/ou de qualquer outra
forma alienar, de modo gratuito ou oneroso, direta ou indiretamente,
parte de sua participação societária na Sociedade para quaisquer
terceiros ou para outro Sócio, independentemente da concordância ou
ciência dos Sócios Participantes, mediante assinatura de Termo de
Cessão, permanecendo, todavia, como o único Sócio Ostensivo e
administrador da Sociedade.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
IX</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>exclusão
e retirada de sócios</b></font></font></span></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>9.1.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Considerando
as características da Sociedade, fica facultado, ao Sócio
Ostensivo, sua dissolução parcial, bem como a exclusão e retirada
de Sócios, desde que estes tenham (i) atentado contra a Sociedade;
e/ou (ii) descumprido quaisquer das obrigações sociais assumidas;
e/ou (iii) não tenham sido aprovados em </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Due
Diligences</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
realizadas periodicamente conforme melhores padrões praticados no
mercado.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"> 
</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
X</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>remuneração
do sócio ostensivo</b></font></font></span></p>
<p className="western" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>10.1.	</b></font></font></span><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
Sócio Ostensivo poderá receber remuneração mensal, a título de
</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>pro
labore</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
pelos serviços de administração da Sociedade, a ser fixada pela
Assembleia Geral.</font></font></p>
<p className="western" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XI</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>assembleia
geral e quórum de deliberação </b></font></font></span>
</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>11.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	A
Assembleia Geral é o órgão superior da Sociedade e possui plenos
poderes para deliberar sobre todos os negócios relativos ao objeto
social e tomar as providências que julgar conveniente à defesa e ao
desenvolvimento da Sociedade, podendo, inclusive, alterar este
Contrato Social.</font></font></p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>11.2.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">A
Assembleia Geral será convocada pelo Sócio Ostensivo, por e-mail,
enviado aos Sócios Participantes, com antecedência mínima de 05
(cinco) dias da data da Assembleia Geral.</font></font></p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>11.3.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">As
Assembleias Gerais serão realizadas, conforme previsto na respectiva
convocação, na sede do Sócio Ostensivo, em outro local ou por
vídeo conferência.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>11.4.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Dispensam-se
as formalidades de convocação, quando os Sócios titulares de 100%
(cem por cento) dos Direitos Políticos comparecerem ou se
declararam, por escrito, cientes do local, data, hora e ordem do dia.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>11.5.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	A
Assembleia será presidida e secretariada por pessoas indicadas pelo
Sócio Ostensivo, sendo que o quórum de instalação dependerá da
presença dos Sócios titulares de 100% (cem por cento) dos Direitos
Políticos.</font></font></p>
<p className="western" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>11.6.	</b></font></font></span><font face="Supreme, serif"><font size="2" style="font-size: 11pt">As
deliberações sociais serão tomadas em Assembleia Geral e aprovadas
por Sócios titulares de 100% (cem por cento) dos Direitos Políticos.</font></font></p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>11.7.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Toda
e quaisquer deliberações submetidas à Assembleia Geral exigirão,
para serem aprovadas, o voto afirmativo do Sócio Ostensivo.</font></font></p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XII</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>exercício
social</b></font></font></span></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>12.1.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
exercício social coincidirá com o ano civil.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XIII</b></font></font></span></p>
<p lang="x-none" align="center" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"> <font face="Supreme, serif"><span lang="pt-BR"><b>distribuição
de lucros</b></span></font></span></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>13.1.</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">	</span></font></font><font face="Supreme, serif"><span lang="pt-BR">Ao
final de cada exercício social será apurado o resultado da
Sociedade, que será distribuído aos Sócios nos termos do Acordo de
Sócios.</span></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>13.2.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">A
Sociedade poderá levantar balanços mensais, trimestrais ou
semestrais, de suas contas, bem como o resultado para apresentação
aos Sócios. Os lucros apurados nos balanços intermediários poderão
ser distribuídos aos Sócios, nos termos do Acordo.</span></font></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XIV</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>concorrência</b></font></font></span></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<ol start="14">
	<ol>
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Os</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
		Sócio</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">s</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
		poder</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">ão</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">,
		independentemente da ciência e/ou concordância dos</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
		demais Sócios,</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
		constituir outras sociedades, personificadas ou despersonificadas,
		com propósitos semelhantes ou idênticos aos da Sociedade, em
		qualquer localidade do País ou do exterior</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">.</span></font></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XV</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>declarações
e garantias</b></font></font></span></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><b>15.1.</b></font><font face="Supreme, serif">	</font><font face="Supreme, serif"><span lang="pt-BR">O</span></font><font face="Supreme, serif">s
Sócios declaram uns aos outros e à Sociedade que não </font><font face="Supreme, serif"><span lang="pt-BR">existem</span></font><font face="Supreme, serif">
ações, protestos, reclamações, investigações, litígios,
arbitragens, processos e/ou procedimentos, judiciais</font><font face="Supreme, serif"><span lang="pt-BR">,
arbitrais</span></font><font face="Supreme, serif"> e/ou
administrativos, nem dívidas, débitos, obrigações e/ou passivos,
envolvendo-os que possam (i) prejudicar o cumprimento de suas
obrigações neste Contrato Social, ou (ii) afetar ou por em risco a
validade ou segurança deste Contrato Social</font><font face="Supreme, serif"><span lang="pt-BR">
ou da Sociedade</span></font><font face="Supreme, serif">.</font></font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XVI</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>confidencialidade
</b></font></font></span>
</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<ol start="16">
	<ol>
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">Exceto
		quando expressamente permitido por acordo escrito entre os Sócios,
		cada Sócio deverá manter em absoluto sigilo qualquer Informação
		Confidencial de um Sócio ou da Sociedade a que tiver acesso,
		obrigando-se a jamais utilizar a Informação Confidencial, nem por
		si, nem por interposta pessoa, a não ser nos estritos e precisos
		limites estabelecidos neste Contrato Social, obrigação esta que
		vigorará por tempo indeterminado e mesmo após o término da
		Sociedade.</font></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<ol start="16">
	<ol start="2">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">A
		obrigação de confidencialidade aqui pactuada se estende aos
		administradores, empregados ou prepostos dos Sócios, assumindo
		cada Sócio, perante os demais, responsabilidade integral por
		qualquer violação à referida obrigação de confidencialidade.</font></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<ol start="16">
	<ol start="3">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">O</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">s
		Sócios obrigam-se a não divulgar a existência ou os termos deste
		Contrato Social, sem o consentimento prévio, por escrito, dos
		demais</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
		Sócios</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">,
		exceto nas situações </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">em
		que</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
		tal divulgação decorra de dever legal ou ordem judicial.</font></font></font></font></p></li>
	</ol>
</ol>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XVII</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>dissolução
da sociedade </b></font></font></span>
</p>
<p lang="x-none" align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<ol start="17">
	<ol>
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">Dissolve-se
		a Sociedade:</font></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
 <ol type="i">
	<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">pela
	vontade </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">do
	Sócio Ostensivo</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">;</font></font></font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
 <ol type="i" start="2">
	<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">pelo
	término do prazo de duração da Sociedade;</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
	ou</span></font></font></font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
 <ol type="i" start="3">
	<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">em
	caso de falência ou extinção </font></font><font face="Supreme, serif">(exceto
	por incorporação ou outra operação societária)</font><font color="#FFFFFF"><font face="Supreme, serif">
	do Sócio Ostensivo.</font></font></font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<ol start="17">
	<ol start="2">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">A
		dissolução da Sociedade</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
		</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">será
		realizada mediante levantamento </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">de</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
		balanço patrimonial especial e apuração de eventuais haveres dos
		Sócios</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">.</span></font></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<ol start="17">
	<ol start="3">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">A
		data do balanço-base será a data do evento (</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">manifestação
		da vontade do Sócio Ostensivo, término do prazo de duração ou
		falência/extinção do Sócio Ostensivo</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">).
		</font></font></font></font>
		</p></li>
	</ol>
</ol>
<p lang="x-none" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<ol start="17">
	<ol start="4">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Os
		eventuais haveres serão partilhados entre os Sócios na </span></font></font><font color="#FFFFFF"><font face="Supreme, serif">proporção
		de seus Direitos Econômicos na Sociedade</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
		e a partilha deverá observar as regras definidas no Acordo de
		Sócios.</span></font></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<ol start="17">
	<ol start="5">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Na
		hipótese de morte, </span></font></font><font face="Supreme, serif"><span lang="pt-BR">separação,
		divórcio ou término, a qualquer título, de casamento, união
		estável ou relacionamento</span></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
		de Sócio Participante, seus</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
		herdeiros, sucessores</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">,
		</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">legatários</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">,
		</span></font></font><font face="Supreme, serif"><span lang="pt-BR">cônjuge,
		meeiro e/ou companheiro, conforme aplicável, </span></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">ingressarão
		na Sociedade, na qualidade de Sócios Participantes, recebendo o
		quinhão da participação societária do Sócio Participante que
		lhes for assegurado em decisão judicial transitada em julgado ou
		em escritura pública de inventário ou divórcio/dissolução de
		união estável/relacionamento, e deverão aderir</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">,
		em caráter irrevogável e incondicional, a todos os termos e
		condições </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">deste
		Contrato Social e do Acordo de Sócios, conforme alterados</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">,
		sem ressalvas.</font></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<ol start="17">
	<ol>
		<ol>
			<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
			<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">Caso
			os herdeiros, sucessores</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">,
			</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">legatários</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">,
			</span></font></font><font face="Supreme, serif"><span lang="pt-BR">cônjuge,
			meeiro e/ou companheiro </span></font><font color="#FFFFFF"><font face="Supreme, serif">do
			Sócio Participante </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">não
			ingressem, por qualquer motivo, </span></font></font><font color="#FFFFFF"><font face="Supreme, serif">na
			Sociedade, </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">os</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
			haveres</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
			da Sociedade</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
			</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">a
			que porventura tiverem direito serão</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
			apurados</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
			e pagos na forma do Acordo de Sócios.</span></font></font></font></font></p></li>
		</ol>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<ol start="17">
	<ol start="6">
		<li><p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">Nenhum
		ativo corpóreo ou intangível, fundo de comércio, marca,
		software, contrato, cliente, rentabilidade futura e/ou projeção
		de rentabilidade </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">deverá
		ser computado</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
		no </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">cálculo
		</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">d</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">e
		</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">haveres.
		</font></font></font></font>
		</p></li>
	</ol>
</ol>
<p align="justify" style="line-height: 100%; margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XVIII</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>disposições
gerais </b></font></font></span>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><b>18.1.	</b></font></font><font color="#FFFFFF"><font face="Supreme, serif">Este
Contrato Social é celebrado em caráter irrevogável e irretratável,
vedado o direito de arrependimento, obrigando as Sócios, seus
herdeiros, legatários, cônjuges, companheiros, sucessores e/ou
cessionários a qualquer título.</font></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><b>18.2.</b></font></font><font color="#FFFFFF"><font face="Supreme, serif">	Caso
qualquer </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">disposição</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
deste Contrato Social seja considerada inválida, ilegal ou
inexequível em qualquer aspecto, a validade, legalidade ou
exequibilidade de suas outras disposições não serão afetadas ou
prejudicadas. Os Sócios deve</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">rão</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
negociar, de boa-fé, a reposição das disposições inválidas,
ilegais ou inexequíveis por disposições válidas e de efeitos que
sejam os mais próximos possíveis dos efeitos das disposições
inválidas, ilegais ou inexequíveis.</font></font></font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><b>18.3.</b></font></font><font color="#FFFFFF"><font face="Supreme, serif">	Os
termos, cláusulas e condições deste Contrato Social foram
ajustados pelos Sócios de mútuo e comum acordo, tendo os Sócios
tido acesso, estudado, analisado, avaliado e levado em consideração
todas as informações e documentos necessários, e sido assessorados
por profissionais</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
especializados e</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
competentes.</font></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>18.4.</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">	</span></font></font><font face="Supreme, serif">Todas
</font><font face="Supreme, serif"><span lang="pt-BR">as
comunicações, convocações e </span></font><font face="Supreme, serif">notificações
(“</font><font face="Supreme, serif"><span lang="pt-BR"><u>Notificações</u></span></font><font face="Supreme, serif">”)
</font><font face="Supreme, serif"><span lang="pt-BR">relativas à
Sociedade</span></font><font face="Supreme, serif"> serão efetuadas
por e-mail, com comprovante de entrega, </font><font face="Supreme, serif"><span lang="pt-BR">enviadas
a</span></font><font face="Supreme, serif">os endereços constantes
do preâmbulo deste</font><font face="Supreme, serif"><span lang="pt-BR">
Contrato Social e dos respectivos Termo de Adesão e Termo de Cessão.
</span></font><font face="Supreme, serif">As </font><font face="Supreme, serif"><span lang="pt-BR">Notificações</span></font><font face="Supreme, serif">
serão consideradas realizadas no momento do recebimento do
comprovante de entrega pelo remetente. Qualquer </font><font face="Supreme, serif"><span lang="pt-BR">Sócio</span></font><font face="Supreme, serif">
poderá mudar o endereço para o qual </font><font face="Supreme, serif"><span lang="pt-BR">a
Notificação</span></font><font face="Supreme, serif"> deverá ser
enviada, mediante </font><font face="Supreme, serif"><span lang="pt-BR">Notificação
ao Sócio Ostensivo</span></font><font face="Supreme, serif">, nos
termos deste item</font><font face="Supreme, serif"><span lang="pt-BR">
18.4</span></font><font face="Supreme, serif">.</font></font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XIX</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>solução
de controvérsias </b></font></font></span>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><a name="_Hlk535509779"></a>
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>19.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Quaisquer
controvérsias que resultem ou se refiram à Sociedade deverão ser
submetidas à </font></font><span style="display: inline-block; border: none; padding: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span style="text-decoration: none"><font color="#FFFFFF">arbitragem,
nos termos da Lei nº 9.307/96, na </span></span></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">CAMARB
– Câmara de Mediação e Arbitragem Empresarial – Brasil</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
de acordo com o seu regulamento (“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Regulamento</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”)
em vigor na data do pedido de instauração da arbitragem. </font></font>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>19.2.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">A
arbitragem deverá ser conduzida no idioma português.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>19.3.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">A
sede da arbitragem será no município de Belo Horizonte, estado de
Minas Gerais, local onde será proferida a sentença arbitral, sendo
vedado ao árbitro julgar por equidade. </font></font>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>19.4.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">A
arbitragem será conduzida por árbitro único, nomeado nos termos do
Regulamento.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>19.5.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	A
sentença arbitral será definitiva e vinculante para todos os Sócios
e a Sociedade, independentemente de eventual recusa, por quaisquer
deles, de participar do procedimento arbitral, seja como parte ou
terceiro interessado, </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
estando sujeita a recurso ou ratificação judicial.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>19.6.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Os
Sócios poderão recorrer ao Poder Judiciário nos casos
estabelecidos na Lei n° 9.307/1996, sem que tal conduta seja
considerada como ato de renúncia à arbitragem como único meio de
solução de controvérsias, a saber: (i) assegurar a instituição
da arbitragem, (ii) obter medidas urgentes (cautelares e
antecipatórias) de proteção de direitos previamente à
constituição do Tribunal Arbitral, (iii) obter a execução
específica de obrigações, e (iv) executar qualquer decisão do
Tribunal Arbitral. Para tanto, fica eleito o Foro da comarca de Belo
Horizonte, estado de Minas Gerais.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in"><a name="_Hlk535509746"></a>
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XX</b></font></font></span></p>
<p className="western" align="center" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Assinatura
Eletrônica</b></font></font></span></p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>20.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Os
Sócios envolvidos neste Contrato afirmam e declaram que o presente
documento poderá ser assinado por meio eletrônico, sendo
consideradas válidas as referidas assinaturas. Os Sócios também
declaram reconhecerem como válidas as assinaturas eletrônicas
feitas através da plataforma “Clicksign” (www.clicksign.com.br),
quando enviadas para os endereços de e-mail citados nas suas
qualificações do presente instrumento, e reconhecem válidas as
assinaturas enviadas para o(s) endereço(s) do(s) seu(s)
representante(s), todos para fins deste contrato, nos termos do art.
10 parágrafo 2º da MP 2200-2/2001.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"> 
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">E,
por estarem justos e contratados, os Sócios assinam este Contrato na
presença de 02 (duas) testemunhas.</font></font></p>
<p className="western" align="right" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="right" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">São
Paulo, SP, ${formatarData()}.</font></font></p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in; page-break-before: always">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>ANEXO
A.I</b></font></font></p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
I</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>definições</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<ol>
	<ol>
		<li><p lang="x-none" align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">As
		palavras, expressões e abreviações com as letras iniciais
		maiúsculas, não definidas em outras </font><font face="Supreme, serif"><span lang="pt-BR">partes</span></font><font face="Supreme, serif">
		</font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">no</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
		Contrato Social e no Acordo de Sócios</font></font><font face="Supreme, serif">,
		no singular ou no plural, terão o significado atribuído a elas</font><font face="Supreme, serif"><span lang="pt-BR">
		neste </span></font><font face="Supreme, serif"><span lang="pt-BR"><u>Anexo
		A.I</u></span></font><font face="Supreme, serif">, exceto se
		expressamente indicado de outra forma ou se o contexto for
		incompatível com qualquer significado aqui indicado</font><font face="Supreme, serif"><span lang="pt-BR">.</span></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<ol>
	<ol start="2">
		<li><p lang="x-none" align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Sempre
		que mencionados n</font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span lang="pt-BR">o
		</span></font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif">Contrato
		e no Acordo de Sócios</font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
		os termos abaixo terão os seguintes significados:</font></font></font></font></font></font></p></li>
	</ol>
</ol>
<p align="justify" style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
 <ol type="i">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Acordo
	de Sócios</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa o acordo de sócios a ser celebrado entre os Sócios, pelo
	qual regularão seus direitos e obrigações como Sócios da
	Sociedade;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Capital
	Investido</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">os
	recursos monetários aportados pelo Novo Sócio Participante
	diretamente nos Fundos Sociais da Sociedade;</font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="3">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Capital
	Investido Corrigido</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">significa
	o Capital Investido, corrigido monetariamente pelo IPCA, desde a
	data dos aportes dinheiro, conforme aplicável, até a realização
	de eventuais Retornos do Investimento, sendo certo que, para os fins
	desta atualização monetária, o Capital Investido sofrerá
	reduções na medida em que ocorrer cada evento de Retorno do
	Investimento</font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="4">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Contrato
	Social</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa o contrato de constituição da Sociedade, regulado </font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">pelos
	artigos 991 a 996 do Código Civil Brasileiro e pelos artigos 160 e
	161 do Decreto n</font></font></font><font color="#FFFFFF"><sup><font face="Supreme, serif"><font size="2" style="font-size: 11pt">o
	</font></font></sup></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">9.580/2018
	(“</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Regulamento
	do Imposto de Renda</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”)
	e demais normas aplicáveis; </font></font></font></font>
	</p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="5">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Direitos
	Econômicos</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa os direitos econômicos (fundos sociais e lucros, por
	exemplo) que caberão a cada Sócio nos termos do Contrato Social e
	do Acordo de Sócios, os quais poderão variar de tempos e tempos em
	razão do ingresso de Novos Sócios Participantes;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="6">
	<li><p align="justify" style="margin-bottom: 0in"> <font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Direitos
	Políticos</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa o direito de voto e demais direitos correlatos de cada
	Sócio, na proporção definida </font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="7">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Evento
	de Liquidez em Dinheiro</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	</font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">tem
	o significado que lhe é atribuído </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="8">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Evento
	de Liquidez in Natura</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	</font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">tem
	o significado que lhe é atribuído </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="9">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Fundos
	Sociais</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é atribuído </font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios</font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="10">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Investimento
	Qualificado</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	entende-se por investimento qualificado os aportes de capital
	realizados em qualquer </font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startup</i></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	em valor igual ou superior a R$ 50.000.000,00 (cinquenta milhões de
	reais);</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="11">
	<li><p align="justify" style="margin-bottom: 0in"><u> <font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Novos
	Sócios Participantes</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significam investidores que sejam admitidos na Sociedade, após a
	sua constituição, na qualidade de Sócios Participantes;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="12">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Notificações</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é </font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">atribuído
	no Contrato Social;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="13">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Partes
	Relacionadas</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:</font></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	significam as seguintes pessoas relacionadas a determinado Sócio
	(conforme aplicável): </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>(a)</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	seus acionistas, sócios, coligadas, controladas e/ou controladoras;
	ou </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>(b)</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	os cônjuges, companheiros, ascendentes, descendentes e seus
	respectivos cônjuges e/ou companheiros das pessoas indicadas no
	item “(a)” desta definição;</font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="14">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Regulamento</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é atribuído </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></font></p></li>
</ol>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="15">
	<li><p align="justify" style="margin-bottom: 0in"><font color="#FFFFFF">
	</font><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Retorno
	do Investimento</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é atribuído no Contrato Social e no
	Acordo de Sócios;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="16">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Retorno
	do Investimento em Dinheiro</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é atribuído no Contrato Social e no
	Acordo de Sócios;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="17">
	<li><p align="justify" style="margin-bottom: 0in"><font color="#FFFFFF">
	</font><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Retorno
	do Investimento in Natura</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é atribuído no Contrato Social e no
	Acordo de Sócios;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="18">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
	Ostensivo</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa a POOL BOSSA NOVA INVESTIMENTOS LTDA., qualificada no
	Contrato Social</font></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="19">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócios
	Participantes</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significam as pessoas, físicas ou jurídicas, que participam ou
	venham a participar da Sociedade, na qualidade de Sócios
	Participantes, inclusive os Novos Sócios Participantes e o Sócio
	Participante Principal;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="20">
	<li><p align="justify" style="margin-bottom: 0in"> <font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
	Participante Principal</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa a BOSSA NOVA INVESTIMENTOS E ADMINISTRAÇÃO S.A.,
	qualificada no Contrato Social;</font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>
	</u></font></font></font></font></font>
	</p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="21">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sociedade</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa esta sociedade em conta de participação, denominada POOL
	BOSSA NOVA INVESTIMENTOS LTDA. SCP MEMBERSHIP; e</font></font></font></p></li>
</ol>
<p lang="x-none" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="22">
	<li><p align="justify" style="margin-bottom: 0in"> <font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i><u>Startups</u></i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>:</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	tem o significado que lhe é atribuído </font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></font></p></li>
</ol>
<p lang="x-none" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in; page-break-before: always">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u><b>ANEXO
B</b></u></font></font></p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>ACORDO
DE SÓCIOS DE SOCIEDADE EM CONTA DE PARTICIPAÇÃO</b></font></font></p>
<p className="western" align="center" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>POOL
BOSSA NOVA INVESTIMENTOS LTDA. SCP MEMBERSHIP</b></font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Pelo
presente instrumento particular,</font></font></p>
<p className="western" align="justify" style="margin-left: 0.5in; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>(i)	POOL
BOSSA NOVA INVESTIMENTOS LTDA</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.,
sociedade empresária limitada inscrita no CNPJ sob o n°
25.453.024/0001-27, com sede e foro na Rua Joaquim Floriano, nº 466,
Edifício Brascan Century Corporate, 9º andar, bairro Itaim Bibi, em
São Paulo, SP, CEP: 04.534-002, com endereço de e-mail:
</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>representante.legal@bossainvest.com</u></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
neste ato representada nos termos de seu Contrato Social</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
doravante denominado</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
como “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
Ostensivo</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”;
e </font></font>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>(ii)	</b></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>BOSSA
NOVA INVESTIMENTOS E ADMINISTRAÇÃO S.A.</b></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">sociedade
anônima fechada inscrita no CNPJ sob o nº 13.568.149/0001-13, com
sede e foro na Rua Joaquim Floriano, nº 466, Brascan Century
Corporate, 9º andar, bairro Itaim Bibi, em São Paulo, SP, CEP:
04.534-002, com endereço de e-mail:
</font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>representante.legal@bossainvest.com</u></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
neste ato representada nos termos de seu Estatuto Social, doravante
denominado como </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
Participante Principal</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in">“<font face="Supreme, serif"><font size="2" style="font-size: 11pt">Sócio
Ostensivo” e “Sócio Participante Principal” doravante
denominados, em conjunto, “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócios</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”
e, isoladamente, “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">E,
na qualidade de interveniente anuente,</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>(iii)</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>POOL
BOSSA NOVA INVESTIMENTOS LTDA. SCP MEMBERSHIP</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
sociedade em conta de participação com sede e foro na </font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Rua
Tomé de Souza, nº 669, 9º andar, bairro Savassi, em Belo
Horizonte, MG, CEP: 30.140-131</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
com endereço de e-mail: </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>representante.legal@bossainvest.com</u></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
neste ato representada nos termos de seu Contrato Social por POOL
BOSSA NOVA INVESTIMENTOS LTDA. (“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
Ostensivo</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”),
doravante denominada “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sociedade
ou SCP</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”.</font></font></p>
<p className="western" align="justify" style="margin-left: 0.5in; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt">	</font></font></p>
<p lang="en-US" align="center" style="margin-bottom: 0in"><font face="Courier New, serif"><font size="2" style="font-size: 10pt"><font color="#0000ff"><span style="letter-spacing: normal"><u> <font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span lang="pt-BR"><u><b>Preâmbulo</b></u></span></font></font></font></span></u></span></font></font></font></p>
<p className="western" align="justify" style="font-variant: small-caps; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt">Considerando
que: </font></font></span>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">os
	Sócios desejam regular seus direitos e obrigações na Sociedade; </font></font>
	</p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">outras
	pessoas, físicas ou jurídicas, poderão ser admitidas na Sociedade
	na condição de sócios participantes (“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Novos
	Sócios Participantes</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”);
	e</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>(iii)	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">os
Novos Sócios Participantes, quando ingressarem na Sociedade,
aderirão ao Contrato Social e ao Acordo de Sócios da Sociedade,
conforme alterados (“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Contrato
Social</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”
e “</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Acordo
de Sócios</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”,
respectivamente).</font></font></p>
<p className="western" align="justify" style="font-variant: small-caps; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt">Resolvem</font></font></span><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
os Sócios </font></font><font color="#0000ff"><span style="letter-spacing: normal"><u><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span style="text-decoration: none">celebrar
este Acordo de Sócios para regular seus direitos e obrigações como
sócios da Sociedade denominada</span></font></font></font></u></span></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">POOL
BOSSA NOVA INVESTIMENTOS LTDA. SCP MEMBERSHIP</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,</font></font><font color="#0000ff"><span style="letter-spacing: normal"><u><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span style="text-decoration: none">
conforme os termos e condições aqui previstos</span></font></font></font></u></span></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
</font></font>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
I</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>definições
e premissas</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<ol>
	<ol>
		<li><p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
		<font face="Supreme, serif"><font size="2" style="font-size: 11pt">As
		palavras, expressões e abreviações com as letras iniciais
		maiúsculas, não definidas em outras partes deste Acordo de
		Sócios, no singular ou no plural, terão o significado atribuído
		a elas no </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Anexo
		B.I</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<ol>
	<ol start="2">
		<li><p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
		<font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
		objeto social da Sociedade será a prospecção de oportunidades de
		negócios em Startups para fins de investimento direto e/ou
		indireto, por meio da celebração de títulos privados
		conversíveis em participação societária e/ou outros
		instrumentos, conforme oportunidade e necessidade (“Special
		Deals”).</font></font></p></li>
	</ol>
</ol>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
II</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>direitos
econômicos e direitos políticos</b></font></font></span></p>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol>
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Os
		Direitos Econômicos na Sociedade consistirão na somatória dos
		Direitos Econômicos mensais (“DEM”), decorrentes da proporção
		entre as quantias integralizadas pelos Sócios junto aos Fundos
		Sociais mensalmente e o montante total aportado pelo Sócio
		Ostensivo em determinado(s) </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Special
		Deal(s) </i></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
		mesmo mês e ano (“Aporte Carimbado”). Nesse sentido, o
		percentual dos Direitos Econômicos de cada Sócio na Sociedade
		decorrerá da somatório dos percentuais por eles detidos para cada
		DEM.</font></font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol>
		<ol>
			<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O
			percentual dos DEM de cada Sócio será calculado mensalmente, com
			base na quantia que tiverem integralizado em determinado mês e
			ano sobre o total aportado pelo Sócio Ostensivo no(s) </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Special
			Deal(s)</i></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
			daquele referido mês e ano. Deve-se, portanto, observar a
			seguinte fórmula:</font></font></font></p></li>
		</ol>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">DEM
= QIDM / ACM</font></font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">sendo
que:</font></font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">DEM
significa o percentual dos Direitos Econômicos mensais detido por
cada Sócio;</font></font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">QIDM
significa a quantia integralizada pelo Sócio junto aos Fundos
Sociais em determinado mês e ano; e</font></font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">ACM
significa o Aporte Carimbado realizado no mesmo mês e ano da
realização do QIDM pelo Sócio.</font></font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<ol>
		<ol start="2">
			<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
			<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><span lang="pt-BR">O
			Sócio Ostensivo comunicará aos Sócios, em até 30 (trinta) dias
			contados da realização de cada Aporte Carimbado, as seguintes
			informações:</span></font></font></font></p></li>
		</ol>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><span lang="pt-BR">o
	percentual dos DEM detido por cada Sócio sobre o respectivo Aporte
	Carimbado cujo </span></font><font color="#FFFFFF"><font face="Supreme, serif">QIDM
	tiver integrado; e</font></font></font></font></p></li>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="2">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">a
	qual(is) </font></font><font color="#FFFFFF"><font face="Supreme, serif"><i>Special
	Deal(s) </i></font></font><font color="#FFFFFF"><font face="Supreme, serif">o
	referido Aporte Carimbado se destinou.</font></font></font></font></p></li>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol start="2">
	<ol>
		<ol start="3">
			<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
			<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><span lang="pt-BR">Caso
			o Sócio Ostensivo não consiga realizar, em determinado mês e
			ano, </span></font><font color="#FFFFFF"><font face="Supreme, serif">o
			Aporte Carimbado</font></font><font color="#FFFFFF"><font face="Supreme, serif"><i>,
			</i></font></font><font color="#FFFFFF"><font face="Supreme, serif">deverá
			realizado no(s) mês(es) necessariamente subsequente(s) em que for
			possível fazê-lo, observando as seguintes regras:</font></font></font></font></p></li>
		</ol>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><span lang="pt-BR">o
	Sócio Ostensivo segregará, gerencialmente, os QIDMs conforme
	mês/ano dos referidos aportes; e</span></font></font></font></p></li>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="2">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><span lang="pt-BR">os
	QIDMs que tiverem sido aportados primeiramente terão preferência
	para a realização dos Aportes Carimbados, devendo o Sócio
	Ostensivo sempre observar o princípio da anterioridade.</span></font></font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol start="2">
	<ol>
		<ol start="4">
			<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
			<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">Eventualmente,
			por questões operacionais, os </font><font face="Supreme, serif"><i>Special
			Deals </i></font><font face="Supreme, serif">poderão devolver
			parcela (ou a totalidade) dos recursos aportados pelo Sócio
			Ostensivo (“Devolução”).</font></font></font></p></li>
		</ol>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol start="2">
	<ol>
		<ol>
			<ol>
				<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
				<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">Ocorrendo
				qualquer Devolução, os recursos restituídos terão preferência
				sobre os </font><font face="Supreme, serif"><span lang="pt-BR">QIDMs
				pendentes de realização de Aporte Carimbado, prorrogando,
				proporcionalmente, o mês/ano dos respectivos Aportes Carimbados.</span></font></font></font></p></li>
			</ol>
		</ol>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol start="2">
	<ol>
		<ol start="5">
			<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
			<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><span lang="pt-BR">Os
			</span></font><font face="Supreme, serif">eventuais haveres
			cabíveis aos Novos Sócios Participantes, nos termos da Cláusula
			IX, deverão observar o disposto na Cláusula V.</font></font></font></p></li>
		</ol>
		<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Os
		Direitos Políticos dos Sócios na Sociedade são os seguintes:</font></font></font></p></li>
	</ol>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<center>
	<table width="265" cellpadding="7" cellspacing="0">
		<col width="150"/>
		<col width="84"/>
		<tr>
			<td colspan="2" width="249" valign="top" bgcolor="#d0cece" style="background: #d0cece; border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center">
				 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>direitos
				políticos</b></font></font></span></p>
			</td>
		</tr>
		<tr valign="top">
			<td width="150" bgcolor="#d0cece" style="background: #d0cece; border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center">
				 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>nome</b></font></font></span></p>
			</td>
			<td width="84" bgcolor="#d0cece" style="background: #d0cece; border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center">
				 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>total</b></font></font></span></p>
			</td>
		</tr>
		<tr valign="top">
			<td width="150" bgcolor="#d0cece" style="background: #d0cece; border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center">
				 <font face="Supreme, serif"><font size="2" style="font-size: 11pt">Sócio
				Ostensivo</font></font></span></p>
			</td>
			<td width="84" style="background: transparent; border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center">
				<font face="Supreme, serif"><font size="2" style="font-size: 11pt">100%</font></font></p>
			</td>
		</tr>
		<tr valign="top">
			<td width="150" bgcolor="#d0cece" style="background: #d0cece; border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center">
				 <font face="Supreme, serif"><font size="2" style="font-size: 11pt">Sócios
				participantes</font></font></span></p>
			</td>
			<td width="84" style="background: transparent; border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center">
				<font face="Supreme, serif"><font size="2" style="font-size: 11pt">Não
				terão Direitos Políticos </font></font>
				</p>
			</td>
		</tr>
		<tr valign="top">
			<td width="150" bgcolor="#d0cece" style="background: #d0cece; border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center">
				 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>total</b></font></font></span></p>
			</td>
			<td width="84" style="background: transparent; border: 1px solid #FFFFFF; padding: 0in 0.08in"><p className="western" align="center">
				<font face="Supreme, serif"><font size="2" style="font-size: 11pt">100%</font></font></p>
			</td>
		</tr>
	</table>
</center>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
III</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Fundos
Sociais</b></font></font></span></p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>3.1.
    </b></font></font></span><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Os
Fundos Sociais da Sociedade, sua subscrição e forma de
integralização, são aqueles previstos no item 3.1 do Contrato
Social.</font></font></font></p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>3.2.	</b></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Da
quantia efetivamente integralizada dos Fundos Sociais, serão
destinados até 25% (vinte e cinco por cento) para cobrir os custos
operacionais e demais despesas de rotina da operação, a critério
exclusivo do Sócio Ostensivo.</font></font></p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>3.3.	</b></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">As
respectivas quantias integralizadas pelos Novos Sócios Participantes
serão aplicadas, respeitado os descontos eventualmente efetuados do
item 3.2, em veículos financeiros rentáveis, até o momento em que
sejam iniciados os investimentos.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
<font color="#FFFFFF">       </font> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
IV</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>aporte
de recursos e ingresso de novos sócios</b></font></font></span></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>4.1.</b></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Em
virtude da necessidade de recursos financeiros para a consecução do
objeto social, caso o Sócio Ostensivo prospecte novas oportunidades
de negócios em </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
o Sócio Ostensivo poderá, a seu exclusivo critério, admitir novos
sócios na Sociedade, mediante </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>(i)</i></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
o aporte de recursos financeiros na Sociedade</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
e assinatura de Termo de Adesão </font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">ou
</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>(ii)</i></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
crédito utilizado pelo Novo Sócio Participante </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">e
assinatura do Termo de Cessão.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>4.2.	</b></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">O</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
ingresso de Novo Sócio Participante na Sociedade independerá da
concordância ou ciência dos Sócios Participantes.</font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
V</b></font></font></span></p>
<p lang="x-none" align="center" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"> <font face="Supreme, serif"><span lang="pt-BR"><b>retorno
dos fundos sociais e distribuição de lucros</b></span></font></span></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>5.1.</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">	</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">Os</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
eventuais lucros auferidos pela Sociedade que não forem compensados
com prejuízos e os fundos sociais remanescentes da Sociedade, que
não houverem sido utilizados para custear despesas da Sociedade,
serão distribuídos e restituídos aos Sócios, conforme aplicável,
na proporção de seus Direitos Econômicos na Sociedade, da seguinte
forma (“</span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><u>Retorno
do Investimento</u></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">”):</span></font></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">até
	que a soma dos valores dos Retornos do Investimento (</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">corrigidos
	monetariamente pelo IPCA)</font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	seja inferior ao Capital Investido Corrigido, os Retornos do
	Investimento serão pagos aos Novos Sócios Participantes;</font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">após
	a soma dos valores dos Retornos do Investimento</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	(corrigidos monetariamente pelo IPCA)</font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	ultrapassar o Capital Investido Corrigido, os Sócios receberão os
	Retornos do Investimento da seguinte forma: </font></font></font>
	</p></li>
</ol>
<p className="western" align="justify" style="margin-left: 1in; margin-bottom: 0in">
<br/>

</p>
<ol type="a">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">os
	Novos Sócios Participantes receberão os Retornos do Investimento,
	na proporção de seus Direitos Econômicos na Sociedade, reduzidos
	em 20% (vinte por cento);</font></font></font></font></p></li>
</ol>
<p lang="x-none" align="justify" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="2">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">o
	Sócio Ostensivo receberá os Retornos do Investimento, na proporção
	de seus Direitos Econômicos na Sociedade; e</font></font></font></font></p></li>
</ol>
<p lang="x-none" style="line-height: 115%; margin-left: 0.39in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<ol type="a" start="3">
	<li><p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
	<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif">o
	Sócio Participante Principal receberá os Retornos do Investimento,
	na proporção de seus Direitos Econômicos na Sociedade, acrescidos
	dos 20% (vinte por cento) que foram deduzidos dos Novos Sócios
	Participantes.</font></font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 115%; margin-bottom: 0.14in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>5.2.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Apenas
os Sócios cujos QIDMs tiverem integrado o Aporte Carimbado para a
realização do investimento no </span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><i>Special
Deal </i></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">que
estiver proporcionando o Retorno do Investimento farão jus ao
referido Retorno do Investimento, na proporção de seus Direitos
Econômicos.</span></font></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>5.3.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Na
hipótese de ocorrer um evento de liquidez nas </span></font></font><font face="Supreme, serif"><i>Startups</i></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
que resulte em efetivo pagamento de juros, distribuição de lucros
ou restituição de capital, em dinheiro, à Sociedade (“</span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><u>Evento
de Liquidez em Dinheiro</u></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">”),
a Sociedade realizará os Retornos do Investimento em dinheiro
(“</span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><u>Retorno
do Investimento em Dinheiro</u></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">”)
em até 30 (trinta) dias da data em que a Sociedade efetivamente
receber das </span></font></font><font face="Supreme, serif"><i>Startups</i></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
os recursos monetários relativos ao Evento de Liquidez em Dinheiro.</span></font></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>5.4.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Um
Evento de Liquidez em Dinheiro poderá resultar, por exemplo, das
seguintes operações envolvendo as </span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><i>Startups</i></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">:</span></font></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">oferta
	pública primária ou secundária de ações das </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	em bolsa de valores;</font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">alienação
	do controle societário das </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="3">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">recebimento
	de Investimento Qualificado pelas </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="4">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">alienação
	de </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Títulos
	Privados Conversíveis—Sócio Ostensivo e </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	que pertençam à Sociedade; e</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="5">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">alienação
	de participações societárias em </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	que pertençam à Sociedade</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></p></li>
</ol>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>5.5.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Na
hipótese de ocorrer um evento de liquidez nas </span></font></font><font face="Supreme, serif"><i>Startups</i></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
que resulte em efetivo pagamento de juros, distribuição de lucros
ou restituição de capital, em bens ou créditos, à Sociedade
(“</span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><u>Evento
de Liquidez </u></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><i><u>in
Natura</u></i></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">”),
o Sócio Ostensivo notificará os demais Sócios para manifestarem,
no prazo de até 10 (dez) dias do recebimento de Notificação, seu
interesse em receber o Retorno do Investimento em bens ou créditos
(“</span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><u>Retorno
do Investimento </u></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><i><u>in
Natura</u></i></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">”),
detalhando as condições do Retorno do Investimento in Natura,
incluindo, mas não se limitando, o valor dos bens ou créditos e a
obrigação de os Sócios assinarem acordos de quotistas/acionistas
das </span></font></font><font face="Supreme, serif"><i>Startups</i></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
e vincularem-se a período de </span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><i>lock-up</i></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
e cláusula não-concorrência. </span></font></font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>5.6.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Caso
o Sócio não responda ou responda fora do prazo a Notificação de
que trata o item 5.5 ou se recuse a receber o Retorno do Investimento
</span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><i>in
Natura </i></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">na
forma como detalhado pelo Sócio Ostensivo, o Retorno do Investimento
</span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><i>in
Natura</i></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
não será realizado, sem prejuízo de a Sociedade receber os bens ou
créditos entregues pelas </span></font></font><font face="Supreme, serif"><i>Startups</i></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
e o Sócio permanecer na Sociedade.</span></font></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>5.7.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span style="f">Os
Direitos Econômicos resultantes de eventual Evento de Liquidez em
Dinheiro ou Evento de Liquidez </span></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i><span style="f">in
Natura</span></i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span style="f">
somente serão devidos aos Novos Sócios Participantes que tiverem
assinado os Termos de Adesão&nbsp;até a data (i) de divulgação do
respectivo fato relevante ao mercado ou (ii) da&nbsp;comunicação
interna do Sócio Ostensivo aos Sócios Participantes nesse sentido,
o que ocorrer primeiro.</span></font></font></p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>5.8.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span style="f">Os
Novos Sócios Participantes que firmarem Termos de Adesão e/ou
Termos de Cessão a partir do 8º (oitavo) mês de constituição
desta Sociedade terão seus Direitos Econômicos corrigidos positiva
ou negativamente pelo Sócio Ostensivo, conforme valorizações,
desvalorizações, apropriação temporal de custeio e eventuais
Eventos de Liquidez em Dinheiro ou Eventos de Liquidez </span></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i><span style="f">in
Natura</span></i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span style="f">.</span></font></font></p>
<p className="western" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
VI</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>prazo
de vigência </b></font></font></span>
</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>6.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	O
presente Acordo de Sócios entra em vigor nesta data e vigorará até
o prazo de vigência da Sociedade em Conta de Participação </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">POOL
BOSSA NOVA INVESTIMENTOS LTDA. SCP MEMBERSHIP</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
VII</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Obrigações
do Sócio Ostensivo</b></font></font></span></p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>7.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
  Além das obrigações previstas no item 6.1 do Contrato Social e
na legislação aplicável, também integram as obrigações do Sócio
Ostensivo:</font></font></p>
<p className="western" align="justify" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
 <ol type="i">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><a name="OLE_LINK25"></a><a name="OLE_LINK24"></a>
	<font face="Supreme, serif"><font size="2" style="font-size: 11pt">formatar
	o POOL (formato técnico, tese de investimento, apresentação
	comercial e documentos jurídicos) para a viabilização dos aportes
	dos Novos Sócios Participantes, bem como os investimentos em
	</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">realizar
	toda a operação de </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>back
	office</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	do processo de captação dos Novos Sócios Participantes;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="3">
	<li><p className="western" align="justify" style="margin-bottom: 0in">
	<font face="Supreme, serif"><font size="2" style="font-size: 11pt">apoiar
	o processo de seleção das </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	a serem investidas pela Sociedade;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="4">
	<li><p className="western" align="justify" style="margin-bottom: 0in">
	<font face="Supreme, serif"><font size="2" style="font-size: 11pt">liderar
	o processo de </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>back
	office</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	do investimento nas </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	selecionadas pela Sociedade;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="5">
	<li><p className="western" align="justify" style="margin-bottom: 0in">
	<font face="Supreme, serif"><font size="2" style="font-size: 11pt">disponibilizar
	o sistema da Rede Bossa de acompanhamento das </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	para os Novos Sócios Participantes;</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="6">
	<li><p className="western" align="justify" style="margin-bottom: 0in">
	<font face="Supreme, serif"><font size="2" style="font-size: 11pt">disponibilizar
	acesso à Rede Bossa para os fundadores das </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	investidas pela Sociedade; e</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="7">
	<li><p className="western" align="justify" style="margin-bottom: 0in"><a name="OLE_LINK26"></a>
	 <font face="Supreme, serif"><font size="2" style="font-size: 11pt">conduzir
	o processo de seleção das </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startups</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	investidas, definindo quais serão, bem como as condições de
	investimento.</font></font></p></li>
</ol>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
VIII</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>alteração
do acordo de sócios </b></font></font></span>
</p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>8.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Este
Acordo de Sócios poderá ser alterado pelos Sócios, a qualquer
tempo, de comum acordo, pela vontade de Sócios titulares de 100%
(cem por cento) dos Direitos Políticos.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>8.2.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Nenhuma
alteração deste Acordo de Sócios poderá ser realizada sem
expressa concordância do Sócio Ostensivo.</font></font></p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
IX</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>haveres
e remissão de dívida  </b></font></font></span>
</p>
<p lang="x-none" align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>9.1.</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">	Os
eventuais haveres que, em quaisquer das hipóteses previstas no
Contrato Social, neste Acordo de Sócios ou em lei, couberem aos
Sócios, seus </span></font></font><font color="#FFFFFF"><font face="Supreme, serif">herdeiros,
sucessores</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">,
</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">legatários</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">,
</span></font></font><font face="Supreme, serif"><span lang="pt-BR">cônjuge,
meeiro e/ou companheiro, conforme aplicável,</span></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
serão levantados, calculados, partilhados e pagos em estrita
conformidade com as regras previstas na Cláusula V deste Acordo de
Sócios, incluindo valores e prazos. </span></font></font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-left: 0.39in; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>9.1.1.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Nenhum
valor além dos Retornos do Investimento deverá ser pago aos Sócios,
seus </span></font></font><font color="#FFFFFF"><font face="Supreme, serif">herdeiros,
sucessores</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">,
</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">legatários</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">,
</span></font></font><font face="Supreme, serif"><span lang="pt-BR">cônjuge,
meeiro e/ou companheiro, conforme aplicável, a título de haveres. </span></font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-left: 0.39in; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-left: 0.39in; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif"><span lang="pt-BR"><b>9.1.2.	</b></span></font><font color="#FFFFFF"><font face="Supreme, serif">Os
eventuais haveres somente ser</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">ão
pagos quando ocorrerem Eventos de Liquidez em Dinheiro ou Eventos de
Liquidez </span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><i>in
Natura</i></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">,
observados os prazos, forma e demais condições previstas na
Cláusula V deste Acordo de Sócios.</span></font></font></font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>9.2.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Quando
da dissolução da Sociedade, os eventuais créditos e débitos do
Sócio Ostensivo e da Sociedade serão automaticamente compensados
até o limite dos valores dos créditos e débitos recíprocos.</span></font></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>9.3.</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">	Após
realizada a compensação, eventual saldo credor em favor da
Sociedade será automaticamente perdoado pela Sociedade, com o que o
Sócio Ostensivo e os Sócios Participantes, inclusive aqueles que
ingressarem na Sociedade após sua constituição, concordam desde
logo.</span></font></font></font></font></p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
X</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>ônus
sobre participações societárias</b></font></font></span></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>10.1.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Os
Sócios concordam que não poderão, em qualquer momento durante a
vigência deste Acordo de Sócios, direta ou indiretamente, criar,
incorrer, assumir ou permitir a existência de quaisquer ônus sobre
suas participações societárias na Sociedade, a menos que aprovado
por escrito pelo Sócio Ostensivo.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XI</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>prevalência
do acordo de sócios</b></font></font></span></p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="justify" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>11.</b></font></font></span><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>1.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Em
caso de conflito entre o Contrato Social e este Acordo de Sócios, o
disposto neste Acordo de Sócios deverá prevalecer. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XII</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>confidencialidade
</b></font></font></span>
</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>12.1.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif">Exceto
quando expressamente permitido por acordo escrito entre os Sócios,
cada Sócio deverá manter em absoluto sigilo qualquer Informação
Confidencial de um Sócio ou da Sociedade a que tiver acesso,
obrigando-se a jamais utilizar a Informação Confidencial, nem por
si, nem por interposta pessoa, a não ser nos estritos e precisos
limites estabelecidos neste</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
Acordo de Sócios</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">,
obrigação esta que vigorará por tempo indeterminado e mesmo após
o término da Sociedade.</font></font></font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>12.2.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif">A
obrigação de confidencialidade aqui pactuada se estende aos
administradores, empregados ou prepostos dos Sócios, assumindo cada
Sócio, perante os demais, responsabilidade integral por qualquer
violação à referida obrigação de confidencialidade.</font></font></font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>12.3.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">O</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">s
Sócios obrigam-se a não divulgar a existência ou os termos deste</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
Acordo de Sócios</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">,
sem o consentimento prévio, por escrito, dos demais</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
Sócios</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">,
exceto nas situações </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">em
que</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
tal divulgação decorra de dever legal ou ordem judicial.</font></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XIII</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>disposições
gerais </b></font></font></span>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>13.1.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif">Este</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
Acordo de Sócios</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
é celebrado em caráter irrevogável e irretratável, vedado o
direito de arrependimento, obrigando </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">os</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
Sócios, seus herdeiros, legatários, cônjuges, companheiros,
sucessores e/ou cessionários a qualquer título.</font></font></font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>13.2.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif">Quaisquer
alterações, aditamentos, cessões e/ou transferências deste </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Acordo
de Sócios</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
e/ou dos direitos e obrigações nele disciplinados, somente
produzirão efeitos e serão válidos e eficazes se forem efetuados
por escrito e firmados pelos Sócios.</font></font></font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>13.3.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif">Caso
qualquer </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">disposição</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
deste </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">Acordo
de Sócios</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
seja considerada inválida, ilegal ou inexequível em qualquer
aspecto, a validade, legalidade ou exequibilidade de suas outras
disposições não serão afetadas ou prejudicadas. Os Sócios
deve</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">rão</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
negociar, de boa-fé, a reposição das disposições inválidas,
ilegais ou inexequíveis por disposições válidas e de efeitos que
sejam os mais próximos possíveis dos efeitos das disposições
inválidas, ilegais ou inexequíveis.</font></font></font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>13.4.	</b></span></font></font><font color="#FFFFFF"><font face="Supreme, serif">Os
termos, cláusulas e condições deste</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
Acordo de Sócios</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
foram </font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">ajustados
</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">pelos
Sócios de mútuo e comum acordo, tendo os Sócios tido acesso,
estudado, analisado, avaliado e levado em consideração todas as
informações e documentos necessários, e sido assessorados por
profissionais</font></font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">
especializados e</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
competentes.</font></font></font></font></p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<br/>

</p>
<p lang="x-none" align="justify" style="line-height: 100%; margin-bottom: 0in">
<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR"><b>13.5.	</b></span></font></font><font face="Supreme, serif">Todas
</font><font face="Supreme, serif"><span lang="pt-BR">as
comunicações, convocações e </span></font><font face="Supreme, serif">notificações
(“</font><font face="Supreme, serif"><span lang="pt-BR"><u>Notificações</u></span></font><font face="Supreme, serif">”)
</font><font face="Supreme, serif"><span lang="pt-BR">relativas à
Sociedade</span></font><font face="Supreme, serif"> serão efetuadas
por e-mail, com comprovante de entrega, </font><font face="Supreme, serif"><span lang="pt-BR">enviadas
a</span></font><font face="Supreme, serif">os endereços constantes
do preâmbulo deste</font><font face="Supreme, serif"><span lang="pt-BR">
Acordo de Sócios e dos respectivos Termo de Adesão e Termo de
Cessão. </span></font><font face="Supreme, serif">As </font><font face="Supreme, serif"><span lang="pt-BR">Notificações</span></font><font face="Supreme, serif">
serão consideradas realizadas no momento do recebimento do
comprovante de entrega pelo remetente. Qualquer </font><font face="Supreme, serif"><span lang="pt-BR">Sócio</span></font><font face="Supreme, serif">
poderá mudar o endereço para o qual </font><font face="Supreme, serif"><span lang="pt-BR">a
Notificação</span></font><font face="Supreme, serif"> deverá ser
enviada, mediante </font><font face="Supreme, serif"><span lang="pt-BR">Notificação
ao Sócio Ostensivo</span></font><font face="Supreme, serif">, nos
termos deste item</font><font face="Supreme, serif"><span lang="pt-BR">
13.5</span></font><font face="Supreme, serif">.</font></font></font></p>
<p className="western" align="center" style="font-variant: small-caps; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XIV</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>solução
de controvérsias </b></font></font></span>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>14.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Quaisquer
controvérsias que resultem ou se refiram à Sociedade deverão ser
submetidas à </font></font><span style="display: inline-block; border: none; padding: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span style="text-decoration: none"><font color="#FFFFFF">arbitragem,
nos termos da Lei nº 9.307/96, na </span></span></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">CAMARB
– Câmara de Mediação e Arbitragem Empresarial – Brasil</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
de acordo com o seu regulamento (“</font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Regulamento</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”)
em vigor na data do pedido de instauração da arbitragem. </font></font>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>14.2.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">A
arbitragem deverá ser conduzida no idioma português.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>14.3.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">A
sede da arbitragem será no município de Belo Horizonte, estado de
Minas Gerais, local onde será proferida a sentença arbitral, sendo
vedado ao árbitro julgar por equidade. </font></font>
</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>14.4.	</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">A
arbitragem será conduzida por árbitro único, nomeado nos termos do
Regulamento.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>14.5.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	A
sentença arbitral será definitiva e vinculante para todos os Sócios
e a Sociedade, independentemente de eventual recusa, por quaisquer
deles, de participar do procedimento arbitral, seja como parte ou
terceiro interessado, </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">não
estando sujeita a recurso ou ratificação judicial.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>14.6.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Os
Sócios poderão recorrer ao Poder Judiciário nos casos
estabelecidos na Lei n° 9.307/1996, sem que tal conduta seja
considerada como ato de renúncia à arbitragem como único meio de
solução de controvérsias, a saber: </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>(i)</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
assegurar a instituição da arbitragem, </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>(ii)</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
obter medidas urgentes (cautelares e antecipatórias) de proteção
de direitos previamente à constituição do Tribunal Arbitral, </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>(iii)</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
obter a execução específica de obrigações, e </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>(iv)</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
executar qualquer decisão do Tribunal Arbitral. Para tanto, fica
eleito o Foro da comarca de Belo Horizonte, estado de Minas Gerais.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
XV</b></font></font></span></p>
<p className="western" align="center" style="margin-bottom: 0in"> <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Assinatura
Eletrônica</b></font></font></span></p>
<p className="western" align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>15.1.</b></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">	Os
Sócios envolvidos neste instrumento afirmam e declaram que o
presente documento poderá ser assinado por meio eletrônico, sendo
consideradas válidas as referidas assinaturas. Os Sócios também
declaram reconhecerem como válidas as assinaturas eletrônicas
feitas através da plataforma “Clicksign” (www.clicksign.com.br),
quando enviadas para os endereços de e-mail citados nas suas
qualificações do presente instrumento, e reconhecem válidas as
assinaturas enviadas para o(s) endereço(s) do(s) seu(s)
representante(s), todos para fins deste contrato, nos termos do art.
10 parágrafo 2º da MP2200-2/2001.</font></font></p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">E,
por estarem justos e contratados, os Sócios assinam este Contrato na
presença de 02 (duas) testemunhas.</font></font></p>
<p className="western" align="right" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="right" style="margin-bottom: 0in"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">São
Paulo, SP, ${formatarData()}.</font></font></p>
<p className="western" style="margin-bottom: 0in"><br/>

</p>
<p className="western" style="margin-bottom: 0in"><br/>

</p>
<p align="center" style="margin-bottom: 0in; page-break-before: always">
<font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u><b>ANEXO
B.I</b></u></font></font></font></p>
<p align="center" style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>Cláusula
I</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
 <font face="Supreme, serif"><font size="2" style="font-size: 11pt"><b>definições</b></font></font></span></p>
<p className="western" align="center" style="orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<ol>
	<ol>
		<li><p lang="x-none" align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Supreme, serif">As
		palavras, expressões e abreviações com as letras iniciais
		maiúsculas, não definidas em outras </font><font face="Supreme, serif"><span lang="pt-BR">partes</span></font><font face="Supreme, serif">
		</font><font color="#FFFFFF"><font face="Supreme, serif"><span lang="pt-BR">no</span></font></font><font color="#FFFFFF"><font face="Supreme, serif">
		Contrato Social e no Acordo de Sócios</font></font><font face="Supreme, serif">,
		no singular ou no plural, terão o significado atribuído a elas</font><font face="Supreme, serif"><span lang="pt-BR">
		neste </span></font><font face="Supreme, serif"><span lang="pt-BR"><u>Anexo
		B.I</u></span></font><font face="Supreme, serif">, exceto se
		expressamente indicado de outra forma ou se o contexto for
		incompatível com qualquer significado aqui indicado</font><font face="Supreme, serif"><span lang="pt-BR">.</span></font></font></font></p></li>
	</ol>
</ol>
<p lang="x-none" align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<ol>
	<ol start="2">
		<li><p lang="x-none" align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
		<font face="Calibri, serif"><font size="2" style="font-size: 11pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Sempre
		que mencionados n</font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><span lang="pt-BR">o
		</span></font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif">Contrato
		e no Acordo de Sócios</font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">,
		os termos abaixo terão os seguintes significados:</font></font></font></font></font></font></p></li>
	</ol>
</ol>
<p align="justify" style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
 <ol type="i">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Acordo
	de Sócios</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa o acordo de sócios a ser celebrado entre os Sócios, pelo
	qual regularão seus direitos e obrigações como Sócios da
	Sociedade;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="2">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Capital
	Investido</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa </font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">os
	recursos monetários aportados pelo Novo Sócio Participante
	diretamente nos Fundos Sociais da Sociedade;</font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="3">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Capital
	Investido Corrigido</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">significa
	o Capital Investido, corrigido monetariamente pelo IPCA, desde a
	data dos aportes dinheiro, conforme aplicável, até a realização
	de eventuais Retornos do Investimento, sendo certo que, para os fins
	desta atualização monetária, o Capital Investido sofrerá
	reduções na medida em que ocorrer cada evento de Retorno do
	Investimento</font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="4">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Contrato
	Social</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa o contrato de constituição da Sociedade, regulado </font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">pelos
	artigos 991 a 996 do Código Civil Brasileiro e pelos artigos 160 e
	161 do Decreto n</font></font></font><font color="#FFFFFF"><sup><font face="Supreme, serif"><font size="2" style="font-size: 11pt">o
	</font></font></sup></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">9.580/2018
	(“</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Regulamento
	do Imposto de Renda</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">”)
	e demais normas aplicáveis; </font></font></font></font>
	</p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="5">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Direitos
	Econômicos</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa os direitos econômicos (fundos sociais e lucros, por
	exemplo) que caberão a cada Sócio nos termos do Contrato Social e
	do Acordo de Sócios, os quais poderão variar de tempos e tempos em
	razão do ingresso de Novos Sócios Participantes;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="6">
	<li><p align="justify" style="margin-bottom: 0in"> <font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Direitos
	Políticos</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa o direito de voto e demais direitos correlatos de cada
	Sócio, na proporção definida </font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="7">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Evento
	de Liquidez em Dinheiro</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	</font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">tem
	o significado que lhe é atribuído </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="8">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Evento
	de Liquidez in Natura</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	</font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">tem
	o significado que lhe é atribuído </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="9">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Fundos
	Sociais</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é atribuído </font></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios</font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="10">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Investimento
	Qualificado</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	entende-se por investimento qualificado os aportes de capital
	realizados em qualquer </font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>Startup</i></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	em valor igual ou superior a R$ 50.000.000,00 (cinquenta milhões de
	reais);</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="11">
	<li><p align="justify" style="margin-bottom: 0in"><u> <font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">Novos
	Sócios Participantes</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significam investidores que sejam admitidos na Sociedade, após a
	sua constituição, na qualidade de Sócios Participantes;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="12">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Notificações</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é </font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">atribuído
	no Contrato Social;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="13">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Partes
	Relacionadas</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:</font></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	significam as seguintes pessoas relacionadas a determinado Sócio
	(conforme aplicável): </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>(a)</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	seus acionistas, sócios, coligadas, controladas e/ou controladoras;
	ou </font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>(b)</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	os cônjuges, companheiros, ascendentes, descendentes e seus
	respectivos cônjuges e/ou companheiros das pessoas indicadas no
	item “(a)” desta definição;</font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="14">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Regulamento</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é atribuído </font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios</font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></font></p></li>
</ol>
<p style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="15">
	<li><p align="justify" style="margin-bottom: 0in"><font color="#FFFFFF">
	</font><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Retorno
	do Investimento</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é atribuído no Contrato Social e no
	Acordo de Sócios;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="16">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Retorno
	do Investimento em Dinheiro</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é atribuído no Contrato Social e no
	Acordo de Sócios;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="17">
	<li><p align="justify" style="margin-bottom: 0in"><font color="#FFFFFF">
	</font><font size="2" style="font-size: 10pt"><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Retorno
	do Investimento in Natura</u></font></font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	tem o significado que lhe é atribuído no Contrato Social e no
	Acordo de Sócios;</font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="18">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
	Ostensivo</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa a POOL BOSSA NOVA INVESTIMENTOS LTDA., qualificada no
	Contrato Social</font></font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">;</font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="19">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócios
	Participantes</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significam as pessoas, físicas ou jurídicas, que participam ou
	venham a participar da Sociedade, na qualidade de Sócios
	Participantes, inclusive os Novos Sócios Participantes e o Sócio
	Participante Principal;</font></font></font></font></font></p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="20">
	<li><p align="justify" style="margin-bottom: 0in"> <font size="2" style="font-size: 10pt"><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sócio
	Participante Principal</u></font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa a BOSSA NOVA INVESTIMENTOS E ADMINISTRAÇÃO S.A.,
	qualificada no Contrato Social;</font></font></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>
	</u></font></font></font></font></font>
	</p></li>
</ol>
<p align="justify" style="margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="21">
	<li><p align="justify" style="margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><u>Sociedade</u></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">:
	significa esta sociedade em conta de participação, denominada POOL
	BOSSA NOVA INVESTIMENTOS LTDA. SCP MEMBERSHIP; e</font></font></font></p></li>
</ol>
<p lang="x-none" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
 <ol type="i" start="22">
	<li><p align="justify" style="margin-bottom: 0in"> <font size="2" style="font-size: 10pt"><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i><u>Startups</u></i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt"><i>:</i></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">
	tem o significado que lhe é atribuído </font></font><font color="#FFFFFF"><font face="Supreme, serif"><font size="2" style="font-size: 11pt">no
	Contrato Social e no Acordo de Sócios</font></font></font><font face="Supreme, serif"><font size="2" style="font-size: 11pt">.</font></font></font></p></li>
</ol>
<p lang="x-none" style="line-height: 115%; margin-left: 0.5in; margin-bottom: 0.14in">
<br/>
<br/>

</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p className="western" align="justify" style="margin-bottom: 0in"><br/>

</p>
<p className="western" style="margin-bottom: 0in"><br/>

</p>
<div title="footer"><p className="western" align="right" style="margin-top: 0.57in; margin-bottom: 0in; border: none; padding: 0in">
	<br/>

	</p>

</div>
</body>
</html>
`;