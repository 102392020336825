/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Alert, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import ContainerTitle from '../components/ContainerTitle';
import DocumentField from '../components/DocumentField';
import validations from '../utils/validations';

const IdentifyForm = ({formData, setFormData}) => {
  IdentifyForm.propTypes = {
      formData: PropTypes.object.isRequired,
      setFormData: PropTypes.func.isRequired
    };  
    const doctype = formData.IDdoctype;
    const document = formData.IDdocument;

    const [showAlert, setShowAlert] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [phoneMask, setPhoneMask] = useState('(99)99999-9999');
    const [isRepresentative, setIsRepresentative] = useState(false);
    const [isNotRepresentative, setIsNotRepresentative] = useState(false);
    const [cpf, setCpf] = useState('');
    const [cpfInvalid, setCpfInvalid] = useState(false);
    const [IDname, setIDName] = useState('');
    const [IDnameInvalid, setIDnameInvalid] = useState(false);
    const [email, setEmail] = useState('');
    const [emailInvalid, setEmailInvalid] = useState(false);
    const ddi = '+55';
    
    const savephoneChange = (value) => {
      setPhoneMask('(99)99999-9999');
      setPhone(value);
      setPhoneInvalid(!validations.validateCellPhone(value));
      setFormData({ ...formData, IDphone: value, IDphoneInvalid: !validations.validateCellPhone(value) });
    } 
    const saveCheckboxYes = (value) => {
      setShowAlert(false);
      setIsRepresentative(value);
      setFormData({ ...formData, IDisRepresentative: value,  });
      if(value) {
        setIsNotRepresentative(false);
      }
    }
    const saveCheckboxNo = (value) => {
      setShowAlert(false);
      setIsNotRepresentative(value);
      setFormData({ ...formData, IDisNotRepresentative: value, IDisRepresentative: false });
      if(value) {
        setIsRepresentative(false);
        setShowAlert(value);
      }
    }
    const saveRepresentativeCPF = (value) => {
      setCpfInvalid(!validations.validateCPF(value));
      setFormData({ ...formData, IDcpf: value, IDcpfInvalid: !validations.validateCPF(value) });
      setCpf(value);
    }
    const saveIDNameChange = (value) => {
      setIDnameInvalid(!validations.validateName(value));
      setFormData({ ...formData, IDname: value, IDnameInvalid: !validations.validateName(value) });
      setIDName(value);
    }
    const saveEmailChange = (value) => {
      setEmailInvalid(!validations.validateEmail(value));
      setFormData({ ...formData, Email: value, EmailInvalid: !validations.validateEmail(value) });
      setEmail(value);
    }

    const revalidateForm = (validate) => {
      if(validate) {
        saveIDNameChange(IDname);
        saveEmailChange(email);
        savephoneChange(phone);
        saveRepresentativeCPF(cpf);
      }
    }
    
    useEffect(() => {
      setIDName(formData.IDname || '');
      setIDnameInvalid(formData.IDnameInvalid || false);
      setEmail(formData.Email || '');
      setEmailInvalid(formData.EmailInvalid || false);
      setPhone(formData.IDphone || '');
      setPhoneInvalid(formData.IDphoneInvalid || false);
      setIsRepresentative(formData.IDisRepresentative || false);
      setCpf(formData.IDcpf || '');
      setCpfInvalid(formData.IDcpfInvalid || false);
      revalidateForm(formData.forceValidation || false);
    }, [formData.forceValidation]); 
    
    return (
      <Container id='login' className="userDataCol">
        {(!doctype || !document) && ( <h1>Erro</h1> ) }
          <Row>
            <ContainerTitle title='Identificação' />
          </Row>
          <Row className="justify-content-center">
            <Col md={6}>
            <Form.Group as={Col} className="form-group">
                <Form.Label>Nome</Form.Label>
                <InputGroup hasValidation>
                <InputMask
                    className={`form-control form-control 
                          ${IDnameInvalid ? 'is-invalid' : (IDname ? 'is-valid' : '')}`}
                    value={IDname}
                    id="IDname"
                    type="text"
                    required
                    onChange={(e) => saveIDNameChange(e.target.value)}
                />
                    {!IDnameInvalid ? '' : <div className="invalid-feedback">
                        Insira seu nome completo
                    </div>}
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group as={Col} className="form-group">
                <Form.Label>E-mail</Form.Label>
                <InputGroup hasValidation>
                <Form.Control
                      className={`form-control form-control 
                        ${emailInvalid ? 'is-invalid' : (email ? 'is-valid' : '')}`}
                      id="email"
                      type="text"
                      value={email}
                      onChange={(e) => saveEmailChange(e.target.value)}
                      required
                  />
                  {!emailInvalid ? '' : <div className="invalid-feedback">
                        Insira seu email
                  </div>}
                </InputGroup>
              </Form.Group>
              
          </Col>
         </Row>
         <Row className="justify-content-center pb-4">
            <Col md={6}>
              <DocumentField disabled={true} doctype={doctype} value={document} />
            </Col>
            <Col md={6} className='mobile-space-top'>
              <div className='d-flex'>
                <div className='login_ddi'>
                      <Form.Group className="form-group">
                        <Form.Label>Telefone</Form.Label>
                        <InputGroup hasValidation>
                        <Form.Control
                              id="ddi"
                              type="text"
                              value={ddi}
                              disabled
                          />
                        </InputGroup>
                      </Form.Group>
                  </div>
                  <div className='login_phone'>
                      <Form.Group className="form-group">
                        <Form.Label>&nbsp;</Form.Label>
                        <InputGroup hasValidation>
                        <InputMask
                            className={`form-control form-control 
                              ${phoneInvalid ? 'is-invalid' : (phone ? 'is-valid' : '')}`}
                            mask={phoneMask}
                            value={phone}
                            id="IDphone"
                            type="text"
                            required
                            onChange={(e) => savephoneChange(e.target.value)}
                        />
                        {!phoneInvalid ? '' : <div className="invalid-feedback">
                            Insira seu número de telefone
                        </div>} 
                        </InputGroup>
                      </Form.Group>
                  </div>
              </div>
          </Col>
         </Row>
         {doctype === 'CNPJ' && ( 
         <Row>
            <div className={`representant-alert ${showAlert ? 'show' : 'hide'}`} >
                { showAlert && (<Alert key='danger' variant='danger'>
                  Apenas o representante legal da empresa pode avançar
                </Alert>
                )}
            </div> 
            <Col md={6}>
            <Form.Label>Você é representante legal da empresa?</Form.Label>
            <Form.Group as={Row} md={4} controlId="representative">
              <div className='check-group col-md-12'>
                <div className='col-md-6 label-check'>
                  <div>
                  <Form.Check 
                    type="checkbox"
                    label=" "
                    id="simCheckbox"
                    onClick={(e) => saveCheckboxYes(e.target.checked)}
                    checked={isRepresentative}
                  />
                  </div>
                  <div>
                    <span className='px-2'>Sim</span>
                  </div>
                </div>
                <div className='col-md-6 label-check'>
                  <div>
                    <Form.Check 
                      type="checkbox"
                      label=" "
                      id="naoCheckbox"
                      onClick={(e) => saveCheckboxNo(e.target.checked)}
                      checked={isNotRepresentative}
                    />
                  </div>
                  <div>
                    <span className='px-2'>Não</span>
                  </div>
                </div>
              </div>
              {!(formData.forceValidation && (!isRepresentative && !isNotRepresentative)) ? '' : 
                <div className="invalid-feedback" style={{display: 'block'}}>
                  Informe se você é representante legal da empresa
                </div>
              }
            </Form.Group>
            </Col>
            <Col md={6} className='mobile-space-top'>
                <Form.Label>Informe seu CPF</Form.Label>
                <DocumentField 
                  doctype='CPF' 
                  additional='Rep' 
                  value={cpf} 
                  func={saveRepresentativeCPF} 
                  invalid={cpfInvalid}
                  forceValidation={formData.forceValidation} />
            </Col>
          </Row>
          )}
        </Container>
    );
};

export default IdentifyForm;