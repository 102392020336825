/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';

const RegisterForm = ({formData, setFormData}) => {
  RegisterForm.propTypes = {
      formData: PropTypes.object.isRequired,
      setFormData: PropTypes.func.isRequired
  };  

  const nome = formData.IDname?.split(' ') || "";
  const saudacao = `Olá, ${nome[0]}!`;

    return (
      <Container className="pt-4">
          <Row>
            <h3>{saudacao}</h3>
          </Row>
      </Container>
    );
};

export default RegisterForm;