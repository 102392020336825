


const responseCoded = {
    "canContractNewPlan": true,
    "wallet_id": "bae1f3e819ec4148a324",
    "plans": [
        {
            "id": "6y7ldxjd1-tm1b-3k71-a8o4-kv3i4cq527ba",
            "name": "Diamante",
            "price": 9999,
            "description": `<p><strong>Benefícios desse plano</strong></p><ul>
                            <li>15% de desconto na taxa de performance</li>
                            <li>2 ingressos VIP para o Bossa Summit</li>
                            <li>Acesso ao pipeline de investimentos</li>
                            <li>4% de investback</li>
                            </ul>`
        },
        {
            "id": "kab38xheg-mab2-15ah-mcvs-mna812hcfahs",
            "name": "Herói",
            "price": 30000,
            "description": `<p><strong>Benefícios desse plano</strong></p><ul>
                            <li>30% de desconto na taxa de performance</li>
                            <li>5 ingressos VIP para o Bossa Summit</li>
                            <li>Acesso ao pipeline de investimento</li>
                            <li>Análise de uma empresa do investidor por trimestre</li>
                            <li>5% de investback</li>
                            <li>Ingresso na jornada do equity</li>
                            <li>Coluna no startupi</li>
                            </ul>`
        },
        {
            "id": "g5499xd2-tb3x-2rv1-254s-klai4cq522sh",
            "name": "Ouro",
            "price": 6999,
            "description": `<p><strong>Benefícios desse plano</strong></p><ul>
                            <li>12% de desconto na taxa de performance</li>
                            <li>1 ingresso VIP para o Bossa Summit</li>
                            <li>3% de investback</li>
                            </ul>`
        },
        {
            "id": "724845d7-cv95-4a16-1526-9813dvb3613x",
            "name": "Prata",
            "price": 3999,
            "description": `<p><strong>Benefícios desse plano</strong></p><ul>
                            <li>10% de desconto na taxa de performance</li>
                            <li>1 ingresso VIP para o Bossa Summit</li>
                            <li>2% de investback</li>
                            </ul>`
        },
        {
            "id": "314879dd-d595-4a09-8556-531349c3610c",
            "name": "Bronze",
            "price": 999,
            "description": `<p><strong>Benefícios desse plano</strong></p><ul>
                            <li>5% de desconto na taxa de performance</li>
                            <li>1 ingresso comum para o Bossa Summit</li>
                            <li>1% de investback</li>
                            </ul>`
        },
        {
            "id": "4s5v5zsd-bs1x3-5f2z-63v1-9ocs49c365cv",
            "name": "Teste",
            "price": 1,
            "description": `<p><strong>Benefícios desse plano</strong></p><ul>
                            <li>5% de desconto na taxa de performance</li>
                            <li>1 ingresso comum para o Bossa Summit</li>
                            <li>1% de investback</li>
                            </ul>`
        }
    ]
  };
  
async function getPlanData(document, planCart) {
    try { /*
        const response = await axios.get(apiUrl, {
            wallet_document: document
        }); 
        const plans = await response.json();  */
        if(responseCoded.plans) {
            const planList = responseCoded.plans;
            let planData = {name:'', price: '', description: ''};
            planList.forEach((plan) => {
              if(plan.id === planCart) {
                planData = plan;
              }
            }); 
            return { walletId: responseCoded.wallet_id, plan: planData };
        }
        return {}; 
    } catch (error) {
        return error;
    }
  }
  export default getPlanData;