import { faCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Row } from 'react-bootstrap';

//* Dados variáveis *//
import { TermsOfUseContent } from '../components/TermsOfUseContent';
const termsTitle = 'Termo de Adesão';
const modificationDate = 'Última modificação 28 de fevereiro de 2024';


const TermsOfUse = () => { 
  const [isScrolling, setIsScrolling] = useState(false);
  const textareaRef = useRef(null);

  const scrollToBottom = () => {
    setIsScrolling(true);
  };

  /* Adiciona evento de scroll suave ao textarea */
  useEffect(() => {
    if (isScrolling) {
      const element = textareaRef.current;
      const targetScroll = element.scrollHeight;
      const startScroll = element.scrollTop;
      const duration = 200; 
      const startTime = performance.now();

      const animateScroll = () => {
        const currentTime = performance.now();
        const progress = currentTime - startTime;
        element.scrollTop = startScroll + (targetScroll - startScroll) * Math.min(progress / duration, 1);
        if (progress < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          setIsScrolling(false);
        }
      };
      requestAnimationFrame(animateScroll);
    }
  }, [isScrolling, textareaRef]);

  /* Ajusta a altura do textarea para 65% da tela */
  useEffect(() => {
    const textarea = textareaRef.current;
    const telaAltura = window.innerHeight;
    textarea.style.height = telaAltura * 0.65 + 'px';
  }, []);


  return (
    <Container id="terms" className="terms-screen terms-title">
      <Row className='terms-title-border'>
        <div className='terms-title-block'>
          <div>
            <h1>{termsTitle}</h1>
            <h6>{modificationDate}</h6>
          </div>
          <div>
          </div>
        </div>
      </Row>
      <Row className='terms-col'>
        <div className="textarea-simulated" ref={textareaRef} 
            dangerouslySetInnerHTML={{ __html: TermsOfUseContent }}
        />    
        <div className='terms-layer'>
          <div className='terms-layer-footer'>
            <button 
              className='icon icon-big'
              onClick={scrollToBottom}
            >
              <FontAwesomeIcon icon={faCircleDown} />
            </button>
          </div>
        </div>
        
      </Row>
    </Container>
  );
};

export default TermsOfUse;
