import * as clubb from '../css/clubb.js';
import * as membership from '../css/membership.js';

export const setThemeColor = (theme) => {
    let colors = {};
    if(theme === 'clubb') 
    {   colors = clubb.colors;

    } else {
        colors = membership.colors;
    }
    document.documentElement.style.setProperty("--brand-color", colors.brandColor);
}

export default setThemeColor;