async function sendPrimoPobreTerms(formData, term) {
    const payload = {
        "term_name": term,
        "email": formData.Email,
        "name": formData.IDname,
        "document": formData.IDcpf.replace(/\D/g, '')
    }

    try {
        await fetch(process.env.REACT_APP_PRIMO_COMPLIANCE_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Connection: "keep-alive",
            },
            body: JSON.stringify(payload),
        }).then(async (response) => {
            if (response.status === 201) {
                console.log('Term acceptance success');
            }
        })
    } catch (error) {
        console.error('Erro ao enviar o aceite:', error);
    }
}

export default sendPrimoPobreTerms;

