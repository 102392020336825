
async function createPixPayment(
  formData,
  setResponseError,
  setResponseType,
  setShowResponse,
  setIsLoading,
) {

  const phone = formData.IDphone.replace(/\D/g, "");
  const cep = formData.addCep.replace(/\D/g, "");
  const payload = {
      offer_name: ["educacaoprimopobre"],
      payer_document: formData.IDcpf.replace(/\D/g, ""),
      customer: {
        name: formData.IDname,
        email: formData.Email,
        document: formData.IDcpf.replace(/\D/g, ""),
        document: formData.IDcpf.replace(/\D/g, ""),
        phones: [
          {
            area: phone.substr(0, 2),
            country: "55",
            number: phone.substr(2, phone.length),
          },
      ]
      },
      billing_address: {
        street: formData.addAddress,
        number: formData.addNumber,
        locality: formData.addDistrict,
        city: formData.addCity,
        state: formData.addState,
        country: "BRL",
        postal_code: cep
    }
  }

  try {
    await fetch(process.env.REACT_APP_URL_PIX_PAYMENT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(payload),
    }).then(async (response) => {
      const resp = await response.json();
      setResponseError('');
      setShowResponse(true);

      if (resp.statusCode === 201) {
        setResponseError([resp.data.qr_code.href, resp.data.order_id]);
        setResponseType(resp.data.pix_code);

      } else {
        setResponseError(resp.message);
        setResponseType('fail');
      }
      setIsLoading(false); 
    });
  } catch (error) {
    setResponseError(error);
    setIsLoading(false);
    return { error: error };
  } 
}
export default createPixPayment;
