
async function registerTermsAccept(formData) {
      const idContractable = process.env.REACT_APP_CONTRACTABLE_ID;
      const getTermURL = process.env.REACT_APP_GET_TERM_URL;
      let term = "";
      try {
          const fullURL = `${getTermURL}${idContractable}`;
          await fetch(fullURL, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Connection: "keep-alive",
            },
          }).then(async (response) => {
              term = await response.json();
              const payload = {
                "id_term": term?.id,
                "id_wallet": formData.walletId.toString(),
                "name": formData.IDname,
                "wallet_document": formData.IDdocument.toString(),
                "representative_document": formData.IDcpf ? formData.IDcpf.toString() : formData.IDdocument.toString(),
              }
              await sendTerms(payload);
          })
      } catch (error) {
        console.error('Erro ao obter os termos:', error);
      }
}

async function sendTerms(payload) {
  try {
    await fetch(process.env.REACT_APP_COMPLIANCE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify(payload),
    }).then(async (response) => {
      if(response.status === 201) {
        console.log('Term acceptance success');
      }
    })
  } catch (error) {
    console.error('Erro ao enviar o aceite:', error);
  }
}

export default registerTermsAccept;

