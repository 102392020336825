const decryptAES = async (encryptedData, iv, key) => {
  const decryptedData = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    encryptedData
  );

  const decoder = new TextDecoder();
  return decoder.decode(decryptedData);
};

const deriveKey = async (password) => {
  const encoder = new TextEncoder();
  const salt = encoder.encode("bauducco-cereale");
  const keyMaterial = await crypto.subtle.importKey(
    "raw",
    encoder.encode(password),
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );
  return await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    true,
    ["encrypt", "decrypt"]
  );
};

export const decrypt = async (ivGet, data) => {
  const ivBase64 = decodeURIComponent(ivGet);
  const encryptedBase64 = decodeURIComponent(data);
  let decryptedMessage;

  const iv = new Uint8Array(
    [...atob(ivBase64)].map((char) => char.charCodeAt(0))
  );
  const encryptedData = new Uint8Array(
    [...atob(encryptedBase64)].map((char) => char.charCodeAt(0))
  ).buffer;

  const password = process.env.REACT_APP_DECRIPTY_KEY;
  const key = await deriveKey(password);

  try {
    decryptedMessage = await decryptAES(encryptedData, iv, key);
  } catch (error) {
    console.log(error);
    decryptedMessage = null;
  }

  return decryptedMessage;
};
