import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import InputMask from 'react-input-mask';
import ContainerTitle from '../components/ContainerTitle';
import validations from '../utils/validations';


const Address = ({formData, setFormData}) => {
  Address.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired
  };  

  const [cep, setCep] = useState('');
  const [cepInvalid, setCepInvalid] = useState('');
  const [city, setCity] = useState('');
  const [cityInvalid, setCityInvalid] = useState('');
  const [state, setState] = useState('');
  const [stateInvalid, setStateInvalid] = useState('');
  const [district, setDistrict] = useState('');
  const [districtInvalid, setDistrictInvalid] = useState('');
  const [address, setAddress] = useState('');
  const [addressInvalid, setAddressInvalid] = useState('');
  const [number, setNumber] = useState('');
  const [numberInvalid, setNumberInvalid] = useState('');
  const [complement, setComplement] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCepBlur = async () => {
    if(!cepInvalid) {
      setIsLoading(true);
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const data = response.data;
        if (data.erro) {
          setCepInvalid(true);
        } else {
          setCepInvalid(false);
          updateAddressData(data);
        }
      } catch (error) {
        setCepInvalid(true);
      }
      finally {
        setIsLoading(false); 
      }
    }
  }

  const updateAddressData = (data) => {
    setCityChange(data.localidade);
    setAddressChange(data.logradouro);
    setDistrictChange(data.bairro);
    setStateChange(data.uf);
    setFormData({ ...formData, 
      addCity: data.localidade, cityInvalid: !validations.validateString(data.localidade),
      addAddress: data.logradouro, addressInvalid: !validations.validateString(data.logradouro),
      addDistrict: data.bairro, districtInvalid: !validations.validateString(data.bairro),
      addState: data.uf, stateInvalid: !validations.validateState(data.uf)
     });
  }
  
  const setCepChange = (value) => {
    setCepInvalid(!validations.validateCEP(value));
    setFormData({ ...formData, addCep: value, cepInvalid: !validations.validateCEP(value) });
    setCep(value);
  }
  const setCityChange = (value) => {
    setCityInvalid(!validations.validateString(value));
    setFormData({ ...formData, addCity: value, cityInvalid: !validations.validateString(value) });
    setCity(value);
  }
  const setDistrictChange = (value) => {
    setDistrictInvalid(!validations.validateString(value));
    setFormData({ ...formData, addDistrict: value, districtInvalid: !validations.validateString(value) });
    setDistrict(value);
  }
  const setAddressChange = (value) => {
    setAddressInvalid(!validations.validateString(value));
    setFormData({ ...formData, addAddress: value, addressInvalid: !validations.validateString(value) });
    setAddress(value);
  }
  const setNumberChange = (value) => {
    setNumberInvalid(!value);
    setFormData({ ...formData, addNumber: value, addressNumberInvalid: value ? false : true });
    setNumber(value);
  }
  const setStateChange = (value) => {
    setStateInvalid(!validations.validateState(value));
    setFormData({ ...formData, addState: value, stateInvalid: !validations.validateState(value) });
    setState(value);
  }
  const setComplementChange = (value) => {
    setFormData({ ...formData, addComplement: value });
    setComplement(value);
  }

  const revalidateForm = (() => {
    if(formData.forceValidation) {
      setCepChange(cep);
      setDistrictChange(district);
      setAddressChange(address);
      setCityChange(city);
      setStateChange(state);
      setNumberChange(number);
    }
  });

  useEffect(() => {
    initializeForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.forceValidation]); 

  const initializeForm = () => {
      setCepInvalid(formData.cepInvalid || false);
      setCityInvalid(formData.cityInvalid || false);
      setDistrictInvalid(formData.addDistrictInvalid || false);
      setAddressInvalid(formData.addAddressInvalid || false);
      setNumberInvalid(formData.addNumberInvalid || false);
      setStateInvalid(formData.addStateInvalid || false);
      revalidateForm();
  }

  return (
    <Container className="userDataCol">
        <Row>
          <div className='d-flex'>
            <div><ContainerTitle title='Endereço' /></div>
            <div className='mx-4'>
                {isLoading && (
                  <Spinner animation="border" variant="secondary" /> 
                )}
            </div>
          </div>
        </Row>
          <Row>
            <Col md={4}>
            <Form.Group as={Col} className="form-group-payment card-group">
                <Form.Label>Cep</Form.Label>
                <InputGroup hasValidation>
                <InputMask
                    className={`form-control 
                          ${cepInvalid ? 'is-invalid' : (cep ? 'is-valid' : '')}`}
                    mask="99999-999"
                    value={cep}
                    id="cardNumber"
                    type="text"
                    required
                    onChange={(e) => setCepChange(e.target.value)}
                    onBlur={handleCepBlur}
                    />
                    {!cepInvalid ? '' : <div className="invalid-feedback">
                        Cep não é válido
                    </div>}
                </InputGroup>
            </Form.Group> 
              </Col>
              <Col md={4} >
                <Form.Group as={Col} className="form-group">
                  <Form.Label>Cidade</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      id="city"
                      type="text"
                      value={city}
                      isInvalid={cityInvalid}
                      isValid={city.length > 0 && !cityInvalid}
                      onChange={(e) => setCityChange(e.target.value)}
                      required
                    />
                      <Form.Control.Feedback type="invalid">
                        Informe a cidade
                      </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group as={Col} className="form-group">
                <Form.Label>Bairro</Form.Label>
                  <InputGroup hasValidation>
                  <Form.Control
                      id="district"
                      type="text"
                      value={district}
                      isInvalid={districtInvalid}
                      isValid={district.length > 0 && !districtInvalid}
                      onChange={(e) => setDistrictChange(e.target.value)}
                      required
                    />
                      <Form.Control.Feedback type="invalid">
                        Informe o bairro
                      </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group> 
              </Col>
          </Row>
          <Row>
              <Col md={8}>
                <Form.Group as={Col} className="form-group">
                  <Form.Label>Endereço</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      id="address"
                      type="text"
                      value={address}
                      isInvalid={addressInvalid}
                      isValid={address.length > 0 && !addressInvalid}
                      onChange={(e) => setAddressChange(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Informe o endereço
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group as={Col} className="form-group">
                <Form.Label>Número</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      id="number"
                      type="text"
                      value={number}
                      isInvalid={numberInvalid}
                      isValid={number.length > 0 && !numberInvalid}
                      onChange={(e) => setNumberChange(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Informe o número
                    </Form.Control.Feedback>
                 </InputGroup>
                </Form.Group> 
              </Col>
          </Row>
          <Row>
          <Col md={9}>
              <Form.Group as={Col} className="form-group">
                <Form.Label>Complemento</Form.Label>
                <InputGroup>
                  <Form.Control
                    id="complement"
                    type="text"
                    value={complement}
                    onChange={(e) => setComplementChange(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group as={Col} className="form-group">
                <Form.Label>Estado</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    id="state"
                    maxLength={2}
                    type="text"
                    value={state}
                    isInvalid={stateInvalid}
                    isValid={state.length > 0 && !stateInvalid}
                    onChange={(e) => setStateChange(e.target.value)}
                    required
                  />
                    <Form.Control.Feedback type="invalid">
                      Informe o estado
                    </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
    </Container>
  );
};

export default Address;
