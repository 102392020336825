
function UserDataCollector() {
  /*
  const [userData, setUserData] = useState({
    browser: navigator.userAgent,
  });

  useEffect(() => {
    // Função para obter o IP (utilizando uma API externa)
    const fetchIp = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json'); 
        let message = '';
        for (const key in response.text) {
            message += `${key}: ${response.text[key]}\n`;
        }
        const ip = response.data.ip;
        Cookies.set('userIp', ip); // Armazena o IP em um cookie
        setUserData((prevData) => ({ ...prevData, ip }));
      } catch (error) {
        console.error('Erro ao obter o IP:', error);
      } 
    };

    fetchIp();

    // Salvar dados em cookie
    const saveToCookie = (data) => {
      const cookieString = JSON.stringify(data);
      document.cookie = `userData=${cookieString}; expires=365d; path=/`;
    };

    saveToCookie(userData);
  }, [userData]);

  return null; // Este componente não renderiza nada na tela
  */
}
export default UserDataCollector;
