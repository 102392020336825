/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Alert, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import ContainerTitle from '../components/ContainerTitle';
import DocumentField from '../components/DocumentField';

const ClientInfo = ({formData}) => {
  ClientInfo.propTypes = {
      formData: PropTypes.object.isRequired,
    };  
    const doctype = formData.IDdoctype;
    const document = formData.IDdocument;

    const phoneMask = useState('(99)99999-9999');
    const [showAlert, setShowAlert] = useState(false);
    const ddi = '+55';

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return () => clearTimeout(timer);   
    }, [showAlert]);
    
    
    return (
      <Container id='login' className="userDataCol userDataColDisabled"> 
        {(!doctype || !document) && ( <h1>Erro</h1> ) }
          <Row>
            <ContainerTitle title='Identificação' />
          </Row>
          <Row className="justify-content-center">
            <Col md={6}>
            <Form.Group as={Col} className="form-group">
                <Form.Label>Nome</Form.Label>
                <InputGroup >
                <InputMask
                    className="form-control-disabled"
                    value={formData.IDname}
                    id="IDname"
                    type="text"
                    onClick={() => setShowAlert(true)}
                />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group as={Col} className="form-group">
                <Form.Label>E-mail</Form.Label>
                <InputGroup>
                <InputMask
                      className="form-control-disabled"
                      id="email"
                      type="text"
                      value={formData.Email}
                      onClick={() => setShowAlert(true)}
                  />
                </InputGroup>
              </Form.Group>
              
          </Col>
         </Row>
         <Row className="justify-content-center pb-4">
            <Col md={6}>
              <DocumentField disabled={false} info={true} doctype={doctype} value={document} func={setShowAlert} />
            </Col>
            <Col md={6} className='mobile-space-top'>
              <div className='d-flex'>
                <div className='login_ddi'>
                      <Form.Group className="form-group">
                        <Form.Label>Telefone</Form.Label>
                        <InputGroup>
                          <InputMask
                            className="form-control-disabled"
                            id="ddi"
                              type="text"
                              value={ddi}
                              onClick={() => setShowAlert(true)}
                          />
                        </InputGroup>
                      </Form.Group>
                  </div>
                  <div md={1}>&nbsp;</div>
                  <div className='login_phone'>
                      <Form.Group className="form-group">
                        <Form.Label>&nbsp;</Form.Label>
                        <InputGroup>
                        <InputMask
                            className="form-control-disabled"
                            mask={phoneMask}
                            value={formData.IDphone}
                            id="IDphone"
                            type="text"
                            onClick={() => setShowAlert(true)}
                        />
                        </InputGroup>
                      </Form.Group>
                  </div>
              </div>
          </Col>
         </Row>
         <div className={`representant-alert ${showAlert ? 'show' : 'hide'}`} >
              { showAlert && (<Alert key='danger' variant='danger'>
                Os dados de identificação não podem ser alterados
                  <button 
                  className='info-btn'
                  onClick={() => setShowAlert(false)}
                ><FontAwesomeIcon icon={faTimes} /></button>
              </Alert>
              )}
          </div>
         {doctype === 'CNPJ' && ( 
         <Row>
            <Col md={6} className='mobile-space-top'>
                <Form.Label>Informe seu CPF</Form.Label>
                <DocumentField 
                  doctype='CPF' 
                  additional='Rep' 
                  disabled={false}
                  info={true}
                  func={setShowAlert}
                  value={formData.IDcpf} />
            </Col>
          </Row>
          )}
        </Container>
    );
};

export default ClientInfo;