
const formClubbValidation = (formData) => {
    let fields = [];

    //Endereço
    if(formData.cepInvalid || formData.cepInvalid === undefined) {
        fields.push('cepInvalid');
    }
    if(formData.cityInvalid || formData.cityInvalid === undefined) {
        fields.push('cityInvalid');
    }
    if(formData.districtInvalid || formData.districtInvalid === undefined) {
        fields.push('districtInvalid');
    }
    if(formData.addressInvalid || formData.addressInvalid === undefined) {
        fields.push('addressInvalid');
    }
    if(formData.addressNumberInvalid || formData.addressNumberInvalid === undefined) {
        fields.push('addressNumberInvalid');
    }
    if(formData.payMethod === 'card' || formData.payMethod === undefined) {
        //Cartão de crédito
        if(formData.CCnameInvalid || formData.CCnameInvalid === undefined) {
            fields.push('CCnameInvalid');
        }
        if(formData.CCnumberInvalid || formData.CCnumberInvalid === undefined) {
            fields.push('CCnumberInvalid');
        }
        if(formData.CCdateInvalid || formData.CCdateInvalid === undefined) {
            fields.push('CCdateInvalid');
        }
        if(formData.CVVInvalid || formData.CVVInvalid === undefined) {
            fields.push('CVVInvalid');
        }
        if(formData.InstallmentInvalid || formData.InstallmentInvalid === undefined) {
            fields.push('InstallmentInvalid');
        }
        if(formData.HolderCpfInvalid || formData.HolderCpfInvalid === undefined) {
            fields.push('HolderCpfInvalid');
        }
    } 
    return fields;
}

const formMembershipValidation = (formData, cardchange) => {
    let fields = [];

    if(!cardchange) {
        //Identificação
        if(formData.IDnameInvalid || formData.IDnameInvalid === undefined) { 
            fields.push('IDnameInvalid');
        }
        if(formData.EmailInvalid || formData.EmailInvalid === undefined) { 
        fields.push('EmailInvalid');
        }
        if(formData.IDphoneInvalid || formData.IDphoneInvalid === undefined) { 
        fields.push('IDphoneInvalid');
        }
        //Identificação para CNPJ
        if(formData.IDdoctype === 'CNPJ') {
            if(!formData.IDisRepresentative) {
                fields.push('IDisRepresentativeInvalid');
            }
            if(formData.IDcpfInvalid) {
                fields.push('IDcpfInvalid');
            }
            //Cartão de crédito para CNPJ
            if(!formData.CCpfisica && !formData.CCpjuridica) {
                fields.push('CCpfisicaInvalid');
                fields.push('CCpjuridicaInvalid');
            }
        }
    }
    //Cartão de crédito
    if(formData.CCnameInvalid || formData.CCnameInvalid === undefined) {
        fields.push('CCnameInvalid');
    }
    if(formData.CCnumberInvalid || formData.CCnumberInvalid === undefined) {
        fields.push('CCnumberInvalid');
    }
    if(formData.CCdateInvalid || formData.CCdateInvalid === undefined) {
        fields.push('CCdateInvalid');
    }
    if(formData.CVVInvalid || formData.CVVInvalid === undefined) {
        fields.push('CVVInvalid');
    }
    //Ultimo campo Cartão de crédito
    if(!formData.CCisTitular || formData.CCisTitular === undefined) {
        fields.push('CCisTitularInvalid');
    }
    return fields;
}

const formValidation = {formMembershipValidation, formClubbValidation};
export default formValidation;