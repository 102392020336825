import React from 'react';
import { Button, Form, InputGroup, Row } from 'react-bootstrap';
import productCover from '../assets/JK_INVESTOR-DE-SUCESSO_CLUBB.VC_ BANNER 2.png';

const ClubbCart = () => {

  return (
    <>
        <Row>
            <h1>Resumo do pedido</h1>
          </Row>
          <Row>
              <img src={productCover} alt="Como se tornar um investidor de sucesso em startups" />
          </Row>
          <Row className='pt-3'>
              <h1 className='text-start'>(Curso Online) Como se tornar um investidor de sucesso em startups</h1>
          </Row>
          <Row>
              <h4 className='text-start'><strong>R$599,00</strong></h4>
          </Row>
          <Row>
          <div className='cupom-container'>
            <div>
              <Form.Label>Cupom</Form.Label>
                <InputGroup >
                  <Form.Control
                    className='input-cupom'
                    type="text"
                    value="PRIMOPOBRE"
                    required
                    disabled
                  />
                    <Form.Control.Feedback type="invalid">
                      Informe a cidade
                    </Form.Control.Feedback>
                </InputGroup>
            </div>
            <div>
              <Button disabled className="btn btn-cupom" >Aplicar</Button>
            </div>
          </div>
          </Row>
          <Row>
              <div className='cupom-info'>O código de cupom <strong>PRIMOPOBRE</strong> foi aplicado.</div>
          </Row>
    </>
  );
};

export default ClubbCart;
