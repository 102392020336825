import "bootstrap/dist/css/bootstrap.min.css";
import { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import logoClubb from "./assets/clubb.vc-logo.png";
import logoMembership from "./assets/membership.png";
import DataContext from "./contexts/DataContext";
import "./css/index.css";
import CardChange from "./pages/CardChange";
import Index from "./pages/Index";
import setThemeColor from "./utils/setThemeColor";

function App() {
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const { source } = useContext(DataContext);

  useEffect(() => {
    const body = document.body;
    body.className = isTermsOpen ? "no-scrolling" : "";
  }, [isTermsOpen]);

  useEffect(() => {
    setThemeColor(source);
  }, [source]);

  return (
    <div className="App">
      <div>
        <img
          src={source !== "membership" ? logoClubb : logoMembership}
          alt={source}
          className="img-logo"
        />
      </div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Index
                isTermsOpen={isTermsOpen}
                setIsTermsOpen={setIsTermsOpen}
              />
            }
          />
          <Route
            path="/change"
            element={
              <CardChange
                isTermsOpen={isTermsOpen}
                setIsTermsOpen={setIsTermsOpen}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
