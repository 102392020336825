import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import responseAnalysis from "../assets/responseAnalysis.png";
import responseNok from "../assets/responseNok.png";
import responseOk from "../assets/responseOk.png";
import DataContext from "../contexts/DataContext";

const Response = ({ type, responseError, setShowResponse }) => {
  const { source } = useContext(DataContext);
  const isClubb = source !== "membership";
  const landingURL = process.env.REACT_APP_LANDING_URL;
  const clubbURL = process.env.REACT_APP_CLUBBVC_URL;
  const pixInstructions =
    "Abra seu aplicativo bancário, escaneie o QR Code para realizar o pagamento ou copie o código Pix clicando no botão abaixo. Em seguida, abra seu aplicativo bancário, selecione a opção Pix 'Copia e Cola', cole o código e finalize o pagamento.";

  const [buttonTxtPix, setButtonTxtPix] = useState("Copiar código Pix");
  const [isPix, setIsPix] = useState(false);
  const [underMsg, setUnderMsg] = useState(responseError);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [buttonTxt, setButtonTxt] = useState("");
  const [href, setHref] = useState("");
  const [orderId, setOrderId] = useState(responseError?.[1] || null);
  const [paymentStatus, setPaymentStatus] = useState(type);

  const [showButton, setShowButton] = useState(true);

  function copyText(texto) {
    navigator.clipboard.writeText(texto).then(() => {
      pixSetTxtButton();
    });
  }

  function pixSetTxtButton() {
    setButtonTxtPix("Código Pix copiado!");
    setTimeout(() => {
      setButtonTxtPix("Copiar código Pix");
    }, 5000);
  }

  function listenPix(orderId) {
    if (orderId) {
      const listenURL = `${process.env.REACT_APP_URL_PIX_LISTEN}${orderId}`;
      const eventSource = new EventSource(listenURL);

      const timeoutId = setTimeout(() => {
        console.log("Timeout: Conexão fechada após 19 minutos");
        eventSource.close();
      }, 19 * 60 * 1000);

      eventSource.onmessage = (event) => {
        if (event.data.includes(orderId)) {
          setPaymentStatus("success");
          eventSource.close(); // Fechar conexão após sucesso
          clearTimeout(timeoutId); // Limpar o timeout
        }
      };

      eventSource.onerror = (error) => {
        console.error("Erro na conexão EventSource", error);
        // A conexão pode tentar ser reaberta automaticamente se estiver fechada
      };

      return () => {
        eventSource.close();
        clearTimeout(timeoutId);
      };
    }
  }

  function setSuccess() {
    setButtonTxt("Ir para login");
    if (isClubb) {
      setTitle("Pagamento realizado com sucesso!");
      setMessage(
        "Agradecemos a sua compra. Você receberá um e-mail de confirmação em breve com os detalhes do pedido"
      );
      setImage(responseOk);
      setHref("https://auth.bossainvest.com/primo-pobre/login?product=clubbvc");
    } else {
      setTitle("Assinatura realizada com sucesso!");
      setMessage(
        "Em breve você receberá um e-mail de confirmação com os detalhes da assinatura."
      );
      setImage(responseOk);
      setHref(landingURL);
    }
    setIsPix(false); // Mudar o estado de Pix após o sucesso
  }

  function setFail() {
    setButtonTxt("Refazer checkout");
    setHref("");
    if (isClubb) {
      setTitle("Falha no pagamento!");
      setMessage(
        "Houve um problema com seu pagamento. Por favor, tente novamente "
      );
      setImage(responseNok);
    } else {
      setTitle("Falha ao criar assinatura");
      setMessage(
        "Houve um problema ao criar sua assinatura. Por favor, tente novamente "
      );
      setImage(responseNok);
    }
  }

  function setAnalysis() {
    setTitle("Assinatura em análise!");
    setMessage(
      "Seu pagamento está em análise. Aguarde enquanto verificamos os detalhes. Você receberá uma notificação por e-mail em breve."
    );
    setImage(responseAnalysis);
    setButtonTxt("Início");
    setHref(isClubb ? clubbURL : landingURL);
  }

  function setPixScreen() {
    setIsPix(true);
    setButtonTxt("Copiar código Pix");
    setTitle("Pedido realizado. Finalize o pagamento.");
    setMessage("Valor da compra R$599,00");
    setImage(responseError[0]);
    setOrderId(responseError[1]);
    setHref(type);
    setUnderMsg("");
  }

  useEffect(() => {
    if (paymentStatus === "success") {
      setSuccess();
    } else if (paymentStatus === "fail") {
      setFail();
    } else if (paymentStatus === "analysis") {
      setAnalysis();
    } else if (paymentStatus && paymentStatus.includes("pix")) {
      setPixScreen();
    }
  }, [paymentStatus]);

  useEffect(() => {
    let cleanup;
    if (isPix && orderId) {
      const timer = setTimeout(() => {
        cleanup = listenPix(orderId);
      }, 15000); // Aguardar 15 segundos antes de iniciar a escuta

      return () => {
        clearTimeout(timer);
        if (cleanup) cleanup();
      };
    }
  }, [isPix, orderId]);

  const closeResponseBtn = (href) => {
    if (href) {
      navigator.clipboard.writeText(href)
        .then(() => {
          alert("Código PIX copiado para a área de transferência!");
        })
        .catch((err) => {
          console.error("Falha ao copiar o código: ", err);
        });
    } else {
      setShowResponse(false);
    }
  };

  return (
    <Container>
      <Row>
        {!isPix && (
          <div className="responseColContainer">
            <div className="responseFrameTitle">
              <img
                src={image}
                alt={title}
                width="132"
                height="132"
                className="responseImage"
              />
              <h3>{title}</h3>
              <h4>
                <div>
                  {message}
                  {paymentStatus === "fail" && (
                    <a href="mailto:suporte@bossainvest.com">
                     ou entre em contato conosco
                    </a>
                  )}
                  .
                </div>
              </h4>
              {underMsg && <h4>{underMsg}</h4>}
            </div>
            {showButton && (
              <div className="pt-4">
                <Button
                  className="btn-primary btn response-btn"
                  onClick={() => {
                    closeResponseBtn(href);
                  }}
                >
                  {buttonTxt}
                </Button>
              </div>
            )}
          </div>
        )}
        {isPix && (
          <div className="responseColContainer">
            <div className="responseFramePixTitle">
              <h3>
                <img
                  src={responseOk}
                  alt={title}
                  width="28"
                  height="28"
                  className="pixSuccessImage"
                />
                {title}
              </h3>
              <h3 style={{ paddingBottom: "40px" }}>{message}</h3>
              <div className="responseFrameTitle">
                <img
                  src={image}
                  alt={title}
                  width="276"
                  height="276"
                  className="pixImage"
                />
                <h4>{pixInstructions}</h4>
              </div>
              {showButton && (
                <div className="pt-4">
                  <Button
                    className="btn-primary btn response-btn"
                    onClick={() => {
                      closeResponseBtn(href);
                    }}
                  >
                    {buttonTxtPix} <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </Row>
    </Container>
  );
};

Response.propTypes = {
  type: PropTypes.string.isRequired,
  responseError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setShowResponse: PropTypes.func.isRequired,
};

export default Response;
