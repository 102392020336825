import HTMLReactParser from 'html-react-parser';
import React from 'react';
import { Row } from 'react-bootstrap';


const MemberCart = ({planData}) => {
  return (
    <>
      <Row className='cart-membership-title'>
          <h1>{planData?.name || 'Plano não encontrado!'}</h1>
          <h6 className='text-start'>
            {planData?.price ? planData.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' / mês' : 'Entre em contato conosco'}
          </h6>
      </Row>
      <Row className='text-start payment-title' >
          {planData?.description ? HTMLReactParser(planData.description) : ''}
      </Row>
    </>
  );
};
export default MemberCart;
